import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  setItem,
  moment,
  ToastNotification,
  localStorageencryp,
  getDataParams2,
  convertDate,
} from "../../../components/helpers/library";
import HeadLaporanBatalPesanan from "./HeadLaporanBatalPesanan";

class LaporanBatalPesanan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      LaporanPesanan: [],
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let query = {
      tgl_from: moment(new Date(data.tgl_awal)).format("yyyy-MM-DD"),
      tgl_to: moment(new Date(data.tgl_akhir)).format("yyyy-MM-DD"),
      kode_toko: data.kode_toko,
    };

    let data_date = {
      tgl_from: convertDate(data.tgl_awal),
      tgl_to: convertDate(data.tgl_akhir),
    };
    setItem("tgl_laporan", data_date);
    console.log(data_date);
    let url = "";
    url = "pesanan/reports-batal-pesanan";
    // http://103.109.55.28:6014/pesanan/reports-batal-pesanan

    getDataParams2(url, query)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Laporan Pesanan Kosong");
          this.setState({
            export: false,
            isLoading: false,
          });

          return false;
        }
        ToastNotification("success", "Laporan Pesanan Tersedia");
        localStorageencryp("tgl_laporan", JSON.stringify(query));
        //   localStorageencryp("tgl_laporan", JSON.stringify(data_post));
        this.setState({
          LaporanPesanan: res.data,
          isLoading: false,
          export: true,
        });
      })
      .catch((err) => {
        ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
        this.setState({
          export: false,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Batal Pesanan</li>
        </ol>
        <h1 className="page-header">Laporan Batal Pesanan </h1>
        <Panel>
          <PanelHeader>Laporan Batal Pesanan</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <HeadLaporanBatalPesanan
              export={this.state.export}
              isLoading={this.state.isLoading}
              LaporanPesanan={this.state.LaporanPesanan}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>

          <br />
          {/* End Tambah Blank Page  */}
        </Panel>
      </div>
    );
  }
}

LaporanBatalPesanan = reduxForm({
  form: "LaporanBatalPesanan",
  enableReinitialize: true,
})(LaporanBatalPesanan);
export default connect(null)(LaporanBatalPesanan);
