import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings";
import background from "../../assets/img/background.jpg";
import { Card, CardBody } from "reactstrap";
// import tambahBarangIcon from "../../assets/img/plus.gif";
// import kurangBarangIcon from "../../assets/img/minus.gif";
import barangIcon from "../../assets/img/layers.gif";
// import moveIcon from "../../assets/img/move.gif";

const activeBg = background;
const SubMenuBarang = () => {
  const type = useContext(PageSettings);
  const history = useHistory();
  //   const [dateSystem, setdateSystem] = useState(true);

  const routeChange = path => {
    history.push(path);
  };
  useEffect(() => {
    type.handleSetPageSidebar(false);
    type.handleSetPageHeader(false);
    // setdateSystem((getUserdata()?.tgl_system || "-") === getToday());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{ backgroundImage: "url(" + activeBg + ")" }}
        />
      </div>
      <div
        className="login"
        style={{
          minWidth: "80%",
          minHeight: "calc(100vh - 40px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="row col-lg-12 justify-content-center">
          <div className="row col-lg-12 justify-content-center">
            {/* <div
              className="col-lg-4 col-md-4"
              onClick={() => {
                routeChange("/tambah-data-barang");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={tambahBarangIcon}
                    alt="this slowpoke moves"
                    width="150"
                    class="img-fluid"
                  />
                  <h2>
                    <strong>Tambah Data Barang</strong>
                  </h2>
                </CardBody>
              </Card>
            </div> */}
            <div
              className="col-lg-4 col-md-4"
              onClick={() => {
                routeChange("/data-barang");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={barangIcon}
                    alt="this slowpoke moves"
                    width="150"
                    class="img-fluid"
                  />
                  <h2>
                    <strong>Data Barang</strong>
                  </h2>
                </CardBody>
              </Card>
            </div>
            {/* <div
              className="col-lg-4 col-md-4"
              onClick={() => {
                routeChange("/hancur-data-barang");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={kurangBarangIcon}
                    alt="this slowpoke moves"
                    width="150"
                    class="img-fluid"
                  />
                  <h2>
                    <strong>Hancur Data Barang</strong>
                  </h2>
                </CardBody>
              </Card>
            </div> */}
            {/* <div
              className="col-lg-4 col-md-4"
              onClick={() => {
                routeChange("/pindah-barang");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={moveIcon}
                    alt="this slowpoke moves"
                    width="150"
                    class="img-fluid"
                  />
                  <h2>
                    <strong>Pindah Barang</strong>
                  </h2>
                </CardBody>
              </Card>
            </div> */}
            {/* <div
              className="col-lg-4 col-md-4"
              onClick={() => {
                routeChange("/pindah-barang-satu-baki");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={moveIcon}
                    alt="this slowpoke moves"
                    width="150"
                    class="img-fluid"
                  />
                  <h2>
                    <strong>Pindah Barang Satu Baki</strong>
                  </h2>
                </CardBody>
              </Card>
            </div> */}
          </div>
          <div className="col-lg-12 justify-content-center row">
            <div className="col-lg-4" />
            <div
              className="col-lg-4 col-md-4"
              onClick={() => routeChange("/home")}
            >
              <Card
                className="border-0 card-inverse bg-danger text-white text-center m-b-10 shadow"
                style={{ borderRadius: 30 }}
              >
                <CardBody>
                  <h2>
                    <strong>BACK</strong>
                  </h2>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubMenuBarang;
