import { localStorageencryp } from "components/helpers/function";
import {
    React,
    connect,
    Link,
    reduxForm,
    Panel,
    PanelHeader,
    formatDate,
    moment,
    getTglSystem,
    ToastNotification,
    getData,
} from "../../../components/helpers/library";
import HeadLaporanHistoryCetakBarcode from "./HeadLaporanHistoryCetakBarcode";

class LaporanHistoryCetakBarcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            export: false,
            dataCetakBarcode: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(data) {
        let data_post = {
            tgl_awal: formatDate(
                moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
            ),
            tgl_akhir: formatDate(
                moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
            ),
        };
        this.setState({
            isLoading: true,
        });
        localStorageencryp('tgl_laporan', JSON.stringify(data_post));
        // setItem("tgl_laporan", data_post);
        getData(
            `history/cetak-barcode?tanggal_awal=${data_post.tgl_awal}&tanggal_akhir=${data_post.tgl_akhir}`
        )
            .then((res) => {
                console.log(res)
                // return false
                if (res.data.length === 0) {
                    ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
                    this.setState({
                        dataCetakBarcode: [],
                        isLoading: false,
                    });
                } else {
                    ToastNotification("success", "Laporan History Cetak Barcode Tersedia");
                    this.setState({
                        dataCetakBarcode: res.data,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
            });
    }

    render() {
        return (
            <div>
                <ol className="breadcrumb float-xl-right">
                    <li className="breadcrumb-item">
                        <Link to="#">Laporan</Link>
                    </li>
                    <li className="breadcrumb-item active">Laporan History Cetak Barcode</li>
                </ol>
                <h1 className="page-header">Laporan History Cetak Barcode </h1>
                <Panel>
                    <PanelHeader>Laporan History Cetak Barcode</PanelHeader>
                    <br />
                    {/* Laporan Otorisasi */}
                    <div className="container">
                        <HeadLaporanHistoryCetakBarcode
                            dataCetakBarcode={this.state.dataCetakBarcode}
                            onSubmit={(data) => this.handleSubmit(data)}
                            export={this.state.export}
                            isLoading={this.state.isLoading}
                        />
                    </div>
                    <br />
                    {/* End Tambah Laporan Otorisasi  */}
                </Panel>
            </div>
        );
    }
}

LaporanHistoryCetakBarcode = reduxForm({
    form: "LaporanOtorisasi",
    enableReinitialize: true,
})(LaporanHistoryCetakBarcode);
export default connect((state) => {
    return {
        initialValues: {
            tgl_awal: getTglSystem(),
            tgl_akhir: getTglSystem(),
        },
    };
})(LaporanHistoryCetakBarcode);
