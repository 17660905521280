import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  formatDate,
  PanelBody,
  moment,
  ToastNotification,
  localStorageencryp,
  getDataNoTimeOut2,
} from "../../../components/helpers/library";
import HeadLaporanPesananSerah from "./HeadLaporanPesananSerah";

class LaporanPesananSerah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      LaporanPesananSerah: [],
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      kode_toko: data.kode_toko,
    };
    this.setState({
      isLoading: true,
    });
    getDataNoTimeOut2(
      "pesanan/reports-pesanan-serah?tgl_from=" +
        data_post.tgl_awal +
        "&tgl_to=" +
        data_post.tgl_akhir +
        "&kode_toko=" +
        data_post.kode_toko
    )
      .then((res) => {
        console.log(res);
        if (res.data.length === 0) {
          ToastNotification("info", "Laporan Pesanan Kosong");
          this.setState({
            export: false,
            isLoading: false,
          });

          return false;
        }
        ToastNotification("success", "Laporan Pesanan Serah Tersedia");
        localStorageencryp("tgl_laporan", JSON.stringify(data_post));
        this.setState({
          LaporanPesananSerah: res.data,
          isLoading: false,
          export: true,
        });
      })
      .catch((err) => {
        ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
        this.setState({
          export: false,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Pesanan Serah</li>
        </ol>
        <h1 className="page-header">Laporan Pesanan Serah </h1>
        <Panel>
          <PanelHeader>Laporan Pesanan Serah</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <HeadLaporanPesananSerah
              export={this.state.export}
              isLoading={this.state.isLoading}
              LaporanPesananSerah={this.state.LaporanPesananSerah}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>

          <br />
          {/* End Tambah Blank Page  */}
        </Panel>
      </div>
    );
  }
}

LaporanPesananSerah = reduxForm({
  form: "LaporanPesananSerah",
  enableReinitialize: true,
})(LaporanPesananSerah);
export default connect(null)(LaporanPesananSerah);
