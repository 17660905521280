import { getMasterToko } from "actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderSelect,
  Tabel,
  InputDate,
  moment,
  getItem,
} from "../../../components/helpers/library";
import CetakLaporanRekapPenjualanPdf from "./CetakLaporanRekapPenjualanPdf";
import CetakRekapPembelianPdf from "./CetakRekapPembelianPdf";

const server = process.env.REACT_APP_BACKEND_URL;

class HeadRekapPerbulan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tgl_awal: new Date(),
    };
  }

  componentDidMount() {
    this.props.dispatch(getMasterToko());
    this.props.change("tipe", "PENJUALAN");
    this.props.change("tanggal", moment(new Date()).format("yyyy-MM"));
    this.props.change("tanggal_akhir", moment(new Date()).format("yyyy-MM"));
    this.props.change("kategori", "ALL");
    // setTimeout(() => {
    //   let dataTokoNow = this.props.dataToko.find(
    //     (val) => val.kode_toko === getItem("userdata").kode_toko
    //   );
    //   this.props.change(
    //     "kode_toko",
    //     `${dataTokoNow?.kode_toko}|${dataTokoNow?.portal}`
    //   );
    // }, 500);
    this.props.change(
      "kode_toko",
      `${getItem("userdata").kode_toko}|${server}`
    );
    console.log(`${getItem("userdata").kode_toko}|${server}`);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setFinalDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  exportPdf() {
    if (this.props.tipe === "PENJUALAN") {
      CetakLaporanRekapPenjualanPdf(this.props.datarekap);
    } else {
      CetakRekapPembelianPdf(this.props.datarekap);
    }
  }
  render() {
    const toko = [{ value: "ALL|ALL", name: "SEMUA" }];
    this.props.dataToko.forEach((data) => {
      let hasil = {
        value: `${data.kode_toko}|${data.portal}/api/v1/`,
        name: data.kode_toko,
      };
      toko.push(hasil);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="tipe"
              component={ReanderSelect}
              label="Tipe"
              type="text"
              onChange={(e) => this.props.changeTipe(e)}
              options={[
                {
                  name: "PENJUALAN",
                  value: "PENJUALAN",
                },
                {
                  name: "PEMBELIAN",
                  value: "PEMBELIAN",
                },
              ]}
              placeholder="Masukan Tipe"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tanggal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              showMonthYearPicker
              showFullMonthYearPicker
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tanggal_akhir"
              component={InputDate}
              label="Tanggal Sampai"
              type="text"
              showMonthYearPicker
              showFullMonthYearPicker
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setFinalDate(date)}
              placeholder="Masukan Tanggal Sampai"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kategori"
              component={ReanderSelect}
              label="Kategori"
              type="text"
              // onChange={(e) => this.props.changeTipe(e)}
              options={[
                {
                  name: "SEMUA",
                  value: "ALL",
                },
                {
                  name: "GOLD",
                  value: "GOLD",
                },
                {
                  name: "NON GOLD",
                  value: "NONGOLD",
                },
              ]}
              placeholder="Masukan Kategori"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kode_toko"
              component={ReanderSelect}
              label="Kode Toko"
              type="text"
              // onChange={(e) => this.props.changeTipe(e)}
              options={toko}
              placeholder="Masukan Kode Toko"
            />
          </div>
          <div className="col-lg-3">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_pesanan"
              data={this.props.datarekap}
              columns={
                this.props.tipe === "PENJUALAN"
                  ? this.props.columnsPenjualan
                  : this.props.columnsPembelian
              }
              empty={true}
              textEmpty="Data Laporan Rekap Perbulan"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

HeadRekapPerbulan = reduxForm({
  form: "HeadRekapPerbulan",
  enableReinitialize: true,
})(HeadRekapPerbulan);
export default connect((state) => {
  return {
    dataToko: state.datamaster.getMasterToko,
  };
}, null)(HeadRekapPerbulan);
