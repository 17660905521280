const ValidasiNamaBeliHadiah = (value) => {
  const errors = {};
  if (!value.namaHadiahBeli) {
    errors.namaHadiahBeli = "Nama Hadiah Harus Dipilih";
  }
  // if (!value.totHargaHadiah) {
  //   errors.totHargaHadiah = "Total Harga Harus Di Isi";
  // }
  if (!value.qtyBeli) {
    errors.qtyBeli = "Qty Beli Harus Di Isi";
  }

  if (!value.namaSupplier) {
    errors.namaSupplier = "Nama Supplier Harus Di Isi";
  }

  

  return errors;
};

export default ValidasiNamaBeliHadiah;
