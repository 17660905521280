import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";

class LaporanTukarPointExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Daftar Member"
          sheet="Laporan Daftar Member"
          buttonText="Export Exel"
        />

        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="6"> Laporan Tukar Point Member </th>
            </tr>
            <tr>
              <th colSpan="6"> Periode </th>
            </tr>
            <tr>
              <th colSpan="6">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    "s/d" +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="6"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="6"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="6"></th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kode Member
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kode Toko
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Barang Tukar
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Point Awal
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Point
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Point Akhir
              </th>
            </tr>
          </thead>

          {this.props.data.map((item, index) => (
            <>
              <tbody>
                <tr>
                  <th colSpan="9" style={{ textAlign: "left" }}>
                    {" "}
                    Tanggal Daftar : {item.tgl_trx}{" "}
                  </th>
                </tr>

                <tr>
                  <td> {item.kode_member}</td>
                  <td> {item.kode_toko}</td>
                  <td> {item.deskripsi}</td>
                  <td> {item.poin_awal}</td>
                  <td> {item.poin}</td>
                  <td> {item.poin_akhir}</td>
                </tr>
              </tbody>
            </>
          ))}
        </table>
      </>
    );
  }
}
export default LaporanTukarPointExcel;
