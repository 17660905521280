export const SET_TRANSAKSI_SERVICES = "SET_TRANSAKSI_SERVICES";
export const SHOW_MODAL_SERVICE_SELESAI = "SHOW_MODAL_SERVICE_SELESAI";
export const SHOW_MODAL_SERVICE_AMBIL = "SHOW_MODAL_SERVICE_AMBIL";

export const setTransaksiServices = (data) => {
    return (dispatch) => {
      dispatch({
        type: SET_TRANSAKSI_SERVICES,
        payload: {
          data: data,
        },
      });
    };
};

export const showModalServiceSelesai = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_SERVICE_SELESAI,
        payload: {
          data: data,
        },
      });
    };
};
export const showModalServiceAmbil = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_SERVICE_AMBIL,
        payload: {
          data: data,
        },
      });
    };
};