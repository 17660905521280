import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  formatDate,
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanAktivasiMemberPdf = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 30;
  let total_daftar = 0;

  doc.setFontSize(15);
  doc.text("LAPORAN AKTIVASI MEMBER", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN AKTIVASI MEMBER",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : JSON.parse(localStoragedecryp("tgl_laporan")).start_date +
          " s/d " +
          JSON.parse(localStoragedecryp("tgl_laporan")).end_date
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `KODE MEMBER`,
      },
      {
        content: `NAMA CUSTOMER`,
      },
      {
        content: `NO HP`,
      },
      {
        content: `ALAMAT CUSTOMER`,
      },
      {
        content: `KECMATAN`,
      },
      {
        content: `KELURAHAN`,
      },
      {
        content: `KOTA`,
      },
      {
        content: `TANGGAL LAHIR`,
      },
      {
        content: `TANGGAL AKTIVASI`,
      },
    ],
  ];
  data.forEach((row, index) => {
    let detail = [
      {
        content: row.kode_member,
      },
      {
        content: row.nama_customer || "",
      },
      {
        content: row.no_hp || "",
      },
      {
        content: row.alamat_customer || "",
      },
      {
        content: row.kecamatan || "",
      },
      {
        content: row.kelurahan || "",
      },
      {
        content: row.kota || "",
      },
      {
        content: row.tgl_lahir,
      },
      {
        content: formatDate(row.input_date),
      },
    ];

    tableRows.push(detail);

    total_daftar = total_daftar + data.length;
  });

  let data1 = [
    {
      content: `Jumlah Daftar :`,
      colSpan: "8",
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data.length}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(data1);

  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${
        JSON.parse(localStoragedecryp("userdata")).user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  footRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",

    margin: { top: 10 },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  total_daftar = 0;
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  doc.save(`LAPORAN AKTIVASI MEMBER.pdf`);
};

export default LaporanAktivasiMemberPdf;
