import { hideModal } from "../../actions/datamaster_action";
import { getUserdata } from "../../components/helpers/function";
import {
  React,
  Field,
  ReanderSelect,
  connect,
  reduxForm,
  InputDate,
  getTglSystem,
  ReanderField,
  // NumberOnly,
  // createNumberMask,
} from "../../components/helpers/library";

// const maptostate = (state) => {
//   return {
//     getDataGudang: state.datamaster.getDataGudang,
//   };
// };
// const currencyMask = createNumberMask({
//   prefix: "Rp. ",
//   locale: "kr-KO",
// });
class HeadModalTrancate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    // this.props.dispatch(getDataGudang());
    this.props.change("gudang", getUserdata().kode_toko);
    this.props.change("tgl_awal", getTglSystem());
    this.props.change("tgl_akhir", getTglSystem());
    this.props.change("trx", "PENJUALAN");
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-6">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal"
            />
          </div>

          <div className="col-lg-6">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Sampai"
              placeholder="Masukan Tanggal Sampai"
            />
          </div>
          <div className="col-6">
            <Field
              name="gudang"
              component={ReanderField}
              type="text"
              readOnly
              label="Gudang"
              placeholder="Masukan Kode Gudang"
            />
          </div>
          <div className="col-6">
            <Field
              name="trx"
              component={ReanderSelect}
              options={[
                {
                  value: "PENJUALAN",
                  name: "PENJUALAN",
                },
                {
                  value: "PEMBELIAN",
                  name: "PEMBELIAN",
                },
              ]}
              type="text"
              label="Trx"
              placeholder="Pilih Trx"
            />
          </div>

          {/* <div className="col-lg-6">
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Non Gold</legend>
              <Field
                name="jumlah_rp_non_gold"
                component={ReanderField}
                type="text"
                normalize={NumberOnly}
                label="Jumlah Rp"
                {...currencyMask}
                placeholder="Masukan Jumlah Rp"
              />
              <fieldset className="scheduler-border">
                <legend className="scheduler-border">Range Harga</legend>
                <Field
                  name="start_range_non_gold"
                  component={ReanderField}
                  type="text"
                  {...currencyMask}
                  normalize={NumberOnly}
                  label="Range Harga 1"
                  placeholder="Masukan Range Harga 1"
                />
                <Field
                  name="end_range_non_gold"
                  component={ReanderField}
                  type="text"
                  {...currencyMask}
                  normalize={NumberOnly}
                  label="Range Harga 2"
                  placeholder="Masukan Range Harga 2"
                />
              </fieldset>
            </fieldset>
          </div>
          <div className="col-lg-6">
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Gold</legend>
              <Field
                name="jumlah_rp_gold"
                component={ReanderField}
                type="text"
                {...currencyMask}
                normalize={NumberOnly}
                label="Jumlah Rp"
                placeholder="Masukan Jumlah Rp"
              />
              <fieldset className="scheduler-border">
                <legend className="scheduler-border">Range Harga</legend>
                <Field
                  name="start_range_gold"
                  component={ReanderField}
                  type="text"
                  normalize={NumberOnly}
                  label="Range Harga 1"
                  {...currencyMask}
                  placeholder="Masukan Range Harga 1"
                />
                <Field
                  name="end_range_gold"
                  component={ReanderField}
                  type="text"
                  normalize={NumberOnly}
                  label="Range Harga 2"
                  {...currencyMask}
                  placeholder="Masukan Range Harga 2"
                />
              </fieldset>
            </fieldset>
          </div> */}
          <div className="col-12 text-right">
            <button className="btn btn-primary" disabled={this.props.isLoading}>
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Memproses
                </>
              ) : (
                "Prosses"
              )}
            </button>
            &nbsp;
            <button
              className="btn btn-secondary"
              onClick={() => this.props.dispatch(hideModal())}
              isLoading={this.state.isLoading}
              type="button"
              disabled={this.props.isLoading}
            >
              {" "}
              Batal{" "}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

HeadModalTrancate = reduxForm({
  form: "HeadModalTrancate",
  enableReinitialize: true,
})(HeadModalTrancate);
export default connect(null)(HeadModalTrancate);
