import React from 'react';
import Swal from 'sweetalert2';
import { postData } from '../../../config/axios';
import { getUserdata } from '../../helpers/function';
import { ToastNotification } from '../../helpers/notification';


class SearchForm extends React.Component {
	showModalTutupToko() {
		Swal.fire({
			html:
			  "<h1><b>Apakah Anda Yakin Ingin  <br/> " +
			  "Menutup Toko" +
			  "</b> ?</h1>",
			icon: "warning",
			position: "top-center",
			cancelButtonText: "Tidak",
			showCancelButton: true,
			confirmButtonText: "OK",
			showConfirmButton: true,
		  }).then((result) => {
			if (result.isConfirmed) {
				postData("system/close", { PROSES: "close" }).then((res) => {
					ToastNotification('success', 'Berhasil Menutup Toko')
					setTimeout(() => {
						window.location.reload();
						localStorage.clear();
						localStorage.setItem("islogin", "false");
					}, 1000);
				}).catch((err) => {
					ToastNotification('info','Terjadi Kesalahan Saat Menutup Toko')
				})
			}
		  });
	}
	render() {
		return (
			<li className="navbar-form">
				{/* <form action="" method="POST" name="search_form"> */}
					<div className="form-group mt-1">
						<input type="text" readOnly className="form-control" placeholder="Tanggal Sekarang" value={"Tanggal System : "+ getUserdata().tgl_system} />
						<button type="button" className="btn btn-search"><i className="fa fa-calendar-check"> </i></button>
					</div>
				{/* </form> */}
			</li>
		);
	}
};

export default SearchForm;
