import {
  GET_KATEGORI_KATALOG,
  SET_DATA_KATEGORI_KATALOG,
} from "./kategoriKatalogAction";

const initialState = {
  kategoriKatalog: [],
  ShowModal: null,
};

const stateKategoriKatalog = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_KATEGORI_KATALOG:
      return {
        ...state,
        kategoriKatalog: actions.payload.data,
      };
    case SET_DATA_KATEGORI_KATALOG:
      return {
        ...state,
        ShowModal: actions.payload,
      };
    default:
      return state;
  }
};
export default stateKategoriKatalog;
