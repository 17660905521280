import {
  ModalGlobal,
  Panel,
  PanelBody,
  PanelHeader,
  Tabel,
} from "components/helpers/library";
import React from "react";
import { Loading } from "react-fullscreen-loading";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchSplashscreenKatalog,
  handleSubmitSplashscreenKatalog,
  setEditSplashscreenKatalog,
  setTambahSplashscreenKatalog,
} from "./redux/splashscreenKatalogAction";
import FormSplashscreenKatalog from "./widgets/FormSplashscreenKatalog";

class SplashscreenKatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "name",
          text: "Nama Screen",
          sort: true,
        },
        {
          dataField: "url",
          text: "Gambar",
          formatter: (data) => {
            return (
              <img
                src={`${data}`}
                alt="IMAGES"
                class="img-thumbnail"
                height="100px"
              />
            );
          },
        },
        {
          dataField: "text",
          text: "Text",
          sort: true,
        },
        {
          dataField: "position",
          text: "Posisi",
          sort: true,
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => {
                      this.props.dispatch(setEditSplashscreenKatalog(row));
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchSplashscreenKatalog());
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Splashscreen Katalog</Link>
          </li>
          <li className="breadcrumb-item active">Splashscreen Katalog</li>
        </ol>
        <h1 className="page-header">Splashscreen Katalog </h1>
        <Panel>
          <PanelHeader>Splashscreen Katalog</PanelHeader>
          <br />
          <PanelBody>
            <Tabel
              keyField="name"
              tambahData={false}
              handleClick={() =>
                this.props.dispatch(setTambahSplashscreenKatalog())
              }
              exportCsv={true}
              data={this.props.splashscreenKatalog}
              columns={this.state.columns}
              empty={this.props.splashscreenKatalog}
              textEmpty="Data Splashscreen Kosong"
              fileNameCsv={`kategori-katalog`}
            />
          </PanelBody>
          <br />
        </Panel>
        <ModalGlobal
          size="P"
          title={
            this.state.isEdit
              ? "Edit Splashscreen Katalog"
              : "Tambah Splashscreen Katalog"
          }
          content={
            <FormSplashscreenKatalog
              isEdit={this.state.isEdit}
              onSubmit={(data) =>
                this.props.dispatch(handleSubmitSplashscreenKatalog(data))
              }
            />
          }
        />
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    splashscreenKatalog: state.stateSplashscreenKatalog.splashscreenKatalog,
  };
})(SplashscreenKatalog);
