import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import { getTglSystem } from "../../../../components/helpers/function";
import Tabel from "../../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import Loading from "react-fullscreen-loading";
import { getDataSales } from "../../../../actions/datamaster_action";
import LaporanHutangSalesExel from "./LaporanHutangSalesExel";

import LaporanHutangSales from "./LaporanHutangSales";
class HeaderTrxHutangSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    LaporanHutangSales(this.props.LaporanHutang);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount() {
    this.props.dispatch(getDataSales());
    this.props.change("status_hutang", "OPEN");
    this.props.change("kode_sales", "ALL");
  }

  render() {
   

    const columns = [
      {
        dataField: "kode_sales",
        text: "Kode Sales",
        footer : ""
      },
      {
        dataField: "qty",
        text: "Qty",
        headerClasses: "text-right",
        formatter: (data) => {
          return <div className="text-right"> {data}</div>;
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0)}{" "}
          </div>
        ),
      },
      {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        formatter: (data) => {
          return <div className="text-right"> {data.toFixed(3)}</div>;
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
          </div>
        ),
      },
      {
        dataField: "jumlah_hutang",
        text: "Jumlah Hutang",
        headerClasses: "text-right",
        formatter: (data) => {
          return (
            <div className="text-right">
              {" "}
              {parseInt(data).toLocaleString("kr-KO")}
            </div>
          );
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {parseInt(
              columnData.reduce((acc, item) => acc + item, 0)
            ).toLocaleString("kr-KO")}{" "}
          </div>
        ),
      },
    ];
    let datasales = [
      {
        value: "ALL",
        name : "SEMUA"
      }
    ]; //
    this.props.datasales.forEach((list) => {
      let row = {
        value: list.kode_sales,
        name: list.kode_sales + "-" + list.nama_sales,
      };
      datasales.push(row);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="kode_sales"
              component={ReanderSelect}
              label="Kode Sales"
              type="text"
              options={datasales}
              placeholder="Pilih Kode Sales"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="status_hutang"
              component={ReanderSelect}
              label="Status Hutang"
              type="text"
              options={[
                {
                  value: "OPEN",
                  name: "BELUM LUNAS",
                },
                {
                  name: "SUDAH LUNAS",
                  value: "CLOS",
                },
              ]}
              placeholder="Pilih Status Hutang"
            />
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary "
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_faktur_hutang"
              data={this.props.export ? this.props.LaporanHutang : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Hutang Persales Kosong"
              // expandRow={expandRow}
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanHutangSalesExel data={this.props.LaporanHutang} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeaderTrxHutangSales = reduxForm({
  form: "HeaderTrxHutangSales",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeaderTrxHutangSales);
// export default connect()(HeaderTrxHutangSales);
export default connect((state) => {
  return {
    datasales: state.datamaster.getDataSales,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeaderTrxHutangSales);
