import { GET_ITEM_KATALOG, SET_DATA_ITEM_KATALOG } from "./itemKatalogAction";

const initialState = {
  itemKatalog: [
  ],
  ShowModal: null,
};

const stateItemKatalog = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_ITEM_KATALOG:
      return {
        ...state,
        itemKatalog: actions.payload.data,
      };
    case SET_DATA_ITEM_KATALOG:
      return {
        ...state,
        ShowModal: actions.payload,
      };
    default:
      return state;
  }
};
export default stateItemKatalog;
