const ValidasiParameterKupon = (value) => {
    const errors = {};
    if (!value.jmlKupon) {
        errors.jmlKupon = "Jumlah Kupon Harus Diisi";
    }
    if (!value.beratKupon) {
        errors.beratKupon = "Jumlah Kupon Harus Diisi";
    }
    return errors;
}

export default ValidasiParameterKupon;


