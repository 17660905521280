import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelHeader } from "../../../components/panel/panel.jsx";

import {
  showModal,
  hideModal,
  getParameterKupon,
  ShowModalParameterKupon,
} from "../../../actions/datamaster_action.jsx";
import { connect } from "react-redux";
import { ToastNotification } from "../../../components/helpers/notification.jsx";
import { reset } from "redux-form";
import { putDataNoTImeOut } from "../../../config/axios.jsx";
import { ErrorHandling } from "../../../components/helpers/function.jsx";
import Tabel from "../../../components/helpers/tabel.jsx";
import ModalGlobal from "../../../components/helpers/ModalGlobal.jsx";
import MasterParameterKupon from "./MasterParameterKupon.jsx";

const maptostate = (state) => {
  return {
    ParameterKupon: state.datamaster.getParameterKupon,
    hideModal: state.datamaster.modalDialog,
  };
};

class FormParameterKupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      modalDialog: false,
      isLoading: false,
      columns: [
        {
          dataField: "jumlah_kupon",
          text: "Jumlah Kupon",
          sort: true,
        },
        {
          dataField: "berat",
          text: "Berat Parameter Kupon",
        },{
          dataField: "kode_group",
          text: "Kode Group Kupon",
        },
        {
          dataField: "ket_hadiah",
          text: "Hadiah Kupon",
        },
        {
          dataField: "ket_kupon",
          text: "Undian Kupon",
        },
       
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
             
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => this.formEditParameterKupon(row)}
                    className="btn btn-primary mr-3"
                  >
                    {" "}
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getParameterKupon());
  }
  handleSubmit(data) {
      
    this.setState({
      isLoading: true,
    });

    let data_post = {
      berat: data.beratKupon,
      jumlah_kupon: data.jmlKupon,
      ket_hadiah: data.ketHadiah,
      ket_kupon: data.ketKupon,
      tgl_mulai: data.tglMulai,
      tgl_selesai: data.tglSelesai,
    };
    console.log(data_post);
    putDataNoTImeOut("transaksi-kupon/" + data.idKpon, data_post)
      .then(() => {
        this.props.dispatch(hideModal());
      })
      .then(() => {
        ToastNotification("success", "Data Berhasil Diedit");
      })
      .then(() => {
        this.props.dispatch(getParameterKupon());
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .then(() => {
        this.props.dispatch(reset("MasterParameterKupon"));
      })
      .catch((error) => {
        ErrorHandling(
          error,
          "sales/reactive/by-kode-sales/",
          data.kode_sales,
          data_post,
          this.props.dispatch,
          getParameterKupon(),
          hideModal(),
          "MasterParameterKupon"
        ).then(() => {
          this.setState({ isLoading: false });
        });
      });
  }
  formEditParameterKupon(dataEdit) {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowModalParameterKupon(dataEdit));
    this.setState({
      isEdit: true,
    });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Parameter Kupon</li>
        </ol>
        <h1 className="page-header">Parameter Kupon</h1>
        <Panel>
          <PanelHeader>Parameter Kupon</PanelHeader>
          <br />

          {/* Parameter Pint */}
          <PanelBody>
            <Tabel
              keyField="_id"
              data={this.props.ParameterKupon || []}
              columns={this.state.columns}
              empty={this.props.ParameterKupon || []}
              textEmpty="Parameter Kupon Kosong"
            />
          </PanelBody>
          <br />
          {/* End Master Sales */}
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit
                ? "Edit Parameter Kupon"
                : "Tambah Parameter Kupon"
            }
            content={
              <MasterParameterKupon
                isLoading={this.state.isLoading}
                isEdit={this.state.isEdit}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            }
          />

          {/* <Modal  title={ this.state.isEdit ? 'Edit Parameter HargaEmas' : 'Tambah Parameter HargaEmas' } content={ <FormModalKategori isEdit={this.state.isEdit} /> } isOpen={ this.props.hideModal} onSubmit={ (data)=>this.handleSubmit(data) } /> */}

          {/* End Tambah Master Sales  */}
        </Panel>
      </div>
    );
  }
}

export default connect(maptostate, null)(FormParameterKupon);
