import React from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../../components/panel/panel.jsx";
import { setItem } from "../../../components/helpers/function.jsx";
import { ToastNotification } from "../../../components/helpers/notification.jsx";
import { postData } from "../../../config/axios.jsx";
import { reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import Loading from "react-fullscreen-loading";
import ModalTambahTransaksi from "./ModalTambahTransaksi";
import ModalGlobal from "../../../components/helpers/ModalGlobal";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { hideModal, showModal } from "../../../actions/datamaster_action";
import {
  GET_ALL_TRANSAKSI_MEMBER,
  deleteTransaksiMember,
  getAllTransaksiMember,
} from "./transaksi_member";
import { Tabel, getDataParams } from "components/helpers/library.jsx";
import FilterPencarianTransaksiMember from "./FilterPencarianTransaksiMember.jsx";
import TransaksiMemberExcel from "./excel/TransaksiMemberExcel.jsx";
import TransaksiMemberPDF from "./pdf/TransaksiMemberPDF.jsx";

class TransaksiMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingBatal: false,
      point: "POINT",
      datatransaksimember: [],
      columns: [
        {
          dataField: "kode_member",
          text: "Kode Member",
        },
        {
          dataField: "nama_member",
          text: "Nama Member",
        },
        {
          dataField: "transaksi",
          text: "Transaksi",
        },
        {
          dataField: "jenis",
          text: "Jenis",
        },
        {
          dataField: "status",
          text: "Status",
        },
        {
          dataField: "no_nota",
          text: "Deskripsi",
        },
        {
          dataField: "keterangan",
          text: "Keterangan",
        },
        {
          dataField: "aksi",
          text: "Aksi",
          formatter: (row, rows) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => {
                      this.props.dispatch(deleteTransaksiMember(rows._id));
                    }}
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getAllTransaksiMember());
  }

  searchTransaksi(data) {
    this.setState({
      isLoading: true,
    });
    let payload = {
      transaksi: data.transaksi === "ALL" ? undefined : data.transaksi,
      jenis: data.jenis === "ALL" ? undefined : data.jenis,
    };
    getDataParams("/transaksi-member", payload)
      .then((res) => {
        console.log(res);
        this.props.dispatch({
          type: GET_ALL_TRANSAKSI_MEMBER,
          payload: res.data,
        });
        if (res.data.length === 0) {
          ToastNotification("info", "Data Transaksi Member Tidak Ada !");
        } else {
          ToastNotification(
            "success",
            "Data Transaksi Member Berhasil Ditemukan !"
          );
        }
        // this.props.dispatch(reset("ModalTambahTransaksi"));
        // this.props.dispatch(hideModal());
        // this.props.dispatch(getAllTransaksiMember());
        this.setState({
          isLoading: false,
        });
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        ToastNotification("info", "Data Transaksi Member Tidak Ada !");
        this.setState({
          isLoading: false,
        });
      });
  }
  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let payload = {
      kode_member: data.kode_member,
      nama_member: data.nama_member,
      transaksi: data.transaksi || "-",
      jenis: data.jenis || "KASUS",
      no_nota: data.no_nota || "-",
      keterangan: data.keterangan || "-",
    };
    postData("/transaksi-member", payload)
      .then((res) => {
        ToastNotification("success", "Data Transaksi Member Berhasil Ditambah");
        this.props.dispatch(reset("ModalTambahTransaksi"));
        this.props.dispatch(hideModal());
        this.props.dispatch(getAllTransaksiMember());
        this.setState({
          datatransaksimember: [],
          isLoading: false,
        });
        // console.log(res.data);
      })
      .catch((err) => {
        ToastNotification("info", "Data member tidak ada");
        this.setState({
          datatransaksimember: [],
          isLoading: false,
        });
      });
  }
  componentWillUnmount() {
    setItem("data_member", []);
  }
  exportPdf() {
    TransaksiMemberPDF(this.props.listTransaksiMember || []);
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Member</Link>
          </li>
          <li className="breadcrumb-item active">Transaksi Member</li>
        </ol>
        <h1 className="page-header">Transaksi Member </h1>
        <Panel>
          <PanelHeader>Filter Pencarian Transaksi Member</PanelHeader>
          <br />
          <PanelBody>
            <FilterPencarianTransaksiMember
              onSubmit={(data) => this.searchTransaksi(data)}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>Transaksi Member</PanelHeader>
          <br />
          {/* Lihat Transaksi Member */}
          <PanelBody>
            <div className={"col-lg-12"}>
              <ToolkitProvider
                keyField="keterangan"
                data={this.props.listTransaksiMember || []}
                columns={this.state.columns}
                search
                exportCSV={{
                  fileName: "Export Master Gudang.csv",
                }}
              >
                {(props) => (
                  <div className="row">
                    <div className="col-6 mb-3">
                      <button
                        onClick={() => this.props.dispatch(showModal())}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="col-12">
                      <Tabel {...props.baseProps} />
                      <br />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>

            <div className="col-lg-12">
              {/* <label htmlFor="">&nbsp;</label> */}
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type="button"
                    onClick={() => this.exportPdf()}
                    className="btn btn-warning btn-block"
                  >
                    {" "}
                    Export PDF{" "}
                  </button>
                </div>
                <div className="col-lg-6">
                  <TransaksiMemberExcel data={this.props.listTransaksiMember} />
                </div>
              </div>
            </div>
          </PanelBody>
          <br />
          {/* End Tambah Lihat Transaksi Member  */}
        </Panel>
        <ModalGlobal
          title="Data Transaksi Member"
          content={
            <ModalTambahTransaksi
              batalLihatTransaksi={() => this.batalLihatTransaksi()}
              onSubmit={(data) => this.handleSubmit(data)}
              columns={this.state.columns}
              isLoading={this.state.isLoading}
            />
          }
        />
        {this.state.isLoadingBatal || this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </div>
    );
  }
}

TransaksiMember = reduxForm({
  form: "TransaksiMember",
  enableReinitialize: true,
  // validate: ValidasiDataUsers,
})(TransaksiMember);
export default connect((state) => {
  return {
    listTransaksiMember: state.datamember.listTransaksiMember,
  };
})(TransaksiMember);
