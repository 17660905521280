const ValidasiLapHasilSO = (value) => {
    const errors = {};

    if (!value.typeLap) {
        errors.typeLap = "Type Laporan Harus Di pilih";
    }
    if (!value.kode_baki) {
        errors.kode_baki = "Baki Harus Di pilih";
    }


    return errors;
};

export default ValidasiLapHasilSO;