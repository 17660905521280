import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, getTglSystem, ReanderSelect, Tabel } from "../../../../components/helpers/library";
import Loading from "react-fullscreen-loading";
// import ValidasiLapMutasiHadiah from "../../../../Validasi/hadiah/ValidasiLapMutasiHadiah";
import LaporanHasilSoPdf from "./hasilSoPDF/LaporanHasilSoPdf";
import LaporanHasilSoExcel from "./hasilSoExcel/LaporanHasilSoExcel";
import { getDataBaki } from "actions/datamaster_action";
import ValidasiLapHasilSO from "Validasi/hadiah/ValidasiTypeLaporan";
import { columnDetail, columnRekap } from "./columns/colum_rekap";
import LaporanHasilSoPdfRekap from "./hasilSoPDF/LaporanHasilSoPdfRekap";
import LaporanHasilSoExcelRekap from "./hasilSoExcel/LaporanHasilSoExcelRekap";

const type = [
  {
    value: "EXCEED",
    name: "BARANG LEBIH",
  },
  {
    value: "LOST",
    name: "BARANG TIDAK KETEMU",
  },
  {
    value: "FOUND",
    name: "BARANG KETEMU",
  },
];

const typeLap = [
  {
    value: "REKAP",
    name: "REKAP"
  },
  {
    value: "DETAIL",
    name: "DETAIL"
  }

]

class HeadLaporanHasilSO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      typeLap: 'REKAP',

    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDataBaki());
    this.props.change("typeLap", "REKAP")
  }

  exportPdf() {
    let type = this.state.type === "FOUND" ? "BARANG KETEMU" : this.state.type === "LOST" ? "BARANG TIDAK KETEMU" : "BARANG LEBIH"
    console.log(this.state.typeLap)
    this.state.typeLap === "REKAP" ? LaporanHasilSoPdfRekap(this.props.LaporanHasilSo) : LaporanHasilSoPdf(this.props.LaporanHasilSo, type)
  }



  setStartDate(date) {
    this.setState({
      start_date: new Date(date),
    });
  }


  setLastDate(date) {
    this.setState({
      end_date: new Date(date),
    });
  }

  render() {
    let databaki = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.databaki.forEach((list) => {
      let data = {
        value: list.kode_baki,
        name: list.kode_baki + " - " + list.nama_baki,
      };
      databaki.push(data);
    });
    let databarang = [];
    if (this.state.typeLap === "DETAIL") {
      this.props.LaporanHasilSo.forEach((list) => {
        list.detail_barang.forEach((detail_barang) => {
          databarang.push(detail_barang);
        })
      })
    }
    console.log(databarang)
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-2">
            <Field
              name="start_date"
              component={InputDate}
              label="Tanggal"
              type="text"
              selected={this.state.start_date}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>
          <div className="col-lg-2">
            <Field
              tabIndex="2"
              name="typeLap"
              label="Type Laporan"

              onChange={(b) => {
                this.props.kosongData()
                this.setState({
                  typeLap: b
                })
              }}
              placeholder="Silahkan Pilih Laporan"
              options={typeLap}
              component={ReanderSelect}
            />
          </div>
          <div className="col-lg-2">

            <Field
              tabIndex="2"
              disabled={this.state.typeLap === "REKAP" ? true : false}
              name="type"
              label="Jenis Laporan"
              onChange={(e) => {
                this.setState({
                  type: e
                })
              }}
              placeholder="Silahkan Pilih Jenis Laporan"
              options={type}
              component={ReanderSelect}
            />
          </div>
          <div className="col-lg-4">
            <Field
              id="kode_baki"
              label="Kode Baki"
              name="kode_baki"
              onChange={(e) => {
                this.setState({ kode_baki: e });
                this.props.change("kode_baki", "");
              }}
              options={databaki}
              placeholder={"Silahkan Pilih Kode Baki"}
              component={ReanderSelect}
            />
          </div>

          <div className="col-lg-2">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-12">
            {this.state.typeLap === "REKAP" ?
              <Tabel
                keyField="no_pesanan"
                data={this.props.LaporanHasilSo}
                columns={columnRekap}
                empty={true}
                textEmpty="Data Laporan Hasil SO Kosong"
              />
              : <Tabel
                keyField="no_pesanan"
                data={databarang}
                // data={this.props.export ? this.props.LaporanHasilSo : []}
                columns={columnDetail}
                empty={true}
                textEmpty="Data Laporan Hasil SO Kosong"
              />

            }
            {/* <Tabel
              keyField="no_pesanan"
              data={this.state.export ? this.props.LaporanHasilSo : []}
              // data={this.props.export ? this.props.LaporanHasilSo : []}
              columns={this.state.typeLap === "REKAP" ? columnRekap : columnDetail}
              empty={true}
              textEmpty="Data Laporan Hasil SO Kosong"
            /> */}
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            <label htmlFor="">&nbsp;</label>
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">

                {this.state.typeLap === "DETAIL" ? (
                  // let type = this.state.type === "FOUND" ? "BARANG KETEMU" : this.state.type === "LOST" ? "BARANG TIDAK KETEMU" : "BARANG LEBIH"                  
                  <LaporanHasilSoExcel data={this.props.LaporanHasilSo || []} type={this.state.type === "FOUND" ? "BARANG KETEMU" : this.state.type === "LOST" ? "BARANG TIDAK KETEMU" : "BARANG LEBIH"} />

                ) : (
                  <LaporanHasilSoExcelRekap data={this.props.LaporanHasilSo || []} />
                )}


              </div>
            </div>
          </div>
        </div>

        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanHasilSO = reduxForm({
  form: "HeadLaporanHasilSO",
  enableReinitialize: true,
  validate: ValidasiLapHasilSO
})(HeadLaporanHasilSO);
// export default connect()(HeadLaporanPesanan);
export default connect((state) => {
  return {
    databaki: state.datamaster.getDataBaki,
    initialValues: {
      start_date: getTglSystem(),
      end_date: getTglSystem(),
    },
  };
})(HeadLaporanHasilSO);
