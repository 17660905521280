import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import { getTglSystem } from "../../../../components/helpers/function";
import Tabel from "../../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import Loading from "react-fullscreen-loading";
import { getDataSales } from "../../../../actions/datamaster_action";
import LaporanHutangThTempo from "./LaporanHutangThTempo";
class HeadLaporanJatuhTempo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "no_faktur_hutang",
          text: "No Hutang",
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Transaksi : {this.props.LaporanHutangJatuhTempo.length}{" "}
              </div>
            );
          },
        },
        {
          dataField: "detail_barang.nama_barang",
          text: "Model Barang",
          headerStyle: (colum, colIndex) => {
            return { width: "300px" };
          },
          footer: "",
        },

        {
          dataField: "nama_customer",
          text: "Nama Member",
          footer: "",
        },
        {
          dataField: "no_hp",
          text: "No Hp",
          footer: "",
        },
        {
          text: "Qty",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
          formatter: (data, row) => {
            return (
              <div className="text-right"> {row.detail_barang.jumlah}</div>
            );
          },
          footer: (data, row) => {
            return (
              <div className="text-right">
                {this.props.LaporanHutangJatuhTempo.reduce(
                  (acc, item) => acc + (item.detail_barang.jumlah || 0),
                  0
                )}
              </div>
            );
          },
        },

        {
          dataField: "berat_emas",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
        },
        {
          dataField: "berat_bruto",
          text: "Berat + Plastik",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
        },
        {
          dataField: "jumlah_hutang",
          text: "Hutang Pokok",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-ko")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    LaporanHutangThTempo(this.props.LaporanHutangJatuhTempo);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  componentDidMount() {
    this.props.dispatch(getDataSales());
    this.props.change("kode_sales", "ALL");
  }
  render() {
    let datasales = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ]; //
    this.props.datasales.forEach((list) => {
      let row = {
        value: list.kode_sales,
        name: list.kode_sales + "-" + list.nama_sales,
      };
      datasales.push(row);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="kode_sales"
              component={ReanderSelect}
              options={datasales}
              label="Kode Sales"
              placeholder="Pilih Kode Sales"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-3">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="berat_bruto"
              data={this.props.export ? this.props.LaporanHutangJatuhTempo : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Hutang Jatuh Tempo"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                {/* <LaporanHutangStockHutangExel
                  data={this.props.LaporanHutangJatuhTempo}
                /> */}
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanJatuhTempo = reduxForm({
  form: "HeadLaporanJatuhTempo",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadLaporanJatuhTempo);
// export default connect()(HeadLaporanJatuhTempo);
export default connect((state) => {
  return {
    datasales: state.datamaster.getDataSales,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadLaporanJatuhTempo);
