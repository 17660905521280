const ValidasiPembayaran = (value) => {
    const errors = {};
    if (!value.jenis) {
        errors.jenis = "Jenis Tidak Boleh Kosong";
    }
    // if (!value.cash) {
    //     errors.cash = "Nominal Tidak Boleh Kosong";
    // }

    return errors;
}

export default ValidasiPembayaran;


