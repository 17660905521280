import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
// import { getDataKondisiBarang } from "../../../actions/datamaster_action";
import { ReanderSelect } from "../../../components/helpers/field";
// const maptostate = (state) => {
//   return {
//     datakondisi: state.datamaster.getDataKondisiBarang,
//     setHancurBarang: state.databarang.setHancurBarang,
//   };
// };
class FilterPencarianTransaksiMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   date: new Date(),
      //   kondisi_barang: "kosong",
    };
  }
  //   componentDidMount() {
  //     this.props.dispatch(getDataKondisiBarang());
  //   }

  render() {
    return (
      <>
        <form
          onSubmit={(data) => this.props.handleSubmit(data)}
          autoComplete="off"
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          <div className="row">
            <div className="col-lg-3">
              <Field
                name="transaksi"
                component={ReanderSelect}
                type="text"
                options={[
                  {
                    value: "ALL",
                    name: "SEMUA",
                  },
                  {
                    value: "PENJUALAN",
                    name: "PENJUALAN",
                  },
                  {
                    value: "PEMBELIAN",
                    name: "PEMBELIAN",
                  },
                  {
                    value: "PINJAMAN",
                    name: "PINJAMAN",
                  },
                  {
                    value: "KASUS",
                    name: "KASUS",
                  },
                ]}
                label="Transaksi"
                placeholder="PILIH TRANSAKSI"
              />
            </div>
            <div className={"col-lg-3"}>
              <Field
                name={"jenis"}
                label={"Jenis"}
                component={ReanderSelect}
                options={[
                  {
                    value: "ALL",
                    name: "SEMUA",
                  },
                  {
                    value: "KASUS",
                    name: "KASUS",
                  },
                  {
                    value: "PERMINTAAN",
                    name: "PERMINTAAN",
                  },
                ]}
                placeholder="PILIH KASUS"
              />
            </div>
            <div className="col-lg-3 mt-4">
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; SEDANG
                    MENCARI DATA
                  </>
                ) : (
                  "LIHAT DATA"
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

FilterPencarianTransaksiMember = reduxForm({
  form: "FilterPencarianTransaksiMember",
  enableReinitialize: true,
})(FilterPencarianTransaksiMember);
export default connect(null, null)(FilterPencarianTransaksiMember);
