const ValidasiBarangKatalog = (value) => {
  const errors = {};
  if (!value.kode_kategori) {
    errors.kode_kategori = "Kategori Harus Dipilih";
  }
  if (!value.nama_item) {
    errors.nama_item = "Nama Barang Tidak Boleh Kosong";
  }

  if (!value.berat) {
    errors.berat = "Berat Barang Tidak Boleh Kosong ";
  }

  if (!value.direct_url) {
    errors.direct_url = "URL Pendukung Tidak Boleh Kosong ";
  }

  if (!value.harga_jual || value.harga_jual === " ") {
    errors.harga_jual = "Harga Jual Tidak Boleh Kosong ";
  }

  return errors;
};

export default ValidasiBarangKatalog;
