export const SHOW_MODAL_BATAL_PEMBELIAN = "SHOW_MODAL_BATAL_PEMBELIAN";

export const showModalBatalBeli = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_BATAL_PEMBELIAN,
        payload: {
          data: data,
        },
      });
    };
};