const ValidasiNamaHadiah = (value) => {
  const errors = {};
  if (!value.namaHadiah) {
    errors.namaHadiah = "Nama Hadiah Harus Dipilih";
  }
  if (!value.inputAlasanAmbil) {
    errors.inputAlasanAmbil = "Alasan Ambil Harus Dipilih";
  }
  if (!value.qtyAmbil) {
    errors.qtyAmbil = "Qty Ambil Harus Dipilih";
  }

  return errors;
};

export default ValidasiNamaHadiah;
