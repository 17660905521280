import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../components/helpers/field";
import { getTglSystem } from "../../../components/helpers/function";
import Tabel from "../../../components/helpers/tabel";
import Loading from "react-fullscreen-loading";
import ValidasiLapMutasiHadiah from "../../../Validasi/hadiah/ValidasiLapMutasiHadiah";
import LaporanMutasiHadiahHadiahPdf from "./pdf/LaporanMutasiHadiahPdf";

const kategori = [
  {
    value: "ALL",
    name: "SEMUA",
  },
  {
    value: "PEMBELIAN",
    name: "PEMBELIAN",
  },
  {
    value: "PENGAMBILAN",
    name: "PENGAMBILAN",
  },
  {
    value: "TUKAR POIN",
    name: "TUKAR POIN",
  },
];

const type = [
  {
    value: "REKAP",
    name: "REKAP",
  },
  {
    value: "DETAIL",
    name: "DETAIL",
  },
];
class HeadLaporanMutasiHadiah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "no_ref",
          text: "No Ref",
          footer: "Total: ",
          footerAttrs: {
            colSpan: "5",
          },
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },

        {
          dataField: "kategori",
          text: "Kategori",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },
        {
          dataField: "tanggal",
          text: "Tanggal",
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },
        {
          dataField: "keterangan",
          text: "keterangan",
          headerStyle: (colum, colIndex) => {
            return { width: "140px" };
          },
          formatter: (cell) => {
            return <div>{cell.toUpperCase()} </div>;
          },
          // formatter: (data) => {
          //   return <div className="text-left">{data.toLocaleString("kr-KO")}</div>;
          // },
        },
        {
          dataField: "nama_hadiah",
          text: "Nama Barang",
          headerStyle: (colum, colIndex) => {
            return { width: "140px" };
          },
        },

        {
          dataField: "stock_in",
          text: "Stock In",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: "40px" };
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((a, b) => a + parseInt(b), 0)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right">{parseInt(data)}</div>;
          },
        },
        {
          dataField: "stock_out",
          text: "Stock Out",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: "40px" };
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((a, b) => a + parseInt(b), 0)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right">{parseInt(data)}</div>;
          },
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    // console.log(this.props.LaporanMutasiHadiah)
    // return false
    LaporanMutasiHadiahHadiahPdf(this.props.LaporanMutasiHadiah);
  }

  setStartDate(date) {
    this.setState({
      start_date: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      end_date: new Date(date),
    });
  }

  changeKategori(e) {
    // console.log(e);
    if (e === "PEMBELIAN") {
      this.setState({
        showMember: false,
      });
    } else {
    
      this.setState({
        showMember: true,
      });
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="start_date"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.start_date}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="end_date"
              component={InputDate}
              type="text"
              selected={this.state.end_date}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <Field
              tabIndex="2"
              name="kategori"
              label="Pilih Kategori"
              onChange={(e) => this.changeKategori(e)}
              placeholder="Silahkan Pilih Kategori"
              options={kategori}
              component={ReanderSelect}
            />
          </div>
          <div className="col-lg-3">
            <Field
              tabIndex="2"
              name="type"
              label="Jenis Laporan"
              // onChange={(e) => this.changemember(e)}
              placeholder="Silahkan Pilih Jenis Laporan"
              options={type}
              component={ReanderSelect}
            />
          </div>

          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_pesanan"
              data={this.props.export ? this.props.LaporanMutasiHadiah : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Mutasi Hadiah Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                {/* <LaporanPesananExel data={this.props.LaporanPesanan} /> */}
              </div>
            </div>
          </div>
        </div>

        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanMutasiHadiah = reduxForm({
  form: "HeadLaporanMutasiHadiah",
  enableReinitialize: true,
  validate: ValidasiLapMutasiHadiah,
})(HeadLaporanMutasiHadiah);
// export default connect()(HeadLaporanPesanan);
export default connect((state) => {
  return {
    initialValues: {
      start_date: getTglSystem(),
      end_date: getTglSystem(),
    },
  };
})(HeadLaporanMutasiHadiah);
