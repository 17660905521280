import { getData, getData2 } from "../config/axios";

export const GET_DATA_BANK = "GET_DATA_BANK";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const EDIT_DATA_BANK = "EDIT_DATA_BANK";
export const GET_DATA_PROVINSI = "GET_DATA_PROVINSI";
export const EDIT_DATA_PROVINSI = "EDIT_DATA_PROVINSI";
export const SHOW_MODAL_KATEGORI = "SHOW_MODAL_KATEGORI";
export const EDIT_KATEGORI = "EDIT_KATEGORI";
export const ISLOADING = "ISLOADING";
export const ISEDIT = "ISEDIT";
//Variable JENIS
export const SHOW_MODAL_JENIS = "SHOW_MODAL_JENIS";
export const GET_DATA_JENIS = "GET_DATA_JENIS";

//Variable Kode Transakso
export const SHOW_MODAL_KODE_TRANSAKSI = "SHOW_MODAL_KODE_TRANSAKSI";
export const GET_KODE_TRANSAKSI = "GET_KODE_TRANSAKSI";

//Variable Gudang
export const SHOW_MODAL_GUDANG = "SHOW_MODAL_GUDANG";
export const GET_DATA_GUDANG = "GET_DATA_GUDANG";

//Variable Baki
export const SHOW_MODAL_BAKI = "SHOW_MODAL_BAKI";
export const GET_DATA_BAKI = "GET_DATA_BAKI";

//Variable Sales
export const SHOW_MODAL_SALES = "SHOW_MODAL_SALES";
export const GET_DATA_SALES = "GET_DATA_SALES";

//Variable Data Tukang
export const SHOW_MODAL_TUKANG = "SHOW_MODAL_TUKANG";
export const GET_DATA_TUKANG = "GET_DATA_TUKANG";

//Variavle Data Kondisi Barang
export const SHOW_MODALKONDISI_BARANG = "SHOW_MODALKONDISI_BARANG";
export const GET_DATA_KONDISI_BARANG = "GET_DATA_KONDISI_BARANG";

//Variable Kondisi Pembelian
export const GET_KONDISI_PEMBELIAN = "GET_KONDISI_PEMBELIAN";
export const SHOW_MODOAL_KONDISI_PEMBELIAN = "SHOW_MODOAL_KONDISI_PEMBELIAN";

//Variable Master Bank
export const GET_MASTER_BANK = "GET_MASTER_BANK";
export const SHOW_MODAL_MASTER_BANK = "SHOW_MODAL_MASTER_BANK";

//Variable Master Rekening
export const GET_MASTER_REKENING = "GET_MASTER_REKENING";
export const SHOW_MODAL_MASTER_REKENING = "SHOW_MODAL_MASTER_REKENING";

//Variable Kondisi Pinjaman
export const GET_KONDISI_PINJAMAN = "GET_KONDISI_PINJAMAN";
export const SHOW_MODAL_KONDISI_PINJAMAN = "SHOW_MODAL_KONDISI_PINJAMAN";

export const SHOW_MODAL_DATA_HADIAH = "SHOW_MODAL_DATA_HADIAH";
export const SHOW_MODAL_RUGI_NOTA = "SHOW_MODAL_RUGI_NOTA";

//Variable Kadar
export const SHOW_MODAL_KADAR = "SHOW_MODAL_KADAR";
export const GET_DATA_KADAR = "GET_DATA_KADAR";

//Variable Group
export const SHOW_MODAL_GROUP = "SHOW_MODAL_GROUP";
export const GET_DATA_GROUP = "GET_DATA_GROUP";

export const GET_BANDROL = "GET_BANDROL";
export const SHOW_MODAL_BANDROL = "SHOW_MODAL_BANDROL";
export const GET_PARAMETER_POINT = "GET_PARAMETER_POINT";
export const SHOW_MODAL_PARAMETER_POINT = "SHOW_MODAL_PARAMETER_POINT";

export const SHOW_MODAL_PARAMETER_HARGA_EMAS =
  "SHOW_MODAL_PARAMETER_HARGA_EMAS";
export const SHOW_MODAL_PARAMETER_KUPON = "SHOW_MODAL_PARAMETER_KUPON";
export const GET_PARAMETER_HARGA_EMAS = "GET_PARAMETER_HARGA_EMAS";
export const GET_PARAMETER_KUPON = "GET_PARAMETER_KUPON";

export const GET_DATA_HADIAH = "GET_DATA_HADIAH";
export const GET_PARAMETER_RUGI_NOTA = "GET_PARAMETER_RUGI_NOTA";

export const SHOW_MODAL_MARKETPLACE = "SHOW_MODAL_MARKETPLACE";
export const GET_DATA_MARKETPLCAE = "GET_DATA_MARKETPLCAE";

export const GET_MASTER_TOKO = "GET_MASTER_TOKO";
export const GET_JENIS_BATU = "GET_JENIS_BATU";
export const GET_DATA_PENJUALAN_PERBULAN = "GET_DATA_PENJUALAN_PERBULAN";
export const GET_DATA_PEMBELIAN_PERBULAN = "GET_DATA_PEMBELIAN_PERBULAN";
export const GET_PENJUALAN_TODAY = "GET_PENJUALAN_TODAY";
export const GET_PEMBELIAN_TODAY = "GET_PEMBELIAN_TODAY";
export const GET_PESANAN_TODAY = "GET_PESANAN_TODAY";
export const GET_SERVICE_TODAY = "GET_SERVICE_TODAY";
export const GET_LIST_NO_HANCUR = "GET_LIST_NO_HANCUR";

export const getServicesToday = (data) => {
  return (dispatch) => {
    getData("service/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_SERVICE_TODAY,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getListNoHancur = (data) => {
  return (dispatch) => {
    getData(
      `hancurbarang/get/no-hancur?tgl_awal=${data?.tgl_awal}&tgl_akhir=${data?.tgl_akhir}`
    )
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_LIST_NO_HANCUR,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPesananToday = (data) => {
  return (dispatch) => {
    getData("pesanan/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_PESANAN_TODAY,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPembelianToday = (data) => {
  return (dispatch) => {
    getData("pembelian/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_PEMBELIAN_TODAY,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPenjualanToday = (data) => {
  return (dispatch) => {
    getData("penjualan/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_PENJUALAN_TODAY,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataPembelianPerbulan = (data) => {
  return (dispatch) => {
    getData("pembelian/chart?type=monthly")
      .then((res) => {
        dispatch({
          type: GET_DATA_PEMBELIAN_PERBULAN,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataPenjualanPerbulan = (data) => {
  return (dispatch) => {
    getData("penjualan/chart?type=monthly")
      .then((res) => {
        dispatch({
          type: GET_DATA_PENJUALAN_PERBULAN,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getMasterToko = (data) => {
  return (dispatch) => {
    getData2("tokos")
      .then((res) => {
        dispatch({
          type: GET_MASTER_TOKO,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const isLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: ISLOADING,
      payload: {
        data: data,
      },
    });
  };
};
export const isEdit = (data) => {
  return (dispatch) => {
    dispatch({
      type: ISEDIT,
      payload: {
        data: data,
      },
    });
  };
};
export const getParameterRugiNota = (data) => {
  return (dispatch) => {
    getData("ruginota/get/all")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_RUGI_NOTA,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getParameterHargaEmas = (data) => {
  return (dispatch) => {
    getData("group/get/all")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_HARGA_EMAS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getParameterKupon = (data) => {
  return (dispatch) => {
    getData("transaksi-kupon")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_KUPON,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataHadiah = (data) => {
  return (dispatch) => {
    getData("hadiah/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_HADIAH,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getParameterPoint = (data) => {
  return (dispatch) => {
    getData("parapoin/get/all")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_POINT,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Data Bandrol
export const ShowModalParameterHargaEmas = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PARAMETER_HARGA_EMAS,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalParameterKupon = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PARAMETER_KUPON,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalBandrol = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_BANDROL,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalParameterPoint = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PARAMETER_POINT,
      payload: {
        data: data,
      },
    });
  };
};
export const getBandrol = (data) => {
  return (dispatch) => {
    getData("parabandrol/get/all")
      .then((res) => {
        dispatch({
          type: GET_BANDROL,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Data Kadar
export const ShowModalDataKadar = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KADAR,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataKadar = (data) => {
  return (dispatch) => {
    getData("kadar/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_KADAR,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Data Group
export const ShowModalGroup = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_GROUP,
      payload: {
        data: data,
      },
    });
  };
};
export const GetDataGroup = (data) => {
  return (dispatch) => {
    getData("group/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_GROUP,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
//End Data Group

//Function Data Bank
export const getDataBank = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_BANK,
      payload: {
        data: [
          {
            kode_bank: 1,
            nama_bank: "BCA",
            atas_nama: "Samsul Arifin",
          },
          {
            kode_bank: 2,
            nama_bank: "BRI",
            atas_nama: "Samsul Arifin",
          },
          {
            kode_bank: 3,
            nama_bank: "BNI",
            atas_nama: "Samsul Arifin",
          },
        ],
      },
    });
  };
};
//End Data Bank

//Function Data Provinsi
export const getDataProvinsi = () => {
  return (dispatch) => {
    getData("provinces")
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_DATA_PROVINSI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const editModalProvinsi = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_DATA_PROVINSI,
      payload: {
        data: data,
      },
    });
  };
};

//Function Data Jenis
export const ShowModalJenis = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_JENIS,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataJenis = (data) => {
  return (dispatch) => {
    if (data !== undefined) {
      getData("jenis/get/by-kode-kategori/" + data)
        .then((res) => {
          dispatch({
            type: GET_DATA_JENIS,
            payload: {
              data: res.data,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getData("jenis/get/all")
        .then((res) => {
          dispatch({
            type: GET_DATA_JENIS,
            payload: {
              data: res.data,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};
//End Data Jenis

//Function Data Kategori
export const ShowtModalKategori = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KATEGORI,
      payload: {
        data: data,
      },
    });
  };
};
//End Data Kategori

//Function Master Kode Transaksi
export const getParamterTransaksi = () => {
  return (dispatch) => {
    getData("paratransaksi/get/all")
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_KODE_TRANSAKSI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKodeTransaksi = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KODE_TRANSAKSI,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Gudang
export const ShowModalGudang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_GUDANG,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataGudang = (data) => {
  return (dispatch) => {
    if (data === "master") {
      getData("gudang/get/all/master")
        .then((res) => {
          dispatch({
            type: GET_DATA_GUDANG,
            payload: {
              data: res.data,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getData("gudang/get/all")
        .then((res) => {
          dispatch({
            type: GET_DATA_GUDANG,
            payload: {
              data: res.data,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

//Function Master Baki
export const ShowModalBaki = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_BAKI,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataBaki = (data) => {
  return (dispatch) => {
    getData("baki/ready")
      .then((res) => {
        dispatch({
          type: GET_DATA_BAKI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getDataBakiByGudang = (data) => {
  return (dispatch) => {
    getData("baki/get/by-kode-gudang/" + data)
      .then((res) => {
        dispatch({
          type: GET_DATA_BAKI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Master Sales
export const getDataSales = (data) => {
  return (dispatch) => {
    getData("sales/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_SALES,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const ShowModalSales = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_SALES,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Tukang
export const getDataTukang = () => {
  return (dispatch) => {
    getData("tukang/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_TUKANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalTukang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_TUKANG,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Kondisi Barang
export const getDataKondisiBarang = () => {
  return (dispatch) => {
    getData("parakondisi/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_KONDISI_BARANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKondisiBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODALKONDISI_BARANG,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Kondisi Pembelian
export const getKondisiPembelian = () => {
  return (dispatch) => {
    getData("parabeli/get/all")
      .then((res) => {
        dispatch({
          type: GET_KONDISI_PEMBELIAN,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKondisiPembelian = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODOAL_KONDISI_PEMBELIAN,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Bank
export const getMasterBank = () => {
  return (dispatch) => {
    getData("banks")
      .then((res) => {
        dispatch({
          type: GET_MASTER_BANK,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalMasterBank = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_MASTER_BANK,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Rekening
export const getMasterRekening = () => {
  return (dispatch) => {
    getData("rekenings")
      .then((res) => {
        dispatch({
          type: GET_MASTER_REKENING,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalMasterRekening = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_MASTER_REKENING,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Kondisi Pinjaman
export const getKondisiPinjaman = () => {
  return (dispatch) => {
    getData("parapinjaman/get/all")
      .then((res) => {
        dispatch({
          type: GET_KONDISI_PINJAMAN,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKondisiPinjaman = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KONDISI_PINJAMAN,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Data Haduah
export const ShowModalDataHadiah = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_DATA_HADIAH,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Parameter Rugi Nota
export const ShowModalParameterRugiNota = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_RUGI_NOTA,
      payload: {
        data: data,
      },
    });
  };
};
export const showModalDataMarketPlcae = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_MARKETPLACE,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataMarketplace = () => {
  return (dispatch) => {
    getData("marketplace")
      .then((res) => {
        dispatch({
          type: GET_DATA_MARKETPLCAE,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Modal
export const hideModal = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL,
      payload: {
        data: false,
      },
    });
  };
};
export const showModal = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        data: true,
      },
    });
  };
};
// End Modal

//
