// import routes from "config/page-route";
import {
  React,
  connect,
  reduxForm,
  FormPanel,
  getItem,
  postDataNoTImeOut,
  ToastNotification,
  setItem,
  ModalGlobal,
  showModal,
  postData2,
  hideModal,
  moment,
  getData,
  convertDate,
  getTglSystem,
} from "../../../components/helpers/library";
import OtorisasiHancur from "../../Barang/HancurDataBarang/OtorisasiHancur";
import HeadBatalHutang from "./HeadBatalHutang";

class BatalHutangLunas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      databarang: [],
      isLoadingOtorisasi: false,
      isloadingFaktur: false,
      columns: [
        {
          dataField: "no_faktur_hutang",
          text: "No Faktur",
          footer: "Total Barang ",
        },
        {
          dataField: "nama_barang",
          text: "Nama Barang",
          footer: "",
        },
        {
          dataField: "kondisi",
          text: "Kondisi",
          footer: "",
        },
        {
          dataField: "kategori",
          text: "Kategori",
          footer: "",
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">{parseFloat(cell).toFixed(3)}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {parseFloat(
                columnData.reduce((acc, item) => acc + item, 0)
              ).toFixed(3)}
            </div>
          ),
        },
        {
          dataField: "ambil",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.simpanBatal(row)}
                    name="type_btn"
                    className="btn btn-danger"
                    disabled={row.tgl_lunas !== getTglSystem()}
                    data-tooltip={
                      row.tgl_lunas !== getTglSystem()
                        ? "Tidak Bisa Batal Hutang Lunas"
                        : "Simpan Batal Hutang Lunas"
                    }
                  >
                    <i className="fa fa-times"></i>
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    data-tooltip="Reprint"
                    onClick={() => this.rePrint(row)}
                    // onClick={() => this.printNotaPelunasan(row)}
                    className="btn btn-success"
                  >
                    <i className="fa fa-file-export"></i>
                  </button>
                </div>
              </div>
            );
          },
          footer: "",
        },
      ],
    };
  }
  rePrint(row) {
    getData("/hutang-lunas/reprint?no_faktur_hutang=" + row.no_faktur_hutang)
      .then((res) => {
        console.log(res.data);
        this.printNotaPelunasan(res.data[0]);
        this.setState({
          isLoadingPembayaran: false,
          isLoading: false,
          formPelunasan: "LIHATHUTANG",
          datahutang: [],
        });
        this.props.dispatch(hideModal());
        localStorage.removeItem("jml_byar_pelunasan_hutang");
        setItem("trx_pemabayaran_hutang", []);
        setItem("sisa_bayar_hutang", []);
        ToastNotification("success", "Berhasil Reprint Hutang Lunas");
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          isLoadingPembayaran: false,
        });
        ToastNotification("info", "Terjadi Kesalahan Saat Mengirim Data");
      });
  }

  printNotaPelunasan(row) {
    let notabeli = [];
    let feedback = "";
    let nama = "";
    feedback += `========================================\n`;
    feedback += `     NOTA PELUNASAN HUTANG ${nama}      \n`;
    feedback += `========================================
Sales Id         : ${row.sales_id}
No Transaksi     : ${row.no_transaksi}
Tgl Transaksi    : ${moment(new Date(row.tgl_transaksi)).format("DD-MM-YYYY")}
Jam              : ${row.jam}
Kode Member      : ${row.kode_member || "-"}
Nama             : ${row.nama_member}
No Hp            : ${row.no_hp}
Alamat Customer  : ${row.alamat.slice(0, 20)}\n`;
    feedback += `========================================\n`;
    feedback += `Keterangan\n`;
    feedback += `========================================\n`;
    let total = 0;
    let harga_nota = 0;
    row.detail_barang.forEach((detail, index) => {
      total = total + detail.berat;
      let jml_nama_barang = detail.nama_barang.length;

      harga_nota = harga_nota + detail.harga_nota;
      feedback += `Nama Barang    : ${detail.nama_barang.slice(0, 20)}\n`;
      if (jml_nama_barang > 20) {
        feedback += `                 ${detail.nama_barang.slice(20, 40)}\n`;
      }
      if (jml_nama_barang > 40) {
        feedback += `                 ${detail.nama_barang.slice(40, 60)}\n`;
      }
      feedback += `\n\n`;
      feedback += `Lama Pinjam    : ${row.lamaPinjam}\n\n\n`;
      feedback += `Total Pinjam   : ${row.totalPinjam.toLocaleString(
        "kr-KO"
      )}\n`;
      feedback += `Bunga          : ${row.bunga.toLocaleString("kr-KO")}\n`;
      feedback += `Harga Dibayar  : ${row.harusDibayar.toLocaleString(
        "kr-KO"
      )}\n`;
      feedback += `========================================\n`;
      feedback += `
\n`;
    });
    notabeli.push(feedback);
    // }
    document.getElementById("myInput").value = notabeli.join("");
    setTimeout(() => {
      this.TextFile();
    }, 100);
  }

  simpanTransaksiJual(data) {
    this.setState({
      isLoadingOtorisasi: true,
    });
    let hasil = {
      user_id: data.username,
      password: data.password,
      kategori: "BATAL HUTANG LUNAS",
      description: "SIMPAN BATAL HUTANG LUNAS",
      keterangan:
        data.keterangan === undefined
          ? "BATAL HUTANG LUNAS"
          : data.keterangan.toUpperCase(),
    };
    postData2("authorization", hasil)
      .then((res) => {
        let databarang = getItem("datahutang_lunas");
        this.setState({
          isLoading: true,
        });
        postDataNoTImeOut(
          "hutang/hutang-lunas/cancellation?no_faktur_hutang=" +
            databarang[0].no_faktur_hutang
        )
          .then((res) => {
            setItem("datahutang_lunas", []);
            ToastNotification("success", "Batal Hutang Lunas Success");
            this.setState({
              isLoading: false,
              isLoadingOtorisasi: false,
            });
            this.props.dispatch(hideModal());
          })
          .then(() => window.location.reload())
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading: false,
              isLoadingOtorisasi: false,
            });
            ToastNotification("info", "Batal Hutang Lunas Gagal");
          });
      })
      .catch((err) => {
        console.log(err);
        ToastNotification("info", "Username Atau Password Salah");
        this.setState({
          isLoadingOtorisasi: false,
        });
      });
  }
  simpanBatal(data) {
    const newarr = [];
    newarr.push(data);
    setItem("datahutang_lunas", newarr);
    this.props.dispatch(showModal());
  }
  TextFile() {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_hutang.txt";
    document.body.appendChild(element);
    element.click();
  }
  handleSubmit(data) {
    let data_post = {
      no_hutang: data.no_hutang || "",
      tgl_awal: convertDate(data.tgl_awal),
      tgl_akhir: convertDate(data.tgl_akhir),
    };
    this.setState({ isloadingFaktur: true, isLoading: true });
    getData(
      `hutang/hutang-lunas?no_faktur_hutang=${data_post.no_hutang}&tgl_awal=${data_post.tgl_awal}&tgl_akhir=${data_post.tgl_akhir}`
    )
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification(
            "info",
            "No Faktur Hutang Yang Anda Cari Tidak Ada"
          );
          this.setState({
            isloadingFaktur: false,
            isLoading: false,
            databarang: [],
          });
          return false;
        } else {
          let data = [];
          res.data.forEach((el) => {
            el.detail_barang.forEach((element) => {
              let datas = {
                no_faktur_hutang: el.no_faktur_hutang,
                berat: element.berat,
                kondisi: element.kondisi,
                kategori: element.kategori,
                nama_barang: element.nama_barang,
                qty: element.jumlah || element.qty || 0,
                tgl_lunas: el.tgl_lunas,
              };
              data.push(datas);
            });
          });
          this.setState({
            isloadingFaktur: false,
            isLoading: false,
            databarang: data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isloadingFaktur: false,
          isLoading: false,
          databarang: [],
        });
        ToastNotification(
          "info",
          err?.response?.data || "Data Yang Anda Cari Tidak Ada"
        );
      });
  }
  render() {
    return (
      <FormPanel menu="Hutang" submenu="Batal Hutang Lunas">
        <textarea
          style={{ display: "none" }}
          id="myInput"
          rows="100"
          cols="100"
        />
        <HeadBatalHutang
          isloadingFaktur={this.state.isloadingFaktur}
          isLoading={this.state.isLoading}
          onSubmit={(data) => this.handleSubmit(data)}
          data={this.state.databarang}
          columns={this.state.columns}
          // simpanBatal={() => this.simpanBatal()}
        />
        <ModalGlobal
          size="L"
          title="Otorisasi Pembatalan"
          content={
            <OtorisasiHancur
              keterangan
              isLoadingOtorisasi={this.state.isLoadingOtorisasi}
              onSubmit={(data) => this.simpanTransaksiJual(data)}
            />
          }
        />
      </FormPanel>
    );
  }
}

BatalHutangLunas = reduxForm({
  form: "BatalHutangLunas",
  enableReinitialize: true,
})(BatalHutangLunas);
export default connect(null)(BatalHutangLunas);
