import { formatGram } from 'components/helpers/function';
import React from 'react'
const columnDetail = [
    {
        dataField: "kode_barcode",
        text: "Barcode",

        footerAttrs: {
            colSpan: "3",
        },
        footer: ""
    },

    {
        dataField: "nama_barang",
        text: "Nama Barang",

    },
    {
        dataField: "nama_atribut",
        text: "Data Atribut",

    },
    {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseFloat(b), 0).toFixed(3)}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 3)} </div>;
        }
    }

];
const columnRekap = [

    {
        dataField: "kode_toko",
        text: "Kode Baki",

        footerAttrs: {
            colSpan: "1",
        },
        footer: ""
    },

    {
        dataField: "qty_ketemu",
        text: "Qty Barang Ketemu",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseInt(b), 0).toLocaleString("Kr-ko")}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {cell} </div>;
        }

    },
    {
        dataField: "berat_ketemu",
        text: "Berat Barang Ketemu",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseFloat(b), 0).toFixed(3)}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 3)} </div>;
        }

    },
    {
        dataField: "qty_hilang",
        text: "Qty Barang Tidak Ketemu",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseInt(b), 0).toLocaleString("Kr-ko")}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {cell} </div>;
        }

    },
    {
        dataField: "berat_hilang",
        text: "Berat Barang Tidak Ketemu",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseFloat(b), 0).toFixed(3)}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 3)} </div>;
        }

    },
    {
        dataField: "qty_lebih",
        text: "Qty Barang Lebih",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseInt(b), 0).toLocaleString("Kr-ko")}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {cell} </div>;
        }

    },
    {
        dataField: "berat_lebih",
        text: "Berat Barang Lebih",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseFloat(b), 0).toFixed(3)}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 3)} </div>;
        }

    },
    {
        dataField: "qty_total",
        text: "Total Qty",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseInt(b), 0).toLocaleString("Kr-ko")}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right">{cell.toLocaleString("kr-KO")}</div>;
        }

    },
    {
        dataField: "berat_total",
        text: "Total Berat",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
            return (
                <div className="text-right">
                    {cell.reduce((a, b) => a + parseFloat(b), 0).toFixed(3)}
                </div>
            );
        },
        formatter: (cell) => {
            return <div className="text-right">{formatGram(cell, 3)}</div>;
        }

    },
]

export { columnDetail, columnRekap };