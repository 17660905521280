const ValidasiBaralJual = (value) => {
    const errors = {};
    // if (!value.kode_barcode) {
    //     errors.kode_barcode = "Kode Barcode Harus Diisi";
    // }
    // if (!value.no_faktur) {
    //     errors.no_faktur = "No Faktur Harus Diisi";
    // }
    if (!value.alasan) {
        errors.alasan = "Alasan Harus Diisi";
    }
    return errors;
}

export default ValidasiBaralJual;


