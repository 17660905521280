import {
  React,
  Component,
  connect,
  Field,
  createNumberMask,
  formValueSelector,
  reduxForm,
  NumberOnly,
  localStoragedecryp,
  ReanderField,
  ReanderSelect,
  getTglSystem,
  InputDate,
} from "../../components/helpers/library";
import { getDataSales } from "../../actions/datamaster_action";
import ValidasiPembayaran from "../../Validasi/Penjualan/ValidasiPembayaran";

const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "kr-KO",
});
const jenis_pembayaran = [
  {
    value: "CASH",
    name: "CASH",
  },
  {
    value: "DEBET",
    name: "DEBET",
  },
  {
    value: "TRANSFER",
    name: "TRANSFER",
  },
  {
    value : "CREDIT",
    name: "CREDIT"
  }
];
class HeadInputPembayaran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      type_pembayaran: "CASH",
      lebih: false,
      columns: [
        // {
        //   dataField: "nama_bank",
        //   text: "Nama Bank",
        //   footerAttrs: {
        //     colSpan: "2",
        //   },
        //   footer: "Grand Total",
        // },
        {
          dataField: "type",
          text: "Type",
          footerAttrs: {
            colSpan: "1",
          },
          footer: "Grand Total",
        },
        {
          dataField: "Total",
          text: "Total",
          footer: "",
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getDataSales());
    let data_barang_penjualan = JSON.parse(
      localStoragedecryp("data_barang_pesanan")
    );

    let total = 0;
    data_barang_penjualan.forEach((item, index) => {
      // console.log(item.total);
      total = total + item.sub_total;
      this.props.change("grand_total_pembayaran", total);
      this.props.change("harus_bayar_rp", total);
    });
    this.props.change("jenis", "CASH");
    setTimeout(() => {
      document.getElementById("cash_trx_penjualan").focus();
    }, 100);
  }
  hitungTotal() {
    this.props.change("kembali", this.props.kembali);
    this.props.change("nominal_kembali", this.props.nominal_kembali);

  }

  pilihPembyaran(e) {
    if (e === "CASH") {
      this.setState({
        type_pembayaran: "CASH",
      });
    } else if (e === "TRANSFER") {
      this.setState({
        type_pembayaran: "TRANSFER",
      });
    } else if (e === "DEBET") {
      this.setState({
        type_pembayaran: "DEBET",
      });
    } else {
      this.setState({
        type_pembayaran: "CREDIT"
      })
    }
    this.props.change("cash", "");

    // document.getElementById('cash_trx_penjualan').focus()
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  copyValue(e) {
    this.props.change("cash",parseInt(NumberOnly(e)))
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-6">
            <Field
              name="grand_total_pembayaran"
              component={ReanderField}
              type="text"
              onChange={this.hitungTotal()}
              readOnly={true}
              {...currencyMask}
              label="Total Harga Jual"
              placeholder="Total Harga Jual"
            />
          </div>

          <div className="col-6">
            <Field
              id="harus_bayar_rp"
              name="harus_bayar_rp"
              component={ReanderField}
              type="text"
              onChange={this.hitungTotal()}
              readOnly={true}
              {...currencyMask}
              label="Harus Bayar Rp"
              placeholder="Harus Bayar Rp"
            />
          </div>
          <div className="col-6">
            <Field
              name="jenis"
              label="Pilih Jenis Bayar"
              placeholder="Pilih Jenis Pembayaran"
              options={jenis_pembayaran}
              onChange={(e) => this.pilihPembyaran(e)}
              component={ReanderSelect}
            />
          </div>

          <div
            className="col-6"
            style={{
              display:
                this.state.type_pembayaran === "CASH"
                  ? "none"
                  : this.state.type_pembayaran === "DEBET"
                  ? "block"
                  : "block",
            }}
          >
            <Field
              tabIndex="1"
              name="nama_bank"
              id="nama_bank"
              component={ReanderField}
              type="text"
              label="Bank"
              placeholder="Masukan Nama Bank"
            />
          </div>
          <div
            className="col-6"
            style={{
              display:
                this.state.type_pembayaran === "CASH"
                  ? "none"
                  : this.state.type_pembayaran === "DEBET"
                  ? "none"
                  : "block",
            }}
          >
            <Field
              tabIndex="1"
              name="no_card"
              id="no_card"
              component={ReanderField}
              type="text"
              label={this.state.type_pembayaran === "TRANSFER" ? "No Rek" : "Card No"}
              placeholder={this.state.type_pembayaran === "TRANSFER" ? "Masukan No Rek" : "Masukan Card No"}
            />
          </div>
          <div className="col-6">
            <Field
              tabIndex="1"
              name="cash"
              id="cash_trx_penjualan"
              component={ReanderField}
              type="text"
              label="Nominal"
              onChange={this.hitungTotal()}
              {...currencyMask}
              normalize={NumberOnly}
              placeholder="Masukan Nominal"
            />
          </div>
          <div className="col-6" 
          style={{
              display:
                this.state.type_pembayaran === "CREDIT"
                  ? "block"
                  : "none"
            }}>
            <Field
              tabIndex="1"
              name="fee"
              id="fee"
              component={ReanderField}
              type="text"
              label="Fee (%)"
              normalize={NumberOnly}
              placeholder="5"
            />
          </div>
          <div className="col-6 d-none">
            <Field
              tabIndex="1"
              name="nominal_kembali"
              component={ReanderField}
              type="text"
              label="Nominal"
              placeholder="Masukan Nominal"
            />
          </div>
          <div className="col-6">
            <Field
              name="kembali"
              id="sisa_bayar"
              component={ReanderField}
              type="text"
              readOnly={true}
              label="Sisa"
              //   {...currencyMask}
              onFocus={(e)=>this.copyValue(e.target.value)}

              // normalize={NumberOnly}
              placeholder="Sisa Pembayaran"
            />
          </div>

          <div
            className={
              this.state.type_pembayaran === "CASH"
                ? "col-6 text-right"
                : this.state.type_pembayaran === "TRANSFER"
                ? "col-6 text-right"
                : "col-12 text-right"
            }
          >
            <br />

            <label>&nbsp;</label>
            <button
              tabIndex="2"
              disabled={this.props.notif}
              className="btn btn-primary"
            >
              {" "}
              <i className="fa fa-plus"></i>
            </button>
          </div>
          <div className="col-12">
            <Field
              name="estimasi_pesanan"
              component={InputDate}
              type="text"
              label="Estimasi Pesanan Selesai"
              defaultValue={getTglSystem()}
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Estimasi Pesanan"
            />
          </div>
        </div>
      </form>
    );
  }
}

HeadInputPembayaran = reduxForm({
  form: "HeadInputPembayaran",
  enableReinitialize: true,
  validate: ValidasiPembayaran,
})(HeadInputPembayaran);

const selector = formValueSelector("HeadInputPembayaran"); // <-- same as form name

export default connect((state) => {
  const { cash,harus_bayar_rp } = selector(
    state,
    "grand_total_pembayaran",
    "harus_bayar_rp",
    "cash"
  );
  let data = JSON.parse(localStorage.getItem("trx_pemabayaran_pesanan")) || [];
  let total = 0;
  data.forEach((element) => {
    total = element.nominal + total;
  });
  let hasil = parseFloat(total || 0) - parseFloat(harus_bayar_rp || 0);

  // let verifikasi_pembayaran_lebih = ;
  
  return {
    kembali: hasil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    nominal_kembali : hasil + parseInt(cash),

    initialValues: {
      estimasi_pesanan: getTglSystem(),
      // tgl_akhir: getTglSystem(),
    },
  };
})(HeadInputPembayaran);
