import { GET_DATA_TIMBANGAN, GET_PARA_SYSTEM,GET_PARA_SYSTEM_ID } from "../actions/utility_action";
import {SET_BARANG_PESANAN, SET_LOADING} from "../pages/Penjualan/TransaksiPenjualan/penjualan_action";

const initialState = {
  getTimbangan: [],
  getParaSystem: [],
  getParaSystemId: [],
  isLoading: false,
  barangPesanan : false,
};

const utility = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_DATA_TIMBANGAN:
      return {
        ...state,
        getTimbangan: actions.payload.data,
      };
    case GET_PARA_SYSTEM:
      return {
        ...state,
        getParaSystem: actions.payload.data,
      };
    case GET_PARA_SYSTEM_ID:
      return {
        ...state,
        getParaSystemId: actions.payload.data,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: actions.payload
      }
    case SET_BARANG_PESANAN:
      return{
        ...state,
        barangPesanan: actions.payload
      }
    default:
      return state;
  }
};

export default utility;
