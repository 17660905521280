//Mendaftarkan reducer / Menggabungkan
import { combineReducers } from "redux";
import datamaster from "./datamaster";
import { reducer as formReducer } from "redux-form";
import manageusers from "./manageusers";
import databarang from "./databarang";
import penjualan from "./penjualan";
import services from "./services";
import hutang from "./hutang";
import datamember from "./datamember";
import pembelian from "./pembelian";
import utility from "./utility";
import stateKategoriKatalog from "../pages/KatalogApp/KategoriKatalog/redux/kategoriKatalogRedux";
import stateBannerKatalog from "../pages/KatalogApp/BannerKatalog/redux/bannerKatalogRedux";
import stateInfoKatalog from "../pages/KatalogApp/InfoKatalog/redux/infoKatalogRedux";
import stateItemKatalog from "../pages/KatalogApp/BarangKatalog/redux/itemKatalogRedux";
import stateKontakAdminKatalog from "../pages/KatalogApp/KontakAdminKatalog/redux/kontakAdminKatalogRedux";
import stateHadiahKatalog from "../pages/KatalogApp/HadiahKatalog/redux/hadiahKatalogRedux";
import stateHargaKatalog from "../pages/KatalogApp/HargaKatalog/redux/hargaKatalogRedux";
import stateSplashscreenKatalog from "../pages/KatalogApp/SplashscreenKatalog/redux/splashscreenKatalogRedux";
import stateGroupDescriptionKatalog from "../pages/KatalogApp/GroupDescriptionKatalog/redux/groupDescriptionKatalogRedux";
export default combineReducers({
  datamaster,
  manageusers,
  databarang,
  penjualan,
  services,
  hutang,
  datamember,
  pembelian,
  utility,
  stateKategoriKatalog,
  stateBannerKatalog,
  stateInfoKatalog,
  stateItemKatalog,
  stateKontakAdminKatalog,
  stateHadiahKatalog,
  stateHargaKatalog,
  stateSplashscreenKatalog,
  stateGroupDescriptionKatalog,
  form: formReducer,
});
