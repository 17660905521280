import jsPDF from "jspdf";
import "jspdf-autotable";
import { getItem, getToday, getUserdata, localStoragedecryp } from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const CetakLaporanHistoryCetakBarcodePdf = (
    data = "",
) => {
    // initialize jsPDF
    const doc = new jsPDF("l", "mm", [297, 210]);
    //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
    let tableRows = [];
    // let footRows = [];
    let tableColumn = [];
    let finalY = 30;


    doc.setFontSize(15);
    doc.text("LAPORAN HISTORY CETAK BARCODE", 14, 15);
    doc.setFontSize(20);
    doc.text(getUserdata().nama_toko, 200, 15);
    doc.setFontSize(8);
    let jml_alamat = getUserdata().alamat_toko.length;
    if (jml_alamat > 20) {
        doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
    }
    if (jml_alamat > 50) {
        doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
    }
    doc.setFontSize(10);
    doc.setProperties({
        title: "LAPORAN HISTORY CETAK BARCODE",
    });
    doc.text(
        `PERIODE : ${getItem("tgl_laporan").length === 0
            ? null
            : getItem("tgl_laporan").tgl_awal + " s/d " + getItem("tgl_laporan").tgl_akhir
        }`,
        14,
        25
    );

    tableColumn = [
        [
            {
                content: `No`,
            },
            {
                content: `TANGGAL`,
            },
            {
                content: `USER ID`,
            },
            {
                content: `BARCODE`,
            },
        ],
    ];

    data.forEach((item, index) => {
        let rows = [
            {
                content: `${++index}`,
                styles: {
                    cellWidth: 10
                },
            },
            item.tanggal,
            item.user_id,
            item.kode_barcode,
        ];
        tableRows.push(rows);
    });
    let printed = [
        {
            colSpan: 5,
            content: `Printed By ${JSON.parse(localStoragedecryp("userdata")).user_id} / ${getToday()}`,
            styles: {
                fontStyle: "italic",
                textColor: "#000",
            },
        },
    ]
    tableRows.push(printed);
    doc.autoTable({
        head: tableColumn,
        body: tableRows,
        // foot: footRows,
        startY: finalY,
        theme: "plain",
        rowPageBreak: "avoid",
        // pageBreak: "avoid",
        margin: { top: 10 },
        bodyStyles: {
            // lineWidth: 0.02,
            // lineColor: "#000",
            fontSize: 8,
        },
        headStyles: {
            fontSize: 8,
            // lineWidth: 0.02,
            // lineColor: "#000",
            textColor: "#000",
            fillColor: "#E8E5E5",
        },
    });
    finalY = doc.autoTableEndPosY() + 3;
    tableRows = [];
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 10; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
            align: "center",
        });
    }
    // doc.autoPrint();
    // doc.save(`LAPORAN BARANG DETAIL.pdf`);
    var string = doc.output("datauristring");
    // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    x.document.open();
    x.document.write(
        "<html><head><title>LAPORAN BARANG DETAIL</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
        string +
        "></embed></body></html>"
    );
    // x.document.write(embed);
    // setInterval(() => {
    //   x.close();
    // }, 1000);
};

export default CetakLaporanHistoryCetakBarcodePdf;
