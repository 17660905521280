import React from "react";
import { Link } from "react-router-dom";
import { isPos } from "./function";

const BackButtonPos = (props) => {
  const link = props.link;
  const children = props.children;
  return isPos() ? (
    <>
      <Link to={link}>
        <button
          type="button"
          className="btn btn-secondary"
          data-tut="reactour_4"
        >
          <i className="fa fa-angle-left mr-3" aria-hidden="true" /> Home
        </button>
      </Link>
      <br />
      <br />
    </>
  ) : (
    <>{children}</>
  );
};

export default BackButtonPos;
