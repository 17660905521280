export const SHOW_MODAL_PENJUALAN_DATA_CUSTOMER = "SHOW_MODAL_PENJUALAN_DATA_CUSTOMER";
export const SHOW_MODAL_TAMBAH_BARANG = "SHOW_MODAL_TAMBAH_BARANG";
export const SHOW_MODAL_PEMBAYARAN = "SHOW_MODAL_PEMBAYARAN";
export const SET_BARANG_PENJUALAN = "SET_BARANG_PENJUALAN";
export const SET_BARANG_PEMBELIAN = "SET_BARANG_PEMBELIAN";
export const SET_BATAL_PENJUALAN = "SET_BATAL_PENJUALAN";
export const SET_BATAL_PEMBELIAN = "SET_BATAL_PEMBELIAN";
export const SET_NO_FAKTUR_PENJUALAN = "SET_NO_FAKTUR_PENJUALAN";
export const SET_BARANG_TUKAR = "SET_BARANG_TUKAR";

export const SET_OTORISASI = "SET_OTORISASI";


export const setOtorisasi = (data) => {
    return (dispatch) => {
      dispatch({
        type: SET_OTORISASI,
        payload: {
          data: data,
        },
      });
    };
};
export const setNoFakturPenjualan = (data) => {
    return (dispatch) => {
      dispatch({
        type: SET_NO_FAKTUR_PENJUALAN,
        payload: {
          data: data,
        },
      });
    };
};

export const ShowModalDataPenjualanCustomer = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_PENJUALAN_DATA_CUSTOMER,
        payload: {
          data: data,
        },
      });
    };
};
export const ShowModalTambahBarang = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_TAMBAH_BARANG,
        payload: {
          data: data,
        },
      });
    };
};
export const showModalPembayaran = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_PEMBAYARAN,
        payload: {
          data: data,
        },
      });
    };
};

export const setBarangPenjualan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_PENJUALAN,
      payload: {
        data: data,
      },
    });
  };
}
export const setBatalPenjualan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BATAL_PENJUALAN,
      payload: {
        data: data,
      },
    });
  };
}
export const setBatalPembelian = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BATAL_PEMBELIAN,
      payload: {
        data: data,
      },
    });
  };
}

export const setBarangPembelian = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_PEMBELIAN,
      payload: {
        data: data,
      },
    });
  };
}

export const setBarangTukar = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_TUKAR,
      payload: {
        data: data,
      },
    });
  };
}