import { GET_INFO_KATALOG, SET_DATA_INFO_KATALOG } from "./infoKatalogAction";

const initialState = {
  infoKatalog: [
   
  ],
  ShowModal: null,
};

const stateInfoKatalog = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_INFO_KATALOG:
      return {
        ...state,
        infoKatalog: actions.payload.data,
      };
    case SET_DATA_INFO_KATALOG:
      return {
        ...state,
        ShowModal: actions.payload,
      };
    default:
      return state;
  }
};
export default stateInfoKatalog;
