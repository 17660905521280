import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate } from "../../../components/helpers/field";
import { getTglSystem } from "../../../components/helpers/function";
import Tabel from "../../../components/helpers/tabel";
import Loading from 'react-fullscreen-loading';
import CetakLaporanHistoryCetakBarcodePdf from "./pdf/HistoryCetakBarcodePdf";
import CetakLaporanHistoryCetakBarcodeExcel from "./excel/HistoryCetakBarcodeExcel";
// import CetakLaporanHistoryCetakBarcodeExcel from "./excel/HistoryCetakBarcodeExcel";

class HeadLaporanHistoryCetakBarcode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
        };
        this.setStartDate = this.setStartDate.bind(this);
        this.setLastDate = this.setLastDate.bind(this);
    }

    handleSubmit(data) {
        console.log(data);
    }
    exportPdf() {
    }
    setStartDate(date) {
        this.setState({
            tgl_awal: new Date(date),
        });
    }
    setLastDate(date) {
        this.setState({
            tgl_akhir: new Date(date),
        });
    }
    render() {

        const columns = [
            {
                dataField: "tanggal",
                text: "Tanggal",
                footerAttrs: {
                    colSpan: "3",
                },
            },
            {
                dataField: "user_id",
                text: "User Id",
            },
            {
                dataField: "kode_barcode",
                text: "Barcode",
            },



        ];
        return (
            <form onSubmit={this.props.handleSubmit} autoComplete="off">
                <div className="row">
                    <div className="col-lg-4">
                        <Field
                            name="tgl_awal"
                            component={InputDate}
                            label="Tanggal Dari"
                            type="text"
                            selected={this.state.tgl_awal}
                            onChange={(date) => this.setStartDate(date)}
                            placeholder="Masukan Tanggal Dari"
                        />
                    </div>

                    <div className="col-lg-4">
                        <Field
                            name="tgl_akhir"
                            component={InputDate}
                            type="text"
                            selected={this.state.tgl_akhir}
                            onChange={(date) => this.setLastDate(date)}
                            label="Tanggal Akhir"
                            placeholder="Masukan Tanggal Akhir"
                        />
                    </div>
                    <div className="col-lg-4 text">
                        <div className="text-right">
                            <label>&nbsp;</label>
                            <button
                                type="submit"
                                value="PDF"
                                name="type_btn"
                                className="btn btn-primary btn-block"
                            >
                                {this.props.isLoading ? (
                                    <>
                                        <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                                        Mencari
                                    </>
                                ) : (
                                    "Cari Laporan"
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <Tabel
                            keyField="no_hp"
                            data={this.props.dataCetakBarcode}
                            columns={columns}
                            empty={true}
                            textEmpty="Data Laporan Otorisasi Kosong"
                        />
                    </div>
                    <div className={this.props.dataCetakBarcode.length > 0 ? "col-lg-12" : "col-lg-12 d-none"}>
                        {/* <label htmlFor="">&nbsp;</label> */}
                        <div className="row">
                            <div className="col-lg-6">
                                <button
                                    type="button"
                                    onClick={() => CetakLaporanHistoryCetakBarcodePdf(this.props.dataCetakBarcode)}
                                    className="btn btn-warning btn-block"
                                >
                                    {" "}
                                    Export PDF{" "}
                                </button>
                            </div>
                            <div className="col-lg-6">
                                <CetakLaporanHistoryCetakBarcodeExcel data={this.props.dataCetakBarcode} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.isLoading ? (
                    <Loading
                        loading
                        background="rgba(0, 0, 0, 0.35)"
                        loaderColor="rgba(94, 147, 117, 1)" />
                ) : null}
            </form>
        );
    }
}

HeadLaporanHistoryCetakBarcode = reduxForm({
    form: "HeadLaporanAuthorization",
    enableReinitialize: true,
})(HeadLaporanHistoryCetakBarcode);
// export default connect()(HeadLaporanAuthorization);
export default connect((state) => {
    return {
        initialValues: {
            tgl_awal: getTglSystem(),
            tgl_akhir: getTglSystem(),
        },
    };
})(HeadLaporanHistoryCetakBarcode);
