import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate } from "../../../components/helpers/field";
import { getTglSystem } from "../../../components/helpers/function";
import Tabel from "../../../components/helpers/tabel";
import LaporanAktivasiMemberExel from "./exel/LaporanAktivasiMemberExel";
import LaporanAktivasiMemberPdf from "./pdf/LaporanAktivasiMemberPdf";
import Loading from "react-fullscreen-loading";
import { getMasterToko } from "../../../actions/datamaster_action";
class HeadFormAktivasiMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  handleSubmit(data) {
    console.log(data);
  }
  exportPdf() {
    LaporanAktivasiMemberPdf(this.props.datamember);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount() {
    this.props.dispatch(getMasterToko());
    this.props.change("kode_toko", "ALL");
  }

  render() {
    let columns = [
      {
        dataField: "kode_member",
        text: "Kode Member",
        footerAttrs: {
          colSpan: "4",
        },
        footer: (cell, row, num, index) => {
          return <div>Total Daftar : {this.props.datamember.length} </div>;
        },
      },
      {
        dataField: "nama_customer",
        text: "NAMA CUSTOMER",
      },
      {
        dataField: "no_hp",
        text: "NO HANDPHONE",
      },
      {
        dataField: "point",
        text: "Point",
        formatter: (value, row) => {
          return value || 0;
        },
      },
      {
        dataField: "alamat_customer",
        text: "Alamat",
        formatter: (value, row) => {
          return (
            value + " " + row.kecamatan + " " + row.kelurahan + " " + row.kota
          );
        },
      },
    ];

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="kode_member"
              data={this.props.export ? this.props.datamember : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Aktivasi Member Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            <label htmlFor="">&nbsp;</label>
            <div className="row">
              <div className="col-lg-6">
                <button
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  Export PDF
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanAktivasiMemberExel data={this.props.datamember} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadFormAktivasiMember = reduxForm({
  form: "HeadFormAktivasiMember",
  enableReinitialize: true,
})(HeadFormAktivasiMember);
// export default connect()(HeadFormAktivasiMember);
export default connect((state) => {
  return {
    getMasterToko: state.datamaster.getMasterToko,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadFormAktivasiMember);
