import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { HiidenFiled, ReanderField } from "../../../components/helpers/field";

class OtorisasiHancur extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // let data = JSON.parse(localStorage.getItem("no_faktur_batal_jual"));
    // this.props.change("no_faktur_group", data.no_faktur_group);
    // this.props.change("alasan", data.alasan);
    setTimeout(() => {
      document.getElementById('username').focus();
    }, 100);
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-6">
            <Field
              name="no_faktur_group"
              component={HiidenFiled}
              type="hidden"
            />
            <Field name="alasan" component={HiidenFiled} type="hidden" />
            <Field
              id="username"
              name="username"
              component={ReanderField}
              type="text"
              nouperCase
              label="Username"
              placeholder="Masukan Username"
            />
          </div>
          <div className="col-lg-6">
            <Field
              name="password"
              component={ReanderField}
              type="password"
              nouperCase
              label="Password"
              placeholder="Masukan Password"
            />
          </div>
          <div className={this.props.keterangan ? "col-lg-12" : "col-lg-12 d-none"}>
            <Field
              name="keterangan"
              component={ReanderField}
              label="Keterangan"
              placeholder="Masukan Keterangan"
            />
          </div>
          <div className="col-lg-12">
            <label> &nbsp; </label>
            <button
              type="submit"
              name="type_btn"
              className="btn btn-primary btn-block"
              disabled={this.props.isLoadingOtorisasi}
            >
              {this.props.isLoadingOtorisasi ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Otorisasi
                </>
              ) : (
                "Otorisasi"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

OtorisasiHancur = reduxForm({
  form: "OtorisasiHancur",
  enableReinitialize: true,
})(OtorisasiHancur);
export default connect(null)(OtorisasiHancur);
