import moment from "moment";
import React from "react";
import Avatar from "react-avatar";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
} from "reactstrap";
import Swal from "sweetalert2";
import { postData, putData } from "../../../config/axios";
import { formatDate, getUserdata } from "../../helpers/function";
import { Loading, postDataNoTImeOut, showModal } from "../../helpers/library";
import { ToastNotification } from "../../helpers/notification";
import { io } from "socket.io-client";
const server = process.env.REACT_APP_BACKEND_URL;
const url = process.env.REACT_APP_URL;

const logout = () => {
  Swal.fire({
    title: "Konfirmasi Keluar !!",
    text: "Apakah anda ingin keluar ?",
    icon: "info",
    position: "top-center",
    cancelButtonText: "Tidak",
    showCancelButton: true,
    confirmButtonText: "OK",
    showConfirmButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      // window.location.reload();
      // this.props.history.push("/")
      // window.location.href = "/nagagold_online.com/";
      // localStorage.clear();
      // localStorage.setItem("islogin", "false");
      postData("users/logout", { user_id: getUserdata().user_id })
        .then((res) => {
          // console.log(res.data);
          // ToastNotification("success", "Berhasil Menutup Toko");
          // setTimeout(() => {
          // window.location.reload();
          window.location.href = "/";
          localStorage.clear();
          localStorage.setItem("islogin", "false");
          // }, 1000);
        })
        .catch((err) => {
          ToastNotification(
            "info",
            err.response === undefined ? "Gagal Logout" : err.response.data
          );
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        });
    }
  });
};
class DropdownProfile extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      modalDialog: false,
      isLoading: false,
      isLoadingTutupToko: false,
      progress: {},
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      isLoading: true,
    });
    let hasil = {
      password: this.state.password_lama,
      new_password: this.state.password_baru,
      // retype_new_password : this.state.password_baru,
    };

    putData("users/change-password", hasil)
      .then((res) => {
        this.toggleModal("modalDialog");
        this.setState({
          isLoading: false,
        });
        ToastNotification("success", "Password Berhasil Diubah");
      })
      .catch((err) => {
        ToastNotification(
          "info",
          err.response === undefined
            ? "Terjadi Kesalahan Saat Request Data"
            : err.response.data
        );
      });
  }
  onChance = (nama) => (e) => {
    this.setState({
      [nama]: e.target.value,
    });
  };
  toggleModal(name) {
    switch (name) {
      case "modalDialog":
        this.setState({ modalDialog: !this.state.modalDialog });
        break;
      default:
        break;
    }
  }
  showModalTutupToko() {
    var datatoday = new Date();
    var datatodays = datatoday.setDate(
      new Date(new Date(getUserdata().tgl_system)).getDate() + 1
    );
    var todate = new Date(datatodays);
    Swal.fire({
      html:
        "<h1><b>Apakah Anda Yakin Ingin  <br/> " +
        "Menutup Toko" +
        "</b> ?</h1><br>" +
        "Dari Tanggal " +
        getUserdata().tgl_system +
        " Ke " +
        formatDate(moment(new Date(todate)).format("YYYY-MM-DD")),
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          isLoadingTutupToko: true,
        });
        this.setState({
          modal: "TUTUP_TOKO",
        });
        this.toggleModal("modalDialog");
        this.socket = io.connect(server.replace("/api/v1/", ""));
        let selft = this;
        this.socket.emit("start-closed-store", () => {});
        this.socket.on(`closed-store-progress`, (data) => {
          selft.setState({
            progress: data,
          });
        });

        postDataNoTImeOut("system/close", { PROSES: "close" })
          .then((res) => {
            ToastNotification("success", "Berhasil Menutup Toko");
            setTimeout(() => {
              // window.location.reload();
              window.location.href = url;
              localStorage.clear();
              localStorage.setItem("islogin", "false");
              this.setState({
                isLoadingTutupToko: false,
              });
            }, 1000);
          })
          .catch((err) => {
            this.setState({
              isLoadingTutupToko: false,
            });
            this.toggleModal(false);
            // console.log(err.response == undefined);
            // console.log(err?.response?.status)
            if (err?.response?.status === 422) {
              this.setState({
                modal: "TUTUP_TOKO",
              });
              this.toggleModal("modalDialog");
            }

            err?.response?.data === "Invalid token."
              ? this.handleInvalidToken()
              : err?.response?.data === "Error! Sedang dalam proses tutup toko!"
              ? this.handleInvalidToken()
              : ToastNotification(
                  "info",
                  err?.response?.data || "Terjadi Kesalahan Saat Mengirim Data"
                );
          });
      } else {
        this.toggleModal(false);

        this.setState({
          modal: "BUKAN",
        });
        this.setState({
          isLoadingTutupToko: false,
        });
      }
    });
  }

  handleInvalidToken() {
    ToastNotification("info", "Toko Sudah Ditutup Oleh Akun Lain");

    setTimeout(() => {
      window.location.href = "/";
      localStorage.clear();
      localStorage.setItem("islogin", "false");
    }, 1000);
  }
  gantiPassword() {
    this.props.dispatch(showModal());
  }
  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="dropdown navbar-user"
        tag="li"
      >
        <DropdownToggle tag="a">
          <Avatar
            name={getUserdata().user_id}
            size="30"
            color="#00743C"
            round="20px"
          />
          <span
            className="d-none d-md-inline"
            style={{ cursor: "pointer" }}
          ></span>{" "}
          <b className="caret"></b>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          {/* <DropdownItem>Edit Profile</DropdownItem> */}
          {/* <DropdownItem>Setting</DropdownItem> */}
          <DropdownItem onClick={() => this.showModalTutupToko()}>
            Tutup Toko
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={() => this.toggleModal("modalDialog")}>
            Ganti Password
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={() => logout()}> Logout </DropdownItem>
        </DropdownMenu>
        <Modal
          isOpen={this.state.modalDialog}
          toggle={() =>
            this.state.modal !== "TUTUP_TOKO"
              ? this.toggleModal("modalDialog")
              : null
          }
          centered
        >
          {this.state.modal !== "TUTUP_TOKO" ? (
            <ModalHeader>Ganti Password</ModalHeader>
          ) : null}
          <ModalBody>
            {this.state.modal === "TUTUP_TOKO" ? (
              <div className="text-center">
                <h2>
                  Mohon Menunggu <br /> Sedang Menutup Toko !!!
                </h2>
                <br />

                <Progress
                  animated
                  value={this.state.progress?.percentage}
                  max="100"
                  barClassName="custome"
                >
                  {this.state.progress.percentage}%
                </Progress>
                {this.state.progress.message}
                {/* {this.state.progress.progress} / {this.state.progress.total} */}
              </div>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label> Password Lama </label>
                  <input
                    id="password_lama"
                    type="password"
                    name="password_lama"
                    value={this.state.password_lama}
                    onChange={this.onChance("password_lama")}
                    className="form-control"
                    placeholder="Masukkan Password Lama"
                  />
                </div>
                <div className="form-group">
                  <label> Password Baru </label>
                  <input
                    type="password"
                    name="password_baru"
                    value={this.state.password_baru}
                    onChange={this.onChance("password_baru")}
                    className="form-control"
                    placeholder="Masukkan Password Baru"
                  />
                </div>
                <div style={{ textAlign: "right" }}>
                  <button
                    className="btn btn-white"
                    type="button"
                    onClick={() => this.toggleModal("modalDialog")}
                  >
                    Batal
                  </button>
                  &nbsp;
                  <button
                    disabled={this.props.isLoading}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {this.props.isLoading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                        Menyimpan
                      </>
                    ) : (
                      "Simpan Data"
                    )}
                  </button>
                </div>
              </form>
            )}
          </ModalBody>
        </Modal>
        {this.state.isLoadingTutupToko ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </Dropdown>
    );
  }
}

export default DropdownProfile;
