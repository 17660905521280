const ValidasiLapMutasiHadiah = (value) => {
  const errors = {};
  if (!value.type) {
    errors.type = "Type Laporan Harus Di pilih";
  }
  if (!value.kategori) {
    errors.kategori = "Kategori Harus Di Isi";
  }

  return errors;
};





export default ValidasiLapMutasiHadiah;
