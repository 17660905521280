import { hideModal, showModal } from "actions/datamaster_action";

import { NotifSucces, ToastNotification } from "components/helpers/library";
import { NotifError } from "components/helpers/notification";
import { deleteData, getData, postData, putData } from "config/axios";
import { setLoading, stopLoading } from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_KONTAK_ADMIN_KATALOG = "GET_KONTAK_ADMIN_KATALOG";
export const SET_DATA_KONTAK_ADMIN_KATALOG = "SET_DATA_KONTAK_ADMIN_KATALOG";
export const DELETE_KONTAK_ADMIN_KATALOG = "DELETE_KONTAK_ADMIN_KATALOG";
export const EDIT_KONTAK_ADMIN_KATALOG = "EDIT_KONTAK_ADMIN_KATALOG";
export const POST_KONTAK_ADMIN_KATALOG = "POST_KONTAK_ADMIN_KATALOG";

export const fetchKontakAdminKatalog = () => {
  return (dispatch) => {
    dispatch(setLoading())
    getData("app/member/admin-chat")
      .then((res) => {
        dispatch({
          type: GET_KONTAK_ADMIN_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setEditKontakAdminKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_KONTAK_ADMIN_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteKontakAdminKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Kontak Admin " +
        data.kode_admin +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteKontakAdminKatalog(data.kode_admin, data.gambar_name));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahKontakAdminKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_KONTAK_ADMIN_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteKontakAdminKatalog = (kode_kategori, gambar_name) => {
  return (dispatch) => {
    dispatch(setLoading())
    deleteData("app/member/admin-chat/delete/" + kode_kategori)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus").then(() =>
          dispatch(fetchKontakAdminKatalog())
        );
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Menghapus KontakAdmin Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const updateKontakAdminKatalog = (kode_admin, data) => {
  return (dispatch) => {
    dispatch(setLoading())
    putData("app/member/admin-chat/edit/" + kode_admin, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchKontakAdminKatalog());
        dispatch(hideModal());
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Merubah KontakAdmin Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const sendKontakAdminKatalog = (data) => {
  return (dispatch) => {
    dispatch(setLoading())
    postData("app/member/admin-chat", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchKontakAdminKatalog());
        dispatch(hideModal());
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Menambahkan KontakAdmin Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const handleSubmitKontakAdminKatalog = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const current = state.stateKontakAdminKatalog.ShowModal;
    if(data.nama_admin === undefined || data.nama_admin === ""){
      NotifError("Mohon Masukan Nama Admin");
      return false
    } else if (data.no_hp === undefined || data.no_hp === ""){
      NotifError("Mohon Masukan Nomor Handphone");
      return false;
    } else if (data.no_hp.length < 11){
      NotifError("Mohn Masukan Nomor Handphone Yang Valid");
      return false;
    }
    if (current) {
      let kode_admin = data.kode_admin;
      delete data.kode_admin;
      dispatch(updateKontakAdminKatalog(kode_admin, data));
      dispatch(stopLoading())
    } else {
      dispatch(sendKontakAdminKatalog(data));
      dispatch(stopLoading())
    }
  };
};
