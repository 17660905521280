import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  getData,
  convertDate,
  PanelBody,
  setItem,
  ToastNotification,
} from "../../../../components/helpers/library";
import HeadLaporanHasilSo from "./HeadLaporanHasilSo";

class FormLaporanHasilSO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      LaporanMutasiHadiah: [],
      LaporanHasilSo: [],
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  handleSubmit(data) {
    let startDate = convertDate(data.start_date);
    let typeLaprekap = data.typeLap
    let kodeBaki = data.kode_baki === undefined ? "ALL" : data.kode_baki
    let typeLaporan = data.type === undefined ? "" : data.type
    this.setState({ isLoading: true });
    let data_post = {
      tgl_awal: convertDate(data.start_date),
    };
    setItem("tgl_laporan", data_post);
    getData(`/opname/report?type=${typeLaporan}&tanggal=${startDate}&baki=${kodeBaki}&type_laporan=${typeLaprekap}`)
      .then((res) => {
        if (res.data.length === 0) {
          this.setState({
            export: false,
            LaporanHasilSo: [],
            isLoading: false,
          });
          ToastNotification("info", "Laporan Hasil SO Tidak Tersedia");
        } else {
          ToastNotification("success", "Laporan Hasil SO Tersedia");



          setItem("tgl_laporan", data_post);
          this.setState({
            export: true,
            isLoading: false,
            LaporanHasilSo: res.data,
          });
        }
      })
      .catch((error) => {
        ToastNotification(
          "info",
          error?.response?.data || "Terjadi Kesalahan saat mengirim data"
        );
      });
  }


  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">
            Laporan Hasil Stock Opname{" "}
          </li>
        </ol>
        <h1 className="page-header">Laporan Hasil Stock Opname</h1>
        <Panel>
          <PanelHeader>Laporan Hasil Stock Opname</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <HeadLaporanHasilSo
              export={this.state.export}
              isLoading={this.state.isLoading}
              LaporanHasilSo={this.state.LaporanHasilSo}
              onSubmit={(data) => this.handleSubmit(data)}
              kosongData={() => this.setState({
                LaporanHasilSo: []
              })}
            />
          </PanelBody>

          <br />
          {/* End Tambah Blank Page  */}
        </Panel>
      </div>
    );
  }
}

FormLaporanHasilSO = reduxForm({
  form: "FormLaporanHasilSO",
  enableReinitialize: true,
})(FormLaporanHasilSO);
export default connect(null)(FormLaporanHasilSO);
