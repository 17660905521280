import { getItem, React } from "components/helpers/library";



const TotalPembelian = (props) => {
  return (
    <div className="row">
      <div className="col-10 text-right">Total Faktur Gold : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .map((cek) => cek.status_gold === "GOLD" && cek.status_cek === true)
          .reduce((a, b) => a + b, 0)}{" "}
      </div>
      <div className="col-10 text-right">Total Faktur Uang Gold : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .filter(
            (cek2) => cek2.status_gold === "GOLD" && cek2.status_cek === true
          )
          .reduce((a, b) => a + Number(b.harga_total), 0)
          .toLocaleString("kr-Ko")}{" "}
      </div>
      <div className="col-10 text-right">Total Berat Gold : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .filter(
            (cek3) => cek3.status_gold === "GOLD" && cek3.status_cek === true
          )
          .reduce((a, b) => a + b.berat, 0)
          .toFixed(3)}{" "}
      </div>
      <div className="col-8 text-right"> </div>
      <div className="col-2">
        {" "}
        ==================================================
      </div>
      <div className="col-10 text-right">Total Faktur Non Gold : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .map(
            (cek) => cek.status_gold === "NONGOLD" && cek.status_cek === true
          )
          .reduce((a, b) => a + b, 0)}{" "}
      </div>
      <div className="col-10 text-right">Total Faktur Uang Non Gold : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .filter(
            (cek2) => cek2.status_gold === "NONGOLD" && cek2.status_cek === true
          )
          .reduce((a, b) => a + Number(b.harga_total), 0)
          .toLocaleString("kr-Ko")}{" "}
      </div>
      <div className="col-10 text-right">Total Berat Non Gold : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .filter(
            (cek3) => cek3.status_gold === "NONGOLD" && cek3.status_cek === true
          )
          .reduce((a, b) => a + b.berat, 0)
          .toFixed(3)}{" "}
      </div>
      <div className="col-8 text-right"> </div>
      <div className="col-2">
        {" "}
        ==================================================
      </div>
      <div className="col-10 text-right">Total Faktur : </div>
      <div className="col-2">
        {" "}
        {getItem("data_trancate_pembelian_baru")
          .map((cek) => cek.status_gold === "GOLD" && cek.status_cek === true)
          .reduce((a, b) => a + b, 0) +
          getItem("data_trancate_pembelian_baru")
            .map(
              (cek) => cek.status_gold === "NONGOLD" && cek.status_cek === true
            )
            .reduce((a, b) => a + b, 0)}{" "}

      </div>
      <div className="col-10 text-right">Total Faktur Uang : </div>
      <div className="col-2">
        {" "}
        {(
          getItem("data_trancate_pembelian_baru")
            .filter(
              (cek2) =>
                cek2.status_gold === "NONGOLD" && cek2.status_cek === true
            )
            .reduce((a, b) => a + Number(b.harga_total), 0) +
          getItem("data_trancate_pembelian_baru")
            .filter(
              (cek2) => cek2.status_gold === "GOLD" && cek2.status_cek === true
            )
            .reduce((a, b) => a + Number(b.harga_total), 0)
        ).toLocaleString("kr-Ko")}{" "}
      </div>
      <div className="col-10 text-right">Total Berat : </div>
      <div className="col-2">

        {parseFloat(getItem("data_trancate_pembelian_baru")
          .filter(
            (cek3) => cek3.status_gold === "NONGOLD" && cek3.status_cek === true
          )
          // .reduce((a, b) => a + b.berat, 0) +
          .reduce((a, b) => a + b.berat, 0)
          // .reduce((a, b) => formatGram(a + b.berat))
          +
          getItem("data_trancate_pembelian_baru")
            .filter(
              (cek3) => cek3.status_gold === "GOLD" && cek3.status_cek === true
            )
            // .reduce((a, b) => a + b.berat, 0)}
            .reduce((a, b) => a + parseFloat(b.berat), 0)).toFixed(3)}


      </div>
      <div className="col-8 text-right"> </div>
      <div className="col-2">
        {" "}
        ==================================================
      </div>
      <div className="col-8 text-right"></div>
      <div className="col-4 text-center">
        {" "}
        <button className="btn btn-primary" onClick={() => props.truncate()} disabled={props.isLoading}>
          {props.isLoading ? (
            <>
              <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
              Memproses
            </>
          ) : (
            "Backup"
          )}
        </button>
        {/* <button className="btn btn-primary " onClick={() => props.truncate()}>
          {" "}
          Backup{" "}
        </button>{" "} */}
        &nbsp;{" "}
        <button className="btn btn-warning " onClick={() => props.clear()}>
          {" "}
          Clear{" "}
        </button>{" "}
      </div>
    </div>
  );
};

export default TotalPembelian;
