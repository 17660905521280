import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanTukarPointPdf = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("LAPORAN TUKAR POINT MEMBER", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN TUKAR POINT MEMBER",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
          " s/d " +
          JSON.parse(localStoragedecryp("tgl_laporan")).tgl_akhir
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `KODE MEMBER`,
      },
      {
        content: `KODE TOKO`,
      },
      {
        content: `BARANG TUKAR`,
      },
      {
        content: `POINT AWAL`,
      },
      {
        content: `HARGA POINT`,
      },
      {
        content: `POINT AKHIR`,
      },
    ],
  ];
  data.forEach((row, index) => {
    // console.log(item._id.pembayaran[0].jenis);
    let data = [
      {
        content: `Tanggal Transaksi : ${row.tgl_trx}`,
        colSpan: "6",
      },
    ];
    tableRows.push(data);
    let detail = [
      {
        content: row.kode_member,
      },
      {
        content: row.kode_toko || "",
      },
      {
        content: row.deskripsi,
      },
      {
        content: row.poin_awal,
      },
      {
        content: row.poin,
      },
      {
        content: row.poin_akhir,
      },
    ];

    tableRows.push(detail);
  });
  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${
        JSON.parse(localStoragedecryp("userdata")).user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  footRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // footRows = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     JSON.parse(localStoragedecryp("userdata")).user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  // doc.text(`Total Daftar              : ${total_semua}`, 14, finalY + 22);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`LAPORAN DAFTAR MEMBER.pdf`);
  var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN DAFTAR MEMBER</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanTukarPointPdf;
