import {
  React,
  reduxForm,
  Component,
  connect,
  getItem,
  setItem,
  Tabel,
  Swal,
  formatDate,
  moment,
  ToastNotification
} from "../../components/helpers/library";

import ValidasiPembayaran from "../../Validasi/Penjualan/ValidasiPembayaran";
import HeadInputPembayaran from "./HeadInputPembayaran";
import Loading from 'react-fullscreen-loading';

// data_barang_penjualan_tmp


const maptostate = (state) => {
  let data_barang = getItem("data_barang_penjualan_tmp") || [];
  let status = "PENJUALAN";
  data_barang.forEach((element) => {
    if (element.no_titip_group === "-") {
      status = "PENJUALAN";
    } else {
      status = "TITIPAN";
    }
  });
  return {
    status: status,
  };
};
class ModalPembayaran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      type_pembayaran: "CASH",
      columns: [
        {
          dataField: "jenis",
          text: "Jenis",
          footerAttrs: {
            colSpan: "2",
          },
          footer: "Grand Total",
        },
        {
          dataField: "keterangan",
          text: "Keterangan",
        },
        {
          dataField: "nominal",
          text: "Total",
          formatter: (cell) => {
            return <span>{parseInt(cell).toLocaleString("kr-KO")}</span>;
          },
          footer: (columnData) =>
            parseInt(
              columnData.reduce((acc, item) => acc + item, 0)
            ).toLocaleString("kr-KO"),
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    disabled={row.keterangan === "TITIPAN" ? true : false}
                    onClick={() => this.hapusdata(index)}
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
          footer: "",
        },
      ],
    };
  }
  hapusdata(id) {
    Swal.fire({
      title: "Anda Yakin !!",
      text: "Ingin Menghapus Data Ini ?",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        let data = getItem("trx_pemabayaran_pesanan");
        data.splice(id, 1);
        setItem("trx_pemabayaran_pesanan", data);
        this.setState({
          status: "berhasil",
        });
        // deleteLocalItemBarcode("data_barang_hancur_tmp", id);
        // this.props.dispatch(getListReturnSupplier());
      }
    });
  }
  simpanPembayaran(row) {
    if (row.no_card === undefined && row.jenis !== "CASH" && row.jenis !== "DEBET") {
      ToastNotification("info", "Nomor kartu tidak boleh kosong");
      return false;
    }
    if (row.nama_bank === undefined && row.jenis !== "CASH") {
      ToastNotification("info", "Nama Bank tidak boleh kosong");
      return false;
    }
    // if (row.cash === undefined || row.cash === "0") {
    //   ToastNotification("info", "Nominal tidak boleh kosong");
    //   return false;
    // }
    let datacek = {
      total_transaksi: row.harus_bayar_rp,
      sisa_bayar: row.nominal_kembali
    }
    setItem('sisa_bayar_pesanan', datacek)
    let data = JSON.parse(localStorage.getItem("trx_pemabayaran_pesanan")) || [];
    if (row.jenis !== "CASH" && !data.length) {
      if (row.kembali.replace(/[^0-9]/g, "") - row.cash < 0) {
        ToastNotification("info", "Pembayaran tidak boleh lebih dari sisa");
        return false;
      }
    } else {
      if (row.jenis !== "CASH") {
        if (row.kembali.replace(/[^0-9]/g, "") - row.cash < 0 && parseInt(row.kembali) !== 0) {
          ToastNotification("info", "Pembayaran tidak boleh lebih dari sisa");
          return false;
        } else {
          if (data.reduce((a, b) => a + b.nominal, 0) >= row.harus_bayar_rp) {
            ToastNotification("info", "Pembayaran Sudah Cukup");
            return false;
          }
        }
      }
    }


    let cash = data.findIndex((hasil) => hasil.jenis === "CASH");
    if (cash >= 0) {
      if (row.kembali.replace(/[^0-9]/g, "") - row.cash < 0 && data.reduce((a, b) => a + b.nominal, 0) > row.harus_bayar_rp) {
        ToastNotification("info", "Pembayaran Sudah Cukup");
        return false;
      } else {
        if (data.reduce((a, b) => a + b.nominal, 0) >= row.harus_bayar_rp) {
          ToastNotification("info", "Pembayaran Sudah Cukup");
          return false;
        }
      }
    }
    setItem('estimasi_pesanan', formatDate(moment(new Date(row.estimasi_pesanan)).format("YYYY-MM-DD")))

    let index = data.findIndex((hasil) => hasil.jenis === row.jenis);
    if (index === -1) {
      let keterangan = "";
      if (row.jenis === "CASH") {
        keterangan = "CASH";
      } else if (row.jenis === "TRANSFER") {
        keterangan = row.no_card + " - " + row.nama_bank;
      } else {
        keterangan = row.nama_bank;
      }
      let hasil = {
        jenis: row.jenis,
        nominal: parseInt(row.cash || 0),
        nama_bank: row.nama_bank !== undefined ? row.nama_bank.toUpperCase() : "-",
        fee: row.jenis === "CREDIT" ? row.fee || 0 : 0,
        no_card: row.no_card || "-",
        keterangan: keterangan.toUpperCase(),
      };
      data.push(hasil);
      this.setState({
        masuk: true,
      });
      setItem("trx_pemabayaran_pesanan", data);
    } else {
      let data_pembayaran = getItem("trx_pemabayaran_pesanan");
      data_pembayaran = data_pembayaran.map((element2) => {
        if (element2.jenis === row.jenis) {
          element2.nominal += parseInt(row.cash);
        }
        return element2;
      });
      setItem("trx_pemabayaran_pesanan", data_pembayaran);
      this.setState({
        status: true,
      });
      // ToastNotification(
      //   "info",
      //   "Pembayaran dengan " + row.jenis + " sudah ada"
      // );
    }
  }

  render() {
    return (
      <>
        <HeadInputPembayaran onSubmit={(data) => this.simpanPembayaran(data)} />
        <hr />
        <form
          onSubmit={this.props.handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete="off"
        >
          <Tabel
            // handleClick={() => this.simpanpembbayaran()}
            keyField="jenis"
            // tambahData={true}
            // btnText="Tambah Pembyaran"
            exportCsv={false}
            data={getItem("trx_pemabayaran_pesanan") || []}
            columns={this.state.columns}
            empty={true}
            pagination="off"
            textEmpty="Data Pembayaran Kosong"
          />
          <div className="row">
            <div className="col-6">

            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-primary"
                disabled={this.props.isLoadingPembayranTitipan}
                onClick={() => this.props.bayarSekarang()}
                type="button"
              >
                {this.props.isLoadingPembayranTitipan ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Diproses
                  </>
                ) : (
                  "Bayar Sekarang"
                )}
              </button>
            </div>
          </div>
          {this.props.isLoadingPembayranTitipan ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)" />
          ) : null}
        </form>
      </>
    );
  }
}

ModalPembayaran = reduxForm({
  form: "ModalPembayaran",
  enableReinitialize: true,
  validate: ValidasiPembayaran,
})(ModalPembayaran);

export default connect(maptostate)(ModalPembayaran);
