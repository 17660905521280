import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  formatGram,
  getItem,
  getToday,
  getUserdata,
  // localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanHasilSoPdf = (
  data = "",
  // data_detail = "",
  typeTrX = "",

) => {
  // initialize jsPDF
  console.log(typeTrX)
  const doc = new jsPDF("l", "mm", [297, 210]);
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 30;
  // let total_qty = 0;
  doc.setFontSize(15);
  doc.text("LAPORAN HASIL STOCK OPNAME", 14, 15);
  doc.setFontSize(10);
  doc.text(`${typeTrX}`, 14, 20);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN HASIL STOCK OPNAME",
  });

  doc.text(
    `PERIODE : ${getItem("tgl_laporan").length === 0
      ? null
      : getItem("tgl_laporan").tgl_awal
    }`,
    14,
    25
  );
  let total_barang = 0;
  tableColumn = [
    [

      {
        content: `BARCODE`,
      },
      {
        content: `NAMA BARANG`,
      },
      {
        content: `DATA ATRIBUT`,
      },
      {
        content: `BERAT`,
      },
    ],
  ];
  let total_berat_grand = 0;
  data.forEach((row, index) => {
    let tot_berat = 0;
    let detail = [
      {
        content: row.kode_baki,
        colSpan: 13,
      },
    ]
    tableRows.push(detail);
    row.detail_barang.forEach((row, index) => {
      let rows = [
        row.kode_barcode,
        row.nama_barang,
        row.nama_atribut,

        {
          content: formatGram(row.berat, 3),
          styles: {
            halign: 'right',
          },
        },

      ];
      tot_berat = tot_berat + parseFloat(row.berat);
      total_berat_grand = total_berat_grand + parseFloat(row.berat);
      tableRows.push(rows);
    });
    total_barang = total_barang + row.detail_barang.length;

    let total = [
      {
        content: `Subtotal : ${row.detail_barang.length} Barang`,
        colSpan: 3,
        styles: {
          halign: 'center',
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },

      {
        content: `${tot_berat.toFixed(3)}`,
        styles: {
          halign: 'right',
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },


    ];
    tableRows.push(total);
  });
  let grand = [
    {
      content: `Grand Total : ${total_barang} Barang`,
      colSpan: 3,
      styles: {
        halign: 'center',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${total_berat_grand.toFixed(3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: 'right',

      },
    },






  ];
  tableRows.push(grand);

  console.log()
  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${getItem("userdata").user_id
        } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  footRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN HASIL STOCK OPNAME.pdf`);
};

export default LaporanHasilSoPdf;
