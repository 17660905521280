import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  formatDate,
  moment,
  localStorageencryp,
  ToastNotification,
  postDataNoTImeOut,
} from "../../../../components/helpers/library";
import HeadFilterStockPembelian from "./HeadFilterStockPembelian";

class LaporanStockPembelian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      LaporanTransaksiPembelian: [],
      export: false,
    };
  }
  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      group_by: data.grouping,
      kode_dept: data.kode_dept,
      kode_group: data.kode_group,
      kondisi: data.kondisi,
    };
    postDataNoTImeOut("pembelian/report-stock-beli", data_post)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
          this.setState({
            export: false,
            isLoading: false,
            LaporanTransaksiPembelian: [],
          });
        } else {
          ToastNotification("success", "Laporan Transaksi Pembelian Tersedia");
          localStorageencryp("tgl_laporan", JSON.stringify(data_post));
          this.setState({
            LaporanTransaksiPembelian: [],
          });
          this.setState({
            export: true,
            isLoading: false,
            LaporanTransaksiPembelian: res.data,
          });
        }
      })

      .catch((error) => {
        this.setState({
          export: false,
          isLoading: false,
        });
        ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
        // this.setState({ isLoading: false });
      });
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Pembelian</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Stock Pembelian</li>
        </ol>
        <h1 className="page-header">Laporan Stock Pembelian </h1>
        <Panel>
          <PanelHeader>Laporan Stock Pembelian</PanelHeader>
          <PanelBody>
            <HeadFilterStockPembelian
              export={this.state.export}
              isLoading={this.state.isLoading}
              dataSales={this.props.dataSales}
              LaporanTransaksiPembelian={this.state.LaporanTransaksiPembelian}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanStockPembelian = reduxForm({
  form: "LaporanStockPembelian",
  enableReinitialize: true,
})(LaporanStockPembelian);
export default connect(null)(LaporanStockPembelian);
