import {
    GET_DATA_USER, SET_NAMA_USER, SHOW_TAMBAH_DATA_USER,
  } from "../actions/mageuser_action";
  
  const initialState = {
    getDataUser: [],
    ShowModalTambahUser: false,
    SetNamaUser: [],
  };
  
  const manageusers = (state = initialState, actions) => {
      switch (actions.type) {
          case GET_DATA_USER:
              return {
                  ...state,
                  getDataUser: actions.payload.data,
              };
          case SHOW_TAMBAH_DATA_USER:
              return {
                ...state,
                ShowModalTambahUser: actions.payload.data,
              }
          case SET_NAMA_USER:
              return {
                ...state,
                SetNamaUser: actions.payload.data,
              }
              default:
                return state;
            }
}
        
export default manageusers;