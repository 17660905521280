import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate } from "../../../components/helpers/field";
import { getTglSystem } from "../../../components/helpers/function";
import Tabel from "../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../Validasi/ValidasiTanggalLaporan";
import Loading from "react-fullscreen-loading";
import LaporanBeliHadiahPdf from "./pdf/LaporanBeliHadiahPdf";
// import LaporanBeliHadiahPdf from "./pdf/LaporanBeliHadiah";


class HeadLaporanBeliHadiah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "no_pembelian",
          text: "No Beli",
          footer: "Total: ",
          footerAttrs: {
            colSpan: "4",
          },
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },
        {
          dataField: "tanggal",
          text: "Tanggal",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },
        {
          dataField: "supplier",
          text: "Supplier",
          headerStyle: (colum, colIndex) => {
            return { width: "150px" };
          },
        },
        {
          dataField: "nama_hadiah",
          text: "Nama Hadiah",
          headerStyle: (colum, colIndex) => {
            return { width: "140px" };
          },
        },

        {
          dataField: "qty",
          text: "Qty",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: "30px" };
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((a, b) => a + parseInt(b), 0)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right">{parseInt(data)}</div>;
          },
        },
        {
          dataField: "total_uang",
          text: "Uang",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        
          footer: (columnData) => (
            <div className="text-right">
             {" "}
                {parseInt(
                  columnData.reduce((acc, item) => acc + item, 0)
                ).toLocaleString("kr-KO")}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right">{data.toLocaleString("kr-ko")}</div>;;
          },
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    console.log(this.props.LaporanBeliHadiah)
    LaporanBeliHadiahPdf(this.props.LaporanBeliHadiah);
   
  }
  setStartDate(date) {
    this.setState({
      start_date: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      end_date: new Date(date),
    });
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="start_date"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.start_date}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="end_date"
              component={InputDate}
              type="text"
              selected={this.state.end_date}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_pesanan"
              data={this.props.export ? this.props.LaporanBeliHadiah : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Beli Hadiah Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              {/* <div className="col-lg-6">
                <LaporanPesananExel data={this.props.LaporanPesanan} />
              </div> */}
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanBeliHadiah = reduxForm({
  form: "HeadLaporanBeliHadiah",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadLaporanBeliHadiah);
// export default connect()(HeadLaporanPesanan);
export default connect((state) => {
  return {
    initialValues: {
      start_date: getTglSystem(),
      end_date: getTglSystem(),
    },
  };
})(HeadLaporanBeliHadiah);
