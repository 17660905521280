import {
  GET_BANNER_KATALOG,
  SET_DATA_BANNER_KATALOG,
} from "./bannerKatalogAction";

const initialState = {
  bannerKatalog: [],
  ShowModal: null,
};

const stateBannerKatalog = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_BANNER_KATALOG:
      return {
        ...state,
        bannerKatalog: actions.payload.data,
      };
    case SET_DATA_BANNER_KATALOG:
      return {
        ...state,
        ShowModal: actions.payload,
      };
    default:
      return state;
  }
};
export default stateBannerKatalog;
