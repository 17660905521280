import {
  React,
  connect,
  reduxForm,
  FormPanel,
  moment,
  ToastNotification,
  convertBulan,
  getDataParamsToko,
} from "../../../components/helpers/library";
import HeadRekapPerbulan from "./HeadRekapPerbulan";

class LaporanRekapPerbulan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      tipe: "PENJUALAN",
      datarekap: [],
      columnsPenjualan: [
        {
          dataField: "tanggal",
          text: "Bulan",
          formatter: (data) => {
            return (
              <div> {convertBulan(moment(new Date(data)).format("M"))}</div>
            );
          },
          footer: "",
        },
        {
          dataField: "qty",
          text: "Qty",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          ),
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
            </div>
          ),
        },
        {
          dataField: "harga",
          text: "Harga",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },

        {
          dataField: "ongkos",
          text: "Ongkos",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
        {
          dataField: "total",
          text: "Total",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
      ],
      columnsPembelian: [
        {
          dataField: "tanggal",
          text: "Bulan",
          formatter: (data) => {
            return (
              <div> {convertBulan(moment(new Date(data)).format("M"))}</div>
            );
          },
          footer: "",
        },
        {
          dataField: "qty",
          text: "Qty",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          ),
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
            </div>
          ),
        },
        {
          dataField: "harga_nota",
          text: "Harga Nota",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
        {
          dataField: "harga_beli",
          text: "Harga Beli",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
      ],
    };
  }
  changeTipe(data) {
    this.setState({
      tipe: data,
      datarekap: [],
      export: false,
    });
  }
  handleSubmit(data) {
    const datatoko = data.kode_toko.split("|");
    const api = datatoko[1];
    const kodeToko = datatoko[0];
    let query = {
      // date_filter: moment(new Date(data.tanggal)).format("yyyy-MM")
      tgl_from: moment(new Date(data.tanggal)).format("yyyy-MM"),
      tgl_to: moment(new Date(data.tanggal_akhir)).format("yyyy-MM"),
      kategori: data.kategori,
    };

    let url = "";
    if (data.tipe === "PENJUALAN") {
      if (kodeToko === "ALL") {
        url = "penjualan/report-bulanan-all";
      } else {
        url = "penjualan/report-bulanan";
      }
    } else {
      if (kodeToko === "ALL") {
        url = "pembelian/report-bulanan-all";
      } else {
        url = "pembelian/report-bulanan";
      }
    }
    getDataParamsToko(url, query, api, kodeToko, data.tipe)
      .then((res) => {
        console.log(res.data);
        if (res.data.length === 0) {
          ToastNotification("info", "Laporan Kosong !");
          this.setState({
            export: false,
            datarekap: [],
            isLoading: false,
          });
        } else {
          ToastNotification("success", "Laporan Tersedia");
          this.setState({
            export: true,
            datarekap: res.data,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          export: false,
          datarekap: [],
          isLoading: false,
        });
        ToastNotification(
          "info",
          err?.response?.data?.message || "Laporan Tidak Ada"
        );
      });
  }
  render() {
    return (
      <FormPanel menu="Laporan" submenu="Laporan Rekap Perbulan">
        <HeadRekapPerbulan
          columnsPenjualan={this.state.columnsPenjualan}
          columnsPembelian={this.state.columnsPembelian}
          tipe={this.state.tipe}
          datarekap={this.state.datarekap}
          export={this.state.export}
          changeTipe={(data) => this.changeTipe(data)}
          onSubmit={(data) => this.handleSubmit(data)}
        />
      </FormPanel>
    );
  }
}

LaporanRekapPerbulan = reduxForm({
  form: "LaporanRekapPerbulan",
  enableReinitialize: true,
})(LaporanRekapPerbulan);
export default connect(null)(LaporanRekapPerbulan);
