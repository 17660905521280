import React, { Component, useState, useEffect, lazy } from "react";

import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody, FormPanel } from "../panel/panel.jsx";
import {
  hideModal,
  isLoading,
  showModal,
  isEdit
} from "../../actions/datamaster_action";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { NotifSucces, ToastNotification } from "./notification.jsx";
import { reset } from "redux-form";

import {
  deleteData,
  postData,
  getDataNoTimeOut2,
  getDataParams,
  getDataParamsToko,
  putData,
  getData2,
  postData2,
  getDataParams2,
  getData,
  getDataNoTimeOut,
  postDataNoTImeOut,
  postDataNoTImeOut2
} from "../../config/axios.jsx";
import Tabel from "./tabel.jsx";
import ModalGlobal from "./ModalGlobal.jsx";
import {
  ReanderField,
  HiidenFiled,
  ReanderSelect,
  SkeletonLoading,
  InputDate,
  ReanderCheckBox
} from "./field";
import { v4 as uuidv4 } from "uuid";
import {
  ErrorHandling,
  convertBase64,
  convertBulan,
  getItem,
  convertDate,
  setItem,
  localStoragedecryp,
  localStorageencryp,
  formatDate,
  getTglSystem,
  getToday,
  BgLoading,
  formatGram,
  JSONToCSVConvertor,
  removeItem
} from "./function.jsx";
import { Gramasi, NumberOnly } from "../../Validasi/normalize";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import imgfound from "../../assets/img/temp.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import Camera from "react-html5-camera-photo";
import * as moment from "moment-timezone";
import { createNumberMask } from "redux-form-input-masks";
import DualListBox from "react-dual-listbox";
import Loading from "react-fullscreen-loading";
import { doDecrypt, doEncrypt } from "./encrypt.jsx";
import BackButtonPos from "./BackButton.jsx";
const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "kr-KO"
});

export {
  currencyMask,
  NotifSucces,
  doDecrypt,
  doEncrypt,
  BgLoading,
  BackButtonPos,
  getDataParams,
  getDataParamsToko,
  getDataParams2,
  convertBulan,
  JSONToCSVConvertor,
  convertDate,
  getDataNoTimeOut2,
  React,
  getToday,
  lazy,
  Gramasi,
  formatGram,
  FormPanel,
  Loading,
  PanelBody,
  postDataNoTImeOut2,
  getDataNoTimeOut,
  postDataNoTImeOut,
  getData2,
  postData2,
  DualListBox,
  formValueSelector,
  ReanderCheckBox,
  NumberOnly,
  localStorageencryp,
  InputDate,
  getTglSystem,
  localStoragedecryp,
  imgfound,
  formatDate,
  moment,
  createNumberMask,
  Camera,
  convertBase64,
  ToolkitProvider,
  SkeletonLoading,
  BootstrapTable,
  useState,
  useEffect,
  ErrorHandling,
  uuidv4,
  setItem,
  getItem,
  isLoading,
  showModal,
  isEdit,
  ToastNotification,
  Swal,
  reset,
  Link,
  PanelHeader,
  Component,
  Panel,
  ModalGlobal,
  Tabel,
  connect,
  Field,
  hideModal,
  reduxForm,
  getData,
  HiidenFiled,
  ReanderField,
  ReanderSelect,
  deleteData,
  postData,
  putData,
  removeItem
};
