import { SET_TRANSAKSI_SERVICES, SHOW_MODAL_SERVICE_AMBIL, SHOW_MODAL_SERVICE_SELESAI } from "../actions/services_action";

const initialState = {
    setTransaksiServices : [],
    showModalServiceSelesai : [],
    showModalServiceAmbil : [],
}

const services = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_TRANSAKSI_SERVICES:
            return {
              ...state,
              setTransaksiServices: actions.payload.data,
            }
        case SHOW_MODAL_SERVICE_SELESAI:
            return {
              ...state,
              showModalServiceSelesai: actions.payload.data,
            }
        case SHOW_MODAL_SERVICE_AMBIL:
            return {
              ...state,
              showModalServiceAmbil: actions.payload.data,
            }
            default:
              return state;
          }
}

export default services;