import { GET_DATA_MEMBER, SHOW_MODAL_EDIT_MEMBER, SHOW_MODAL_TUKAR_POIN } from "../actions/member_action";
import {CHECK_TRANSAKSI_MEMBER, GET_ALL_TRANSAKSI_MEMBER} from "../pages/Member/TransaksiMember/transaksi_member";


const initialState = {
    getDataMember: false,
    showEditMember: false,
    ShowModalTukarPoin:false,
    listTransaksiMember : [],
    checkTransaksiMember: []
}

const datamember = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_DATA_MEMBER:
            return {
                ...state,
                getDataMember: actions.payload.data,
            };
        case SHOW_MODAL_TUKAR_POIN:
            return {
                ...state,
                ShowModalTukarPoin: actions.payload.data,
            };
        case SHOW_MODAL_EDIT_MEMBER:
            return {
                ...state,
                showEditMember: actions.payload.data,
            };
        case GET_ALL_TRANSAKSI_MEMBER:
            return {
                ...state,
                listTransaksiMember: actions.payload
            }
        case CHECK_TRANSAKSI_MEMBER:
            return {
                ...state,
                checkTransaksiMember : actions.payload
            }
        default:
            return state;
        }
}
export default datamember;