import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  Tabel,
  PanelHeader,
  PanelBody,
  Field,
  ReanderSelect,
  Loading,
  ToastNotification
} from "../../../components/helpers/library";
import { getDataSales } from "../../../actions/datamaster_action";
import CetakLaporanRekapPdf from "./CetakLaporanRekapPdf";
import CetakLaporanRekapExel from "./CetakLaporanRekapExel";
import { getDataParams } from "./../../../config/axios";

const maptostate = (state) => {
  return {
    datasales: state.datamaster.getDataSales,
  };
};
class LaporanTransaksiRekapSalesDaily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      LaporanSalesDaily: [],
      tipe_transaksi: "PENJUALAN",
      columns: [
        {
          dataField: "qty",
          text: "Qty",
        },
        {
          dataField: "berat",
          text: "Berat",
          formatter: (cell) => {
            return <div> {cell === "-" ? '-' : cell.toFixed(3)} </div>;
          },
        },
      ],
    };
  }
  handleCari() {
    if(this.state.kode_sales === undefined){
      ToastNotification("info", "Kode Sales Belum Dipilih")
      return false
    }
    this.setState({
      isLoading: true,
    });
    let params = {
      tipe_transaksi: this.state.tipe_transaksi,
      kode_sales: this.state.kode_sales,
    };
    getDataParams("sales/report-daily", params)
      .then((res) => {
        if(res.data.length === 0){
          ToastNotification('info',"Laporan Tidak Tersedia");
          this.setState({
            export: false,
            isLoading: false,
            LaporanSalesDaily : []
          });
          return false;
        }
        ToastNotification('success',"Laporan Tersedia");
        this.setState({
          export: true,
          isLoading: false,
          LaporanSalesDaily : res.data
        });
      })
      .catch((err) => {
        ToastNotification('info',err?.response?.data?.message || "Terjadi Kesalahan Saat Request Data")
        this.setState({
          export: false,
          isLoading: false,
        });
      });
  }
  componentDidMount() {
    this.props.dispatch(getDataSales());
    this.props.change("tipe_transaksi", "PENJUALAN");
  }
  exportPdf() {
    CetakLaporanRekapPdf(this.state.LaporanSalesDaily);
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
        </ol>
        <h1 className="page-header">Laporan Transaksi Rekap Sales Daily </h1>
        <Panel>
          <PanelHeader>Laporan Transaksi Rekap Sales Daily</PanelHeader>
          <PanelBody>
            <form
              onSubmit={this.props.handleSubmit}
              autoComplete="off"
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            >
              <div className="row">
                <div className="col-lg-4">
                  <Field
                    name="kode_sales"
                    component={ReanderSelect}
                    onChange={(e) => {
                      this.setState({ kode_sales: e });
                    }}
                    options={this.props.datasales.map((list) => {
                      let row = {
                        value: list.kode_sales,
                        name: list.kode_sales + " - " + list.nama_sales,
                      };
                      return row;
                    })}
                    label="Kode Sales"
                    placeholder="Pilih Kode Sales"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="tipe_transaksi"
                    component={ReanderSelect}
                    onChange={(e) => {
                      this.setState({ tipe_transaksi: e });
                    }}
                    options={[
                      {
                        name: "PENJUALAN",
                        value: "PENJUALAN",
                      },
                      {
                        name: "PEMBELIAN",
                        value: "PEMBELIAN",
                      },
                      {
                        name: "HUTANG",
                        value: "HUTANG",
                      },
                      {
                        name: "HUTANG LUNAS",
                        value: "HUTANG_LUNAS",
                      },
                    ]}
                    label="Tipe Transaksi"
                    placeholder="Pilih Tipe Transaksi"
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={() => this.handleCari()}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                        Mencari
                      </>
                    ) : (
                      "Cari"
                    )}
                  </button>
                </div>
                <div className="col-lg-12">
                  <Tabel
                    keyField="kode_sales"
                    data={this.state.LaporanSalesDaily}
                    columns={this.state.columns}
                    empty={true}
                    textEmpty="Data Laporan Rekap Sales Daily"
                  />
                </div>
                <div
                  className={
                    this.state.export ? "col-lg-12" : "col-lg-12 d-none"
                  }
                >
                  {/* <label htmlFor="">&nbsp;</label> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <button
                        type="button"
                        onClick={() => this.exportPdf()}
                        className="btn btn-warning btn-block"
                      >
                        {" "}
                        Export PDF{" "}
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <CetakLaporanRekapExel data={this.state.LaporanSalesDaily}/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {this.state.isLoading ? (
              <Loading
                loading
                background="rgba(0, 0, 0, 0.35)"
                loaderColor="rgba(94, 147, 117, 1)"
              />
            ) : null}
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanTransaksiRekapSalesDaily = reduxForm({
  form: "LaporanTransaksiRekapSalesDaily",
  enableReinitialize: true,
})(LaporanTransaksiRekapSalesDaily);
export default connect(maptostate)(LaporanTransaksiRekapSalesDaily);
