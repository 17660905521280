import { getItem } from "../components/helpers/function";
import { getData2, getDataNoTimeOut2 } from "../config/axios";

export const GET_DATA_TIMBANGAN = "GET_DATA_TIMBANGAN";
export const GET_PARA_SYSTEM = "GET_PARA_SYSTEM";
export const GET_PARA_SYSTEM_ID = "GET_PARA_SYSTEM_ID";

export const getTimbangan = (data) => {
    let timbangan = getItem('timbangan');
    // console.log(timbangan.split("g")[timbangan.split("g").length-2])
    return (dispatch) => {
        dispatch({
          type: GET_DATA_TIMBANGAN,
          payload: {
            data: timbangan.length === 0 ? 0 : timbangan.split("g")[timbangan.split("g").length-2],
          },
        });
      };
   
};

export const getParaSystemId = (key) => {
  return (dispatch) => {
    getDataNoTimeOut2('para-system/key/'+key).then((res) => {
        // console.log(res);
          dispatch({
              type: GET_PARA_SYSTEM_ID,
              payload: {
                  data: res.data
              }
          })   
      }).catch((err) => {
           console.log(err);
       })
  }
}

export const getParaSystem = () => {
  return (dispatch) => {
    getData2('para-system').then((res) => {
        // console.log(res);
          dispatch({
              type: GET_PARA_SYSTEM,
              payload: {
                  data: res.data
              }
          })   
      }).catch((err) => {
           console.log(err);
       })
  }
}