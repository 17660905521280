import { change } from "redux-form";
import {
  React,
  reduxForm,
  Field,
  ReanderField,
  Component,
  connect,
  NumberOnly,
  Tabel,
  ToastNotification,
  Loading,
  getItem,
  hideModal,
  getDataNoTimeOut,
  postDataNoTImeOut,
} from "../../../../components/helpers/library";

import ValidasiPenjualan from "../../../../Validasi/Penjualan/ValidasiPenjualan";

class FindCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMember: true,
      datamember: [],
      isLoading: false,
      columns: [
        {
          dataField: "kode_member",
          text: "Kode Member",
          headerStyle: (colum, colIndex) => {
            return { width: "100px" };
          },
        },
        {
          dataField: "nama_customer",
          text: "NAMA CUSTOMER",
          headerStyle: (colum, colIndex) => {
            return { width: "250px" };
          },
        },
        {
          dataField: "no_hp",
          text: "No Hp",
          headerStyle: (colum, colIndex) => {
            return { width: "120px" };
          },
        },
        {
          dataField: "alamat_customer",
          text: "Alamat",
          formatter: (value, row) => {
            return (
              value + " " + row.kecamatan + " " + row.kelurahan + " " + row.kota
            );
          },
        },
      ],
    };
  }
  getNamaMember(e) {
    if (e === "") {
      if (this.delay) {
        clearTimeout(this.delay);
      }
      this.setState({
        datamember: [],
        loading: false,
      });
      return false;
    }
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      let pencarian = {
        nama_customer: e.toUpperCase(),
      };
      this.setState({
        loading: true,
      });
      postDataNoTImeOut("member/get/by-nama/", pencarian, [], true)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Nama Tidak Di Temukan");
            this.setState({
              datamember: [],
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              datamember: res.data,
            });
          }
        })
        .catch((err) => {
          ToastNotification("info", "Nama Tidak Tidak Ditemukan");
          this.setState({
            datamember: [],
            loading: false,
          });
        });
    }, 1500);
  }
  getAlamat(e) {
    if (e === "") {
      if (this.delay) {
        clearTimeout(this.delay);
      }
      this.setState({
        datamember: [],
        loading: false,
      });
      return false;
    }
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      this.setState({
        loading: true,
      });
      let pencarian = {
        alamat: e.toUpperCase(),
      };
      postDataNoTImeOut("member/get/by-alamat", pencarian, ["alamat"], true)
        .then((res) => {
          console.log(res.data);
          if (res.data.length === 0) {
            ToastNotification("info", "Alamat Tidak Di Temukan");
            this.setState({
              datamember: [],
              loading: false,
            });
          } else {
            this.setState({
              datamember: res.data,
              loading: false,
            });
          }
        })
        .catch((err) => {
          ToastNotification("info", "Alamat Tidak Di Temukan");
          this.setState({
            datamember: [],
            loading: false,
          });
        });
    }, 1500);
  }
  componentDidMount() {
    setTimeout(() => {
      document.getElementById("nama_customer").focus();
    }, 100);
  }
  getNohp(e) {
    if (e === "") {
      if (this.delay) {
        clearTimeout(this.delay);
      }
      this.setState({
        datamember: [],
        loading: false,
      });
      return false;
    }
    if (this.delay) {
      clearTimeout(this.delay);
    }
    this.delay = setTimeout(() => {
      this.setState({
        loading: true,
      });
      let pencarian = {
        hp: e.toUpperCase(),
      };
      postDataNoTImeOut("member/get/by-hp", pencarian, ["hp"], true)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "No Hp Tidak Di Temukan");
            this.setState({
              datamember: [],
              loading: false,
            });
          } else {
            this.setState({
              datamember: res.data,
              loading: false,
            });
          }
        })
        .catch((err) => {
          ToastNotification("info", "No Hp Tidak Di Temukan");
          this.setState({
            datamember: [],
            loading: false,
          });
        });
    }, 1500);
  }
  render() {
    const CheckCustomer = {
      mode: "radio",
      clickToSelect: true,
      selectColumnPosition: "left",
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect === true) {
          let hasil = {
            kode_sales: getItem("kode_sales_penjulan"),
            alamat_customer: row.alamat_customer || "-",
            jenis_pelanggan: "MEMBER",
            kode_customer: row.kode_member,
            nama_customer: row.nama_customer || "REGULER",
            no_hp: row.no_hp || "-",
          };
          this.setState({
            isLoading: true,
          });
          getDataNoTimeOut(
            "member/get/by-kode-member/" + row.kode_member + "?single=1"
          )
            .then((res) => {
              if (res.data[0].trx_count === 1) {
                ToastNotification("info", "Member Baru Transaksi 1 Kali");
              }
              if (res.data[0].trx_count === 0) {
                ToastNotification("info", "Member Ini Belum Pernah Transaksi");
              }
              this.setState({
                isLoading: false,
              });
              this.props.dispatch(
                change(
                  "HeadFormLaporanTukarPoint",
                  "kode_member",
                  row.kode_member
                )
              );
              setTimeout(() => {
                localStorage.setItem(
                  "data_customer_tmp",
                  JSON.stringify(hasil)
                );

                this.props.dispatch(hideModal());
              }, 1000);
            })
            .catch((err) => {
              localStorage.setItem("data_customer_tmp", JSON.stringify(hasil));
              this.props.dispatch(hideModal());
              this.setState({
                isLoading: false,
              });
            });
        } else {
          // console.log();
          if (localStorage.getItem("data_customer_tmp") === null) {
          } else {
            localStorage.setItem("data_customer_tmp", JSON.stringify([]));
          }
          this.setState({
            isLoading: false,
          });
        }
      },
    };
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-4">
            <Field
              id="nama_customer"
              name="nama_customer"
              component={ReanderField}
              type="text"
              label="Nama Customer"
              placeholder="Masukan Nama Customer"
              normalize={(value) => value && value.toUpperCase()}
              onChange={(e) => this.getNamaMember(e.target.value)}
            />
          </div>
          <div className="col-4">
            <Field
              name="no_hp"
              component={ReanderField}
              type="text"
              normalize={NumberOnly}
              label="No Hp"
              onChange={(e) => this.getNohp(e.target.value)}
              placeholder="Masukan No Hp"
            />
          </div>
          <div className="col-4">
            <Field
              onChange={(e) => this.getAlamat(e.target.value)}
              name="alamat_customer"
              component={ReanderField}
              type="textarea"
              label="Alamat Customer"
              placeholder="Masukan Alamat Customer"
            />
          </div>
          <div className="col-12">
            <Tabel
              keyField="kode_member"
              selectRow={CheckCustomer}
              data={this.state.datamember || []}
              columns={this.state.columns}
              empty={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-8"></div>
          <div className="col-4">
            <button className="btn btn-primary btn-block" type="submit">
              Selesai
            </button>
          </div>
        </div>
        {this.state.loading || this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FindCustomer = reduxForm({
  form: "FindCustomer",
  enableReinitialize: true,
  validate: ValidasiPenjualan,
})(FindCustomer);
export default connect(null)(FindCustomer);
