import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  getParameterKupon,
  hideModal,
} from "../../../actions/datamaster_action";
import Loading from "react-fullscreen-loading";
import {InputDate, ReanderField} from "../../../components/helpers/field";
import { Gramasi, NumberOnly } from "../../../Validasi/normalize";
import ValidasiParameterKupon from "./Validasi";

const maptostate = (state) => {
  if (state.datamaster.ShowModalParameterKupon !== null) {
    console.log(state.datamaster.ShowModalParameterKupon);
    return {
      datagroup: state.datamaster.getParameterKupon,
      initialValues: {
        idKpon: state.datamaster.ShowModalParameterKupon._id,
        beratKupon: state.datamaster.ShowModalParameterKupon.berat,
        jmlKupon: state.datamaster.ShowModalParameterKupon.jumlah_kupon,
        tglMulai: state.datamaster.ShowModalParameterKupon.tgl_mulai,
        tglSelesai: state.datamaster.ShowModalParameterKupon.tgl_selesai,
        ketHadiah: state.datamaster.ShowModalParameterKupon.ket_hadiah,
        ketKupon: state.datamaster.ShowModalParameterKupon.ket_kupon,
      },
    };
  }
};

class MasterParameterKupon extends Component {
  state = {
    aktif: false,
  };
  componentDidMount() {
    this.props.dispatch(getParameterKupon());
  
  }

  handleSubmit(data) {}
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Field
          name="jmlKupon"
          component={ReanderField}
          type="text"
          normalize ={Gramasi}
          label="Jumlah Kupon"
          placeholder="Masukan Jumlah Kupon"
        />
        <Field
          name="beratKupon"
          component={ReanderField}
          type="text"
          normalize={NumberOnly}     
          label="Berat Parameter Kupon"
          placeholder="Masukan Berat Parameter Kupon"
        />
        <Field
          name="tglMulai"
          component={InputDate}
          label="Tanggal Mulai "
          type="text"
          selected={this.state.tgl_awal}
          onChange={(date) => this.setStartDate(date)}
          placeholder="Masukan Tanggal Mulai"
        />
        <Field
          name="tglSelesai"
          component={InputDate}
          label="Tanggal Berakhir "
          type="text"
          selected={this.state.tgl_awal}
          onChange={(date) => this.setStartDate(date)}
          placeholder="Masukan Tanggal Berakhir"
        />

        <Field
          name="ketHadiah"
          component={ReanderField}
          type="text"
          //   readOnly
          label="Keterangan Hadiah"
          placeholder="Masukan Keterangan Hadiah"
        />


        <Field
          name="ketKupon"
          component={ReanderField}
          type="text"
          //   readOnly
          label="Keterangan Kupon"
          placeholder="Masukan Keterangan Kupon"
        />
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="2"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.isLoading}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

MasterParameterKupon = reduxForm({
  form: "MasterParameterKupon",
  enableReinitialize: true,
  validate : ValidasiParameterKupon
})(MasterParameterKupon);
export default connect(maptostate, null)(MasterParameterKupon);
