import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanHutangThTempo = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];
  let finalY = 30;
  // let total_berat = 0;
  // let total_berat_bruto = 0;
  // let total_jumlah_hutang = 0;

  let grand_total_qty = 0;
  let grand_total_hutang = 0;
  let grand_total_berat = 0;
  let grand_total_berat_plastik = 0;
  let total_hutang = 0;

  doc.setFontSize(15);
  doc.text("LAPORAN  HUTANG JATUH TEMPO", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN  HUTANG JATUH TEMPO",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
          " s/d " +
          JSON.parse(localStoragedecryp("tgl_laporan")).tgl_akhir
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `No Hutang`,
      },
      {
        content: `MODEL BARANG`,
      },

      {
        content: `NAMA MEMBER`,
      },

      {
        content: `NO HP`,
      },
      {
        content: `QTY`,
        styles: {
          halign: "right",
        },
      },

      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT + PLASTIK`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `HUTANG POKOK`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  data.forEach((item, index) => {
    grand_total_qty = grand_total_qty + item.detail_barang.jumlah;
    grand_total_hutang = grand_total_hutang + item.jumlah_hutang;
    grand_total_berat = grand_total_berat + item.berat_emas;
    grand_total_berat_plastik = grand_total_berat_plastik + item.berat_bruto;
    total_hutang = total_hutang + item.total_hutang;
    let rows = [
      item.no_faktur_hutang || "-",
      item.detail_barang.nama_barang,
      item.nama_customer,
      item.no_hp,
      {
        content: parseInt(item.detail_barang.jumlah || 0),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_emas.toFixed(2),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_bruto.toFixed(2),
        styles: {
          halign: "right",
        },
      },

      {
        content: parseInt(item.jumlah_hutang).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
    ];

    tableRows.push(rows);
  });

  let grand_total = [
    {
      content: `Grand total`,
      colSpan: 4,
      styles: {
        halign: "center",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${grand_total_qty}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${grand_total_berat.toFixed(2)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${grand_total_berat_plastik.toFixed(2)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${grand_total_hutang.toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
  ];
  tableRows.push(grand_total);
  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${
        JSON.parse(localStoragedecryp("userdata")).user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,/
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     JSON.parse(localStoragedecryp("userdata")).user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  // doc.text(`Total Daftar              : ${total_semua}`, 14, finalY + 22);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN HUTANG JATUH TEMPO.pdf`);
  // var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  // var x = window.open();
  // x.document.open();
  // x.document.write(
  //   "<html><head><title>LAPORAN  HUTANG JATUH TEMPO</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
  //     string +
  //     "></embed></body></html>"
  // );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanHutangThTempo;
