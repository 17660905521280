import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "actions/datamaster_action";
import {  ReanderSelect,  renderTextArea } from "components/helpers/field";

const maptostate = (state) => {
  if (state.stateGroupDescriptionKatalog.ShowModal !== null) {
    return {
      isEdit: true,
      isLoading: state.utility.isLoading,
      DataGroup: state.datamaster.GetDataGroup,
      initialValues: {
        description: state.stateGroupDescriptionKatalog.ShowModal.description,
        kode_group: state.stateGroupDescriptionKatalog.ShowModal.kode_group,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
      DataGroup: state.datamaster.GetDataGroup,
    }
  }
};
class FormGroupDescriptionKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktif: false,
      listgroup: [],
      parameter_bandrol: "2301",
    };
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
       
        autoComplete="off"
      >
         <Field
          tabIndex="1"
          id="kode_group"
          name="kode_group"
          component={ReanderSelect}
          label="Group Yang Dipilih"
          placeholder="Silahkan Pilih Group Harga"
          options={this.props.DataGroup.map((data)=> {
            return {
              value : data.kode_group,
              name: `${data.nama_group} / ${data.kadar}`
            }
          })}
        />
        <Field
          tabIndex="2"
          id="description"
          name="description"
          component={renderTextArea}
          type="text"
          label="Deskripsi"
          placeholder="Masukan Deskripsi"
        />
       
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="5"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormGroupDescriptionKatalog = reduxForm({
  form: "FormGroupDescriptionKatalog",
  enableReinitialize: true,
})(FormGroupDescriptionKatalog);
export default connect(maptostate, null)(FormGroupDescriptionKatalog);
