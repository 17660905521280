import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { Panel } from "../../../components/panel/panel";
import Tabel from "../../../components/helpers/tabel";
import {
  convertDate,
  getTglSystem,
} from "../../../components/helpers/function";
import { hideModal, showModal } from "../../../actions/datamaster_action";
import ModalGlobal from "../../../components/helpers/ModalGlobal";
import ModalPembayaran from "../../Pesanan/ModalPembayaran";
import { InputDate } from "../../../components/helpers/field";
import FilterHadiah from "./FilterHadiah";
import { ToastNotification } from "../../../components/helpers/notification";
import { postData } from "../../../config/axios";

const maptostate = (state) => {
  return {
    setHancurBarang: state.databarang.setHancurBarang,
  };
};

class HeadFormAmbilHadiah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      FormModal: "",
      isLoading: false,
      databarang: [],
      columns: [
        {
          dataField: "namaHadiah",
          text: "Nama Hadiah",
          headerClasses: "text-center",
          footerAttrs: {
            colSpan: "1",
          },
          footer: "Total",
        },

        {
          dataField: "qtyAmbil",
          headerClasses: "text-center",
          text: "Qty",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
          formatter: (cell) => {
            return <div className="text-right">{parseInt(cell)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {" "}
                {columnData.reduce((a, b) => a + parseInt(b), 0)}{" "}
              </div>
            );
          },
        },
        {
          dataField: "inputAlasanAmbil",
          text: "Alasan Ambil",
          headerClasses: "text-center",
          formatter: (cell) => {
            return <div>{cell.toUpperCase()} </div>;
          },
          
        },
      ],
    };
  }

  showModalAmbilHadiah() {
    this.setState({ FormModal: "AmbilHadiah" });
    this.props.dispatch(showModal());
  }

  componentDidMount() {
    this.props.change("tgl_ambil", getTglSystem());
  }

  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }

  resetAmbilHadiah() {
    this.setState({
      resetAmbilHadiah: true,
    });
    setTimeout(() => {
      localStorage.removeItem("data_ambil_hadiah_tmp");
      this.setState({
        databarang: [],
        resetAmbilHadiah: false,
      });
    }, 300);
  }

  handleSimpan() {
    this.setState({
      isLoading: true,
    });
    let cek = JSON.parse(localStorage.getItem("data_ambil_hadiah_tmp")) || [];
    if (cek.length === 0) {
      ToastNotification("Information", "Data Hadiah Masih Kosong");
      return false;
    }
    let dataAmbilHadiah = [];
    cek.forEach((List) => {
      let row = {
        kode_hadiah: List.kode_hadiah,
        qty: List.qtyAmbil,
        alasan: List.inputAlasanAmbil,
      };
      dataAmbilHadiah.push(row);
    });

    const data = {
      tanggal: convertDate(this.state.tgl_awal || getTglSystem()),
      detail_hadiah: dataAmbilHadiah,
    };

    console.log(data);

    postData("/hadiah/ambil", data,[], true)
      .then((res) => {
        console.log(res.data);
        ToastNotification("success", "Data Barang Berhasil Di Simpan");
        this.setState({
          resetAmbilHadiah: true,
        });
        setTimeout(() => {
          localStorage.removeItem("data_ambil_hadiah_tmp");
          this.setState({
            databarang: [],
            resetAmbilHadiah: false,
          });
        }, 300);
      })
      .catch((error) => {
        console.log(
          error.response === undefined
            ? "Terjadi Kesalahan Saat Mengirim Data"
            : error.response.data
        );
      });
  }

  saveLocalDataAmbilHadiah(data) {
    let namaHadiah = data.namaHadiah.split("~");

    let cek = JSON.parse(localStorage.getItem("data_ambil_hadiah_tmp")) || [];

    let hasil_cek = cek.findIndex(
      (hasil) => hasil.kode_hadiah === namaHadiah[0]
    );
    console.log(hasil_cek);
    console.log(cek);

    if (hasil_cek === -1) {
      let row = {
        stockHadiah: Number(data.stockHadiah),
        kode_hadiah: namaHadiah[0],
        namaHadiah: namaHadiah[1],
        inputAlasanAmbil: data.inputAlasanAmbil,
        qtyAmbil: Number(data.qtyAmbil),
      };
      cek.push(row);
      localStorage.setItem("data_ambil_hadiah_tmp", JSON.stringify(cek));
      this.props.dispatch(hideModal());
    } else {
      ToastNotification("info", "Nama Hadiah Tersebut Sudah Ada Ditabel");
      return false;
    }

    this.setState({
      databarang: cek,
    });
  }
  render() {
    return (
      <Panel>
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="tgl_ambil"
              component={InputDate}
              label="Tanggal Ambil"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-12">
            <br />
            <textarea
              style={{ display: "none" }}
              id="nota_titip"
              rows="100"
              cols="100"
            />

            <Tabel
              keyField="kode_barcode"
              // exportCsv={false}
              columns={this.state.columns}
              btnOptional={true}
              btnTextOptional="Data Barang"
              handleClickOptional={() => this.showModalAmbilHadiah()}
              empty={true}
              data={
                this.state.databarang.length === 0
                  ? JSON.parse(localStorage.getItem("data_ambil_hadiah_tmp")) ||
                    []
                  : this.state.databarang
              }
              disable_search
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              onClick={() => this.handleSimpan()}
              className="btn btn-primary"
              disabled={this.props.isLoading}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data Ambi Hadiah"
              )}
            </button>
            &nbsp; &nbsp;
            <button
              onClick={() => this.resetAmbilHadiah()}
              className="btn btn-warning"
              disabled={this.state.isLoadingBatal}
              type="submit"
              // type="button"
            >
              {this.state.isLoadingBatal ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Reset
                </>
              ) : (
                "Reset"
              )}
            </button>
          </div>
        </div>

        <ModalGlobal
          hideBtnX={this.state.FormModal}
          size={
            this.state.FormModal === "AmbilHadiah" ||
            this.state.FormModal === "BeliHadiah"
              ? "xl"
              : "l"
          }
          title={<TitleModal state={this.state.FormModal} />}
          content={
            this.state.FormModal === "AmbilHadiah" ? (
              <FilterHadiah
                isLoading={this.state.isLoading}
                onSubmit={(data) => this.saveLocalDataAmbilHadiah(data)}
              />
            ) : (
              <ModalPembayaran
                isLoadingPembayranTitipan={this.state.isLoadingPembayranTitipan}
                isLoadingPembayran={this.state.isLoadingPembayran}
                bayarTitipan={(data) => this.bayarTitipan(data)}
                simpanPembayaran={(data) => this.simpanPembayaran(data)}
              />
            )
          }
        />

        {this.state.isLoadingAmbilPoin || this.state.isLoadingTambahPoin ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </Panel>
    );
  }
}

function TitleModal(data, onSubmit) {
  return "Form Ambil Hadiah";
}

HeadFormAmbilHadiah = reduxForm({
  form: "HeadFormAmbilHadiah",
  enableReinitialize: true,
})(HeadFormAmbilHadiah);
export default connect(maptostate, null)(HeadFormAmbilHadiah);
