import { hideModal, showModal } from "actions/datamaster_action";
import { dataURLtoFile, makeID } from "components/helpers/function";
import { NotifSucces, ToastNotification } from "components/helpers/library";
import { NotifError } from "components/helpers/notification";
import {
  deleteData,
  deleteImageApp,
  getData,
  postData,
  postImageApp,
  putData,
} from "config/axios";
import {
  setLoading,
  stopLoading,
} from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_INFO_KATALOG = "GET_INFO_KATALOG";
export const SET_DATA_INFO_KATALOG = "SET_DATA_INFO_KATALOG";
export const DELETE_INFO_KATALOG = "DELETE_INFO_KATALOG";
export const EDIT_INFO_KATALOG = "EDIT_INFO_KATALOG";
export const POST_INFO_KATALOG = "POST_INFO_KATALOG";

export const fetchInfoKatalog = () => {
  return (dispatch) => {
    dispatch(setLoading());
    getData("app/info-katalog")
      .then((res) => {
        dispatch({
          type: GET_INFO_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
      });
  };
};

export const setEditInfoKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_INFO_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteInfoKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Info " +
        data.kode_info +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteInfoKatalog(data.kode_info, data.gambar_name));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahInfoKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_INFO_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteInfoKatalog = (kode_info, gambar_name) => {
  return (dispatch) => {
    dispatch(setLoading());
    deleteData("app/info-katalog/delete/" + kode_info)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus")
          .then(() => dispatch(fetchInfoKatalog()))
          .then(() => {
            deleteImageApp(`INFO_ICON/${gambar_name}`);
            dispatch(stopLoading());
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
        NotifError("Gagal Menghapus Info Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const updateInfoKatalog = (kode_info, data) => {
  return (dispatch) => {
    dispatch(setLoading());
    putData("app/info-katalog/edit/" + kode_info, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchInfoKatalog());
        dispatch(hideModal());
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
        NotifError("Gagal Merubah Info Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const sendInfoKatalog = (data) => {
  return (dispatch) => {
    dispatch(setLoading());
    postData("app/info-katalog", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchInfoKatalog());
        dispatch(hideModal());
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
        NotifError("Gagal Menambahkan Info Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const handleSubmitInfoKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(setLoading());
    const state = getState();
    const current = state.stateInfoKatalog.ShowModal;
    const gambar_name = makeID();
    if (current) {
      if (data.gambar_path !== current.gambar_path) {
        var imgUrl = await postImageApp(
          dataURLtoFile(data.gambar_path),
          `INFO_ICON/${data.gambar_name}`
        );
        data.gambar_path = imgUrl;
      }
      let kode_info = data.kode_info;
      delete data.kode_info;
      if (data.sub_title === undefined) {
        data.sub_title = " ";
      }
      if (data.deskripsi === undefined) {
        data.deskripsi = " ";
      }
      dispatch(updateInfoKatalog(kode_info, data));
      dispatch(stopLoading());
    } else {
      if (data.gambar_path !== undefined) {
        var imgUrl2 = await postImageApp(
          dataURLtoFile(data.gambar_path),
          `INFO_ICON/${gambar_name}`
        );
        data.gambar_path = imgUrl2;
      } else {
        NotifError("Gambar Wajib Dimasukan");
        dispatch(stopLoading());
        return false;
      }
      if (data.sub_title === undefined) {
        data.sub_title = " ";
      }
      if (data.deskripsi === undefined) {
        data.deskripsi = " ";
      }
      dispatch(setLoading());
      data.gambar_name = gambar_name;
      dispatch(sendInfoKatalog(data));
    }
  };
};
