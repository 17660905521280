import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "actions/datamaster_action";
import { ReanderField, ReanderSelectMultiple } from "components/helpers/field";

const maptostate = (state) => {
  if (state.stateHargaKatalog.ShowModal !== null) {
    return {
      isEdit: true,
      isLoading: state.utility.isLoading,
      DataGroup: state.datamaster.GetDataGroup,
      initialValues: {
        nama_harga_app: state.stateHargaKatalog.ShowModal.nama_harga_app,
        detail_group: state.stateHargaKatalog.ShowModal.detail_group.map((data) => {
          return {
            value : data.kode_group,
            label: `${data.nama_group} / ${data.kadar}`
          }
        }),
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
      DataGroup: state.datamaster.GetDataGroup,
    }
  }
};
class FormHargaKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktif: false,
      listgroup: [],
      parameter_bandrol: "2301",
    };
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <Field
          tabIndex="1"
          id="nama_harga_app"
          name="nama_harga_app"
          component={ReanderField}
          type="text"
          label="Label Harga"
          placeholder="Masukan Label Harga"
        />
        <Field
          tabIndex="2"
          id="detail_group"
          name="detail_group"
          component={ReanderSelectMultiple}
          label="List Group Yang Masuk Harga"
          placeholder="Silahkan Pilih Group Harga"
          options={this.props.DataGroup.map((data)=> {
            return {
              value : data.kode_group,
              label: `${data.nama_group} / ${data.kadar}`
            }
          })}
        />
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="5"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormHargaKatalog = reduxForm({
  form: "FormHargaKatalog",
  enableReinitialize: true,
})(FormHargaKatalog);
export default connect(maptostate, null)(FormHargaKatalog);
