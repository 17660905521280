import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
class LaporanPesananSerahExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Pesanan"
          sheet="Laporan Pesanan"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="11" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN PESANAN SERAH{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="11"> Periode </th>
            </tr>
            <tr>
              <th colSpan="11">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="11"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="11"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NO</td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                SALES
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO PESANAN
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TGL PESAN
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TGL AMBIL
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TUKANG
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                TOTAL BERAT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ONGKOS
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                TOTAL RP
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                JUMLAH DP
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SISA BAYAR
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, index) => (
              <tr key={index}>
                <td>{++index}</td>
                <td>{row.kode_sales}</td>
                <td>{row.no_pesanan}</td>
                <td>{row.tanggal}</td>
                <td>{row.tanggal_ambil}</td>
                <td>{row.kode_tukang}</td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.total_berat.toFixed(2)}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.ongkos.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.total_harga.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.jumlah_bayar.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.sisa_bayar.toLocaleString("kr-KO")}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="6"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Grand Total Barang :
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.total_berat), 0)
                  .toFixed(2)}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .map((list) => list.ongkos)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .map((list) => list.total_harga)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .map((list) => list.jumlah_bayar)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .map((list) => list.sisa_bayar)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanPesananSerahExel;
