import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getUserdata } from "../../../../components/helpers/function";

class TransaksiMemberExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Transaksi Member"
          sheet="Transaksi Member"
          buttonText="Export Exel"
        />

        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="7">TRANSAKSI MEMBER</th>
            </tr>
            {/* <tr>
              <th colSpan="7"> Periode </th>
            </tr>
            <tr>
              <th colSpan="7">
                {datalocal.length === 0
                  ? null
                  : datalocal.tgl_awal + " s/d " + datalocal.tgl_akhir}{" "}
              </th>
            </tr> */}
            <tr>
              <th colSpan="7"> TOKO : {getUserdata().nama_toko} </th>
            </tr>
            <tr>
              <th colSpan="7"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="7"></th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kode Member
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Nama Member
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Transaksi
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Jenis
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Status
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Deskripsi
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Keterangan
              </th>
            </tr>
          </thead>

          {this.props.data?.map((item, index) => (
            <>
              <tbody>
                <>
                  <tr>
                    <td> {item.kode_member}</td>
                    <td> {item.nama_member}</td>
                    <td> {item.transaksi}</td>
                    <td> {item.jenis}</td>
                    <td> {item.status}</td>
                    <td align="left"> {item.no_nota.toString()}</td>
                    <td> {item.keterangan}</td>
                  </tr>
                </>
              </tbody>
            </>
          ))}
          {/* <tfoot>
            <tr>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
                colSpan="8"
              >
                {" "}
                Jumlah Daftar{" "}
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                {this.props.data.length}
              </th>
            </tr>
          </tfoot> */}
        </table>
      </>
    );
  }
}
export default TransaksiMemberExcel;
