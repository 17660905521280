import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { Panel, PanelBody, PanelHeader } from "../../../components/panel/panel";
import HeadFormBeliHadiah from "./HeadFormBeliHadiah";

class FormBeliHadiah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // point: "POINT",
    };
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#"> Hadiah</Link>
          </li>
          <li className="breadcrumb-item active">Beli Hadiah</li>
        </ol>
        <h1 className="page-header">Beli Hadiah </h1>
        <Panel>
          <PanelHeader>Beli Hadiah</PanelHeader>
          <br />

          <PanelBody>
            <HeadFormBeliHadiah />
          </PanelBody>
          <br />
        </Panel>
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </div>
    );
  }
}

export default connect(null)(FormBeliHadiah);
