import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  formatGram,
  getItem,
  getToday,
  getUserdata,
  // localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanHasilSoPdfRekap = (data = "", typeTrX = "") => {
  // initialize jsPDF
  console.log(typeTrX);
  const doc = new jsPDF("l", "mm", [297, 210]);
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 30;
  let total_berat = 0;
  let total_qty = 0;
  let total_qty_ketemu = 0;
  let total_berat_ketemu = 0;
  let total_qty_hilang = 0;
  let total_berat_hilang = 0;
  let total_qty_lebih = 0;
  let total_berat_lebih = 0;

  doc.setFontSize(15);
  doc.text("LAPORAN HASIL STOCK OPNAME REKAP", 14, 15);
  // doc.setFontSize(10);
  // doc.text(typeTrX, 14, 20);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN HASIL STOCK OPNAME",
  });

  doc.text(`PERIODE : ${getItem("tgl_laporan").tgl_awal}`, 14, 25);
  tableColumn = [
    [
      {
        content: `BAKI`,
      },
      {
        content: `QTY BARANG KETEMU`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT BARANG KETEMU`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY BARANG TIDAK KETEMU`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT BARANG TIDAK KETEMU`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY BARANG LEBIH`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT BARANG LEBIH`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `TOTAL QTY`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `TOTAL BERAT`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  data.forEach((row, index) => {
    let detail = [
      {
        content: row.kode_toko,
      },

      {
        // content: row.qty_ketemu || "",
        content: parseInt(row.qty_ketemu),
        styles: {
          halign: "right",
        },
      },

      {
        content: `${formatGram(row.berat_ketemu, 3)}`,
        styles: {
          halign: "right",
        },
      },
      {
        content: row.qty_hilang,
        styles: {
          halign: "right",
        },
      },
      {
        content: `${formatGram(row.berat_hilang, 3)}`,
        styles: {
          halign: "right",
        },
      },
      {
        content: row.qty_lebih,
        styles: {
          halign: "right",
        },
      },
      {
        content: `${parseFloat(formatGram(row.berat_lebih, 3))}`,
        styles: {
          halign: "right",
        },
      },
      {
        content: row.qty_total,
        styles: {
          halign: "right",
        },
      },

      {
        content: `${formatGram(row.berat_total, 3)}`,
        styles: {
          halign: "right",
        },
      },
      (total_qty_ketemu = total_qty_ketemu + row.qty_ketemu),
      (total_berat_ketemu = total_berat_ketemu + row.berat_ketemu),
      (total_qty_hilang = total_qty_hilang + row.qty_hilang),
      (total_berat_hilang = total_berat_hilang + row.berat_hilang),
      (total_qty_lebih = total_qty_lebih + row.qty_lebih),
      (total_berat_lebih = total_berat_lebih + row.berat_lebih),
      (total_qty = total_qty + row.qty_ketemu + row.qty_hilang + row.qty_lebih),
      (total_berat =
        total_berat + row.berat_ketemu + row.berat_hilang + row.berat_lebih),
      // total_berat = total_berat + total_berat_ketemu + total_berat_hilang + total_berat_lebih,
    ];

    tableRows.push(detail);
  });
  let footer = [
    {
      content: `Total`,
      colSpan: 1,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_qty_ketemu,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat_ketemu, 3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },

    {
      content: total_qty_hilang,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat_hilang, 3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: total_qty_lebih,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat_lebih, 3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: total_qty,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat, 3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
  ];

  tableRows.push(footer);

  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  footRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN HASIL STOCK OPNAME.pdf`);
};

export default LaporanHasilSoPdfRekap;
