import { hideModal, showModal } from "actions/datamaster_action";
import { dataURLtoFile, makeID } from "components/helpers/function";
import { NotifSucces, ToastNotification } from "components/helpers/library";
import { NotifError } from "components/helpers/notification";
import {
  deleteData,
  deleteImageApp,
  getData,
  postData,
  postImageApp,
  putData,
} from "config/axios";
import {
  setLoading,
  stopLoading,
} from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_HADIAH_KATALOG = "GET_HADIAH_KATALOG";
export const SET_DATA_HADIAH_KATALOG = "SET_DATA_HADIAH_KATALOG";
export const DELETE_HADIAH_KATALOG = "DELETE_HADIAH_KATALOG";
export const EDIT_HADIAH_KATALOG = "EDIT_HADIAH_KATALOG";
export const POST_HADIAH_KATALOG = "POST_HADIAH_KATALOG";

export const fetchHadiahKatalog = () => {
  return (dispatch) => {
    dispatch(setLoading());
    getData("app/member/list-gift")
      .then((res) => {
        dispatch({
          type: GET_HADIAH_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
      });
  };
};

export const setEditHadiahKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DATA_HADIAH_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteHadiahKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Hadiah " +
        data.kode_hadiah +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteHadiahKatalog(data.kode_hadiah, data?.gambar_name ?? "-")
          );
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahHadiahKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_HADIAH_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteHadiahKatalog = (kode_barang, gambar_name) => {
  return (dispatch) => {
    dispatch(setLoading());
    deleteData(`app/member/list-gift/delete/${kode_barang}`)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus")
          .then(() => deleteImageApp(`/HADIAH/${gambar_name}`))
          .then(() => dispatch(fetchHadiahKatalog()))
          .then(() => dispatch(stopLoading()));
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
        NotifError("Gagal Menghapus Barang Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const updateHadiahKatalog = (kode_hadiah, data) => {
  return (dispatch) => {
    dispatch(setLoading());
    delete data.kode_hadiah;
    putData("app/member/list-gift/edit/" + kode_hadiah, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchHadiahKatalog());
        dispatch(hideModal());
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        NotifError("Gagal Mengubah Barang Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const sendHadiahKatalog = (data) => {
  return (dispatch) => {
    dispatch(setLoading());
    postData("app/member/list-gift", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchHadiahKatalog());
        dispatch(hideModal());
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading());
        NotifError("Gagal Mengirim Barang Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const handleSubmitHadiahKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(setLoading());
    const state = getState();
    const current = state.stateHadiahKatalog.ShowModal;
    if (data.gambar_path === undefined) {
      NotifError("Gambar Wajib Dimasukan");
      dispatch(stopLoading());
      return false;
    }
    if (current) {
      if (data.gambar_path !== current.gambar_path) {
        let url = await postImageApp(
          dataURLtoFile(data.gambar_path),
          `HADIAH/${data.gambar_name}`
        );
        data.gambar_path = url;
      }

      dispatch(updateHadiahKatalog(data.kode_hadiah, data));
      dispatch(stopLoading());
    } else {
      let gambar_name = makeID();
      let url = await postImageApp(
        dataURLtoFile(data.gambar_path),
        `HADIAH/${gambar_name}`
      );
      data.gambar_path = url;
      data.gambar_name = gambar_name;
      data.deskripsi = data.deskripsi === undefined ? " " : data.deskripsi;
      dispatch(setLoading());
      dispatch(sendHadiahKatalog(data));
    }
  };
};
