import React from "react";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export const renderTextArea = ({
  input,
  label,
  placeholder,
  id,
  tabIndex,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <textarea
      {...input}
      tabIndex={tabIndex}
      autoComplete="off"
      id={id}
      className="form-control"
      placeholder={placeholder}
      rows="4"
    ></textarea>

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);
export const HiidenFiled = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  value,
  id,
  tabIndex,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      tabIndex={tabIndex}
      autoComplete="off"
      type={type}
      id={id}
      className="form-control"
      readOnly={readOnly}
      defaultValue={value}
      placeholder={placeholder}
    />
  </>
);

export const inputGroup = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  textValue,
  ref,
  customeCss,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label || <> &nbsp; </>}
    </label>
    <div className="input-group mb-3">
      <input
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault(); //<===== This stops the form from being submitted
          } else {
          }
        }}
        {...input}
        tabIndex={tabIndex}
        ref={ref}
        autoComplete="off"
        type={type}
        id={id}
        style={{ textTransform: "uppercase" }}
        className={"form-control " + customeCss}
        readOnly={readOnly}
        // defaultValue={defaultValue}
        placeholder={placeholder}
      />
      <div class="input-group-prepend">
        <span class="input-group-text">{textValue}</span>
      </div>
      {touched &&
        ((error && (
          <ul className="parsley-errors-list filled">
            <li className="parsley-required"> {error}.</li>
          </ul>
        )) ||
          (warning && <p>{warning}</p>))}
    </div>
  </div>
);
export const ReanderField = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  autoFocus,
  ref,
  customeCss,
  defaultValue,
  maxLength,
  nouperCase,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label || <> &nbsp; </>}
    </label>
    <input
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      tabIndex={tabIndex}
      ref={ref}
      autoComplete="off"
      type={type}
      id={id}
      style={{ textTransform: !nouperCase ? "uppercase" : "none" }}
      className={"form-control " + customeCss}
      readOnly={readOnly}
      // defaultValue={defaultValue}
      maxLength={maxLength}
      placeholder={placeholder}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const ReanderCheckBox = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  autoFocus,
  ref,
  customeCss,
  defaultValue,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    {/* <label htmlFor="" className="text-black">
      {label || <> &nbsp; </>}  
    </label> */}
    {label || <> &nbsp; </>}
    <div className={"mb-2"}></div>
    <div className="switcher">
      {/* <input
        type="checkbox"
        name="switcher_checkbox_1"
        id="switcher_checkbox_1"
        defaultChecked
        value="1"
      /> */}
      <input
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault(); //<===== This stops the form from being submitted
          } else {
          }
        }}
        {...input}
        defaultChecked
        // value="1"
        tabIndex={tabIndex}
        ref={ref}
        autoComplete="off"
        type={type}
        id={id}
        // className={}
        readOnly={readOnly}
        // defaultValue={defaultValue}
        placeholder={placeholder}
      />{" "}
      <label htmlFor="switcher_checkbox_1"></label>
    </div>

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);
export const FormatNumber = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <NumberFormat
      thousandSeparator={"."}
      decimalSeparator={","}
      autoComplete="off"
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      type={type}
      inputMode="numeric"
      className="form-control"
      readOnly={readOnly}
      placeholder={placeholder}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const Gramasi = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  defaultValue,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <input
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      tabIndex={tabIndex}
      autoComplete="off"
      type="number"
      id={id}
      className="form-control"
      readOnly={readOnly}
      defaultValue={defaultValue}
      placeholder={placeholder}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const SkeletonLoading = ({ label }) => (
  <div className="form-group">
    <label> {label} </label>
    <Skeleton className="form-control" />
  </div>
);

export const ReanderSelect = ({
  input,
  label,
  readOnly,
  placeholder,
  options,
  value,
  id,
  disabled,
  tabIndex,

  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <SelectSearch
      autoComplete="off"
      onInputKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      id={id}
      readOnly={readOnly}
      search
      tabIndex={tabIndex}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
    />
    {/* <Select
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        options={options}
     
      /> */}

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const ReanderSelectMultiple = ({
  input,
  label,
  readOnly,
  placeholder,
  options,
  value,
  id,
  disabled,
  tabIndex,
  defaultValue,
  onChange,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <Select
      defaultValue={defaultValue}
      onInputKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        } else {
        }
      }}
      {...input}
      options={options}
      isSearchable={true}
      id={label}
      isMulti
      openMenuOnFocus
      tabIndex={tabIndex}
      isDisabled={readOnly}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
    />

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const InputDate = ({
  input,
  label,
  readOnly,
  placeholder,
  id,
  selected,
  customInput,
  showMonthYearPicker,
  showFullMonthYearPicker,
  minDate,
  meta: { touched, error, warning },
}) => (
  <div className="input-group mb-3">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <div className="customDatePickerWidth">
      <DatePicker
        id={id}
        todayButton="Hari Ini"
        peekNextMonth
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat={showMonthYearPicker ? "yyyy-MM" : "yyyy-MM-dd"}
        autoComplete="off"
        minDate={minDate}
        {...input}
        onKeyDown={(event) => {
          event.preventDefault(); //<===== This stops the form from being submitted
          return false;
        }}
        selected={selected}
        disabledKeyboardNavigation={true}
        className="form-control"
        readOnly={readOnly}
        placeholder={placeholder}
        customInput={customInput}
      />
      {touched &&
        ((error && (
          <ul className="parsley-errors-list filled">
            <li className="parsley-required"> {error}.</li>
          </ul>
        )) ||
          (warning && <p>{warning}</p>))}
    </div>
  </div>
);

export const terbilang = (a) => {
  let bilangan = [
    "",
    "Satu",
    "Dua",
    "Tiga",
    "Empat",
    "Lima",
    "Enam",
    "Tujuh",
    "Delapan",
    "Sembilan",
    "Sepuluh",
    "Sebelas",
  ];
  let kalimat = "";
  let utama = "";
  let depan = "";
  let belakang = "";
  // 1 - 11
  if (a < 12) {
    kalimat = bilangan[a];
  }
  // 12 - 19
  else if (a < 20) {
    kalimat = bilangan[a - 10] + " Belas";
  }
  // 20 - 99
  else if (a < 100) {
    utama = a / 10;
    depan = parseInt(String(utama).substr(0, 1));
    belakang = a % 10;
    kalimat = bilangan[depan] + " Puluh " + bilangan[belakang];
  }
  // 100 - 199
  else if (a < 200) {
    kalimat = "Seratus " + terbilang(a - 100);
  }
  // 200 - 999
  else if (a < 1000) {
    utama = a / 100;
    depan = parseInt(String(utama).substr(0, 1));
    belakang = a % 100;
    kalimat = bilangan[depan] + " Ratus " + terbilang(belakang);
  }
  // 1,000 - 1,999
  else if (a < 2000) {
    kalimat = "Seribu " + terbilang(a - 1000);
  }
  // 2,000 - 9,999
  else if (a < 10000) {
    utama = a / 1000;
    depan = parseInt(String(utama).substr(0, 1));
    belakang = a % 1000;
    kalimat = bilangan[depan] + " Ribu " + terbilang(belakang);
  }
  // 10,000 - 99,999
  else if (a < 100000) {
    utama = a / 100;
    depan = parseInt(String(utama).substr(0, 2));
    belakang = a % 1000;
    kalimat = terbilang(depan) + " Ribu " + terbilang(belakang);
  }
  // 100,000 - 999,999
  else if (a < 1000000) {
    utama = a / 1000;
    depan = parseInt(String(utama).substr(0, 3));
    belakang = a % 1000;
    kalimat = terbilang(depan) + " Ribu " + terbilang(belakang);
  }
  // 1,000,000 - 	99,999,999
  else if (a < 100000000) {
    utama = a / 1000000;
    depan = parseInt(String(utama).substr(0, 4));
    belakang = a % 1000000;
    kalimat = terbilang(depan) + " Juta " + terbilang(belakang);
  } else if (a < 1000000000) {
    utama = a / 1000000;
    depan = parseInt(String(utama).substr(0, 4));
    belakang = a % 1000000;
    kalimat = terbilang(depan) + " Juta " + terbilang(belakang);
  } else if (a < 10000000000) {
    utama = a / 1000000000;
    depan = parseInt(String(utama).substr(0, 1));
    belakang = a % 1000000000;
    kalimat = terbilang(depan) + " Milyar " + terbilang(belakang);
  } else if (a < 100000000000) {
    utama = a / 1000000000;
    depan = parseInt(String(utama).substr(0, 2));
    belakang = a % 1000000000;
    kalimat = terbilang(depan) + " Milyar " + terbilang(belakang);
  } else if (a < 1000000000000) {
    utama = a / 1000000000;
    depan = parseInt(String(utama).substr(0, 3));
    belakang = a % 1000000000;
    kalimat = terbilang(depan) + " Milyar " + terbilang(belakang);
  } else if (a < 10000000000000) {
    utama = a / 10000000000;
    depan = parseInt(String(utama).substr(0, 1));
    belakang = a % 10000000000;
    kalimat = terbilang(depan) + " Triliun " + terbilang(belakang);
  } else if (a < 100000000000000) {
    utama = a / 1000000000000;
    depan = parseInt(String(utama).substr(0, 2));
    belakang = a % 1000000000000;
    kalimat = terbilang(depan) + " Triliun " + terbilang(belakang);
  } else if (a < 1000000000000000) {
    utama = a / 1000000000000;
    depan = parseInt(String(utama).substr(0, 3));
    belakang = a % 1000000000000;
    kalimat = terbilang(depan) + " Triliun " + terbilang(belakang);
  } else if (a < 10000000000000000) {
    utama = a / 1000000000000000;
    depan = parseInt(String(utama).substr(0, 1));
    belakang = a % 1000000000000000;
    kalimat = terbilang(depan) + " Kuadriliun " + terbilang(belakang);
  }

  let pisah = kalimat.split(" ");
  let full = [];
  for (let i = 0; i < pisah.length; i++) {
    if (pisah[i] !== "") {
      full.push(pisah[i]);
    }
  }
  return full.join(" ");
};

export const convertDateIndo = (date) => {
  let data = date.split("-");
  return data.reverse().join("-");
};
