import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../components/helpers/field";
import { getTglSystem } from "../../../components/helpers/function";
import Tabel from "../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../Validasi/ValidasiTanggalLaporan";
import Loading from "react-fullscreen-loading";
import LaporanBatalPesananPdf from "./pdf/LaporanBatalPesananPdf";
import LaporanBatalPesananExel from "./excel/LaporanBatalPesananExcel";
import { getMasterToko } from "actions/datamaster_action";

class HeadLaporanBatalPesanan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "no_pesanan",
          text: "No Pesanan",
          footer: "",
          footerAttrs: {
            colSpan: "5",
          },
          headerStyle: (colum, colIndex) => {
            return { width: "140px" };
          },
        },
        {
          dataField: "estimasi_tanggal",
          text: "Estimasi Selesai",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },
        {
          dataField: "nama_customer",
          text: "Nama Customer",
          headerStyle: (colum, colIndex) => {
            return { width: "150px" };
          },
        },
        {
          dataField: "kode_sales",
          text: "Sales",
          headerStyle: (colum, colIndex) => {
            return { width: "50px" };
          },
        },
        {
          dataField: "tanggal",
          text: "Tgl Batal",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },
        {
          dataField: "total_berat",
          text: "Total Berat",
          headerClasses: "text-right",
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
        },
        {
          dataField: "total_qty",
          text: "Total Qty",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
          formatter: (data) => {
            return <div className="text-right"> {data}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          ),
        },
        {
          dataField: "ongkos",
          text: "Ongkos",
          headerClasses: "text-right",

          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-ko")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
        {
          dataField: "total_harga",
          text: "Total Rp",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-ko")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },

        {
          dataField: "jumlah_bayar",
          text: "Bayar Rp",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
        {
          dataField: "sisa_bayar",
          text: "Sisa Rp",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-KO")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    LaporanBatalPesananPdf(this.props.LaporanPesanan);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  setKodeToko(data) {
    this.setState({ kode_toko: data });
    // setTimeout(() => {
    //   this.getNoKirim("toko");
    // }, 300);
  }

  componentDidMount() {
    this.props.dispatch(getMasterToko());
  }

  render() {
    const dataToko = [
      {
        value: "all",
        name: "SEMUA",
      },
    ];
    this.props.dataToko.forEach((data) => {
      let hasil = {
        value: data.kode_toko,
        name: data.kode_toko,
      };
      dataToko.push(hasil);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="kode_toko"
              component={ReanderSelect}
              options={dataToko}
              type="text"
              label="Kode Toko"
              onChange={(data) => this.setKodeToko(data)}
              placeholder="Pilih Kode Toko"
            />
          </div>

          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_pesanan"
              data={this.props.export ? this.props.LaporanPesanan : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Titip Uang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            <label htmlFor="">&nbsp;</label>
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanBatalPesananExel data={this.props.LaporanPesanan} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanBatalPesanan = reduxForm({
  form: "HeadLaporanBatalPesanan",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadLaporanBatalPesanan);
export default connect((state) => {
  return {
    dataToko: state.datamaster.getMasterToko,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
      kode_toko: "all",
    },
  };
})(HeadLaporanBatalPesanan);
