import HeaderTrxHutangSales from "./HeaderTrxHutangSales";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  convertDate,
  getDataParams,
  ToastNotification,
  localStorageencryp
} from "../../../../components/helpers/library";

class LaporanTransaksiHutangSales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      dataHutang: [],
      LaporanHutang: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(data) {
    if(data.kode_sales === undefined){
      ToastNotification("info", "Kode Sales Harga Dipilih")
      return false;
    }
    let query = {
      kode_sales: data.kode_sales,
      tgl_from: convertDate(data.tgl_awal),
      tgl_to: convertDate(data.tgl_akhir),
      status_hutang: data.status_hutang,
    };
    this.setState({
      isLoading: true,
    });

    getDataParams("hutang/report-hutang-persales", query)
      .then((res) => {
        localStorageencryp('tgl_laporan', JSON.stringify(query))
        if(res.data.length === 0){
          ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!")
          this.setState({
            isLoading: false,
            export: false,
            LaporanHutang: [],
          });
          return false;
        }
        this.setState({
          isLoading: false,
          export: true,
          LaporanHutang: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          export: false,
        });
        ToastNotification(
          "info",
          err?.response?.data?.message || "Terjadi Kesalahan Saat Request Data"
        );
      });
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Hutang</Link>
          </li>
          <li className="breadcrumb-item active">
            Laporan Transaksi Hutang Sales
          </li>
        </ol>
        <h1 className="page-header">Laporan Transaksi Hutang Sales </h1>
        <Panel>
          <PanelHeader>Laporan Transaksi Hutang Sales</PanelHeader>
          <PanelBody>
            <HeaderTrxHutangSales
              export={this.state.export}
              isLoading={this.state.isLoading}
              LaporanHutang={this.state.LaporanHutang}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanTransaksiHutangSales = reduxForm({
  form: "LaporanTransaksiHutangSales",
  enableReinitialize: true,
})(LaporanTransaksiHutangSales);
export default connect(null)(LaporanTransaksiHutangSales);
