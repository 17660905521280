import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
// import { InputDate, ReanderField, ReanderSelect } from "../../../../components/helpers/field";
import { getTglSystem } from "../../../../components/helpers/function";
import Tabel from "../../../../components/helpers/tabel";
import LaporanTukarPointPdf from "./pdf/LaporanTukarPointPdf";
import Loading from "react-fullscreen-loading";
import LaporanTukarPointExcel from "./exel/LaporanTukarPointExel";
import ModalGlobal from "../../../../components/helpers/ModalGlobal";
import FilterCustomer from "./FilterCustomer";
// import { showModal } from "../../../../actions/datamaster_action";
class HeadFormLaporanTukarPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  handleSubmit(data) {
    console.log(data);
  }
  exportPdf() {
    LaporanTukarPointPdf(this.props.datamember);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  typeLaporan(e) {
    if (e === "SEMUA") {
      this.setState({
        type_laporan: "SEMUA",
      });
    } else {
      this.setState({
        type_laporan: "SELISIH",
      });
    }
  }

  render() {
    let columns = [
      {
        dataField: "kode_member",
        text: "Kode Member",
        footerAttrs: {
          colSpan: "4",
        },
        footer: (cell, row, num, index) => {
          return <div>Total Daftar : {this.props.datamember.length} </div>;
        },
      },
      {
        dataField: "kode_toko",
        text: "Kode Toko",
      },
      {
        dataField: "tgl_trx",
        text: "Tanggal Transkasi",
      },
      {
        dataField: "deskripsi",
        text: "Barang Tukar",
      },
      {
        dataField: "poin_awal",
        text: "Point Awal",
      },
      {
        dataField: "poin",
        text: "Harga Point",
      },
      {
        dataField: "poin_akhir",
        text: "Point Akhir",
      },
    ];

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Awal"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Awal"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              label="Tanggal Akhir"
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              placeholder="Masukan Tanggal AKhir"
            />
          </div>

            <div className="col-lg-3">
            <Field
              name="type"
              component={ReanderSelect}
              onChange={(e) => this.typeLaporan(e)}
              options={[
                { value: "DETAIL", name: "DETAIL" },
                { value: "REKAP", name: "REKAP" },
              ]}
              label="Type Laporan"
              placeholder="Pilih Type"
            />
          </div>
          {/* <div className="col-lg-2">
            <Field
              name="kode_toko"
              component={ReanderField}
              label="Kode Toko"
              type="text"
              selected={this.state.kode_toko}
              placeholder="Masukan Kode Toko"
            />
          </div> */}
          {/* <div className="col-lg-2">
            <Field
              name="kode_member"
              component={ReanderField}
              label="Kode Member"
              type="text"
              selected={this.state.kode_member}
              placeholder="Kode Member"
            />
          </div> */}
          {/* <div className="col-lg-2">
            <label>&nbsp;</label>
            <button
              type="button"
              name="type_btn"
              onClick={() => this.props.dispatch(showModal())}
              className="btn btn-success btn-block"
            >
              Cari Member
            </button>
          </div> */}
          <div className="col-lg-3 mt-4">
            <div className="text-right">
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_hp"
              data={this.props.export ? this.props.datamember : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Member Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            <label htmlFor="">&nbsp;</label>
            <div className="row">
              <div className="col-lg-6">
                <button
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanTukarPointExcel data={this.props.datamember} />
              </div>
            </div>
          </div>
          <ModalGlobal
            title="Cari Member"
            size="xl"
            content={<FilterCustomer onSubmit={(data) => console.log(data)} />}
          />
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadFormLaporanTukarPoint = reduxForm({
  form: "HeadFormLaporanTukarPoint",
  enableReinitialize: true,
})(HeadFormLaporanTukarPoint);
// export default connect()(HeadFormLaporanTukarPoint);
const selector = formValueSelector("HeadFormLaporanTukarPoint");
export default connect((state) => {
  return {
    tanggal: selector(state, "tgl_tukar"),
    kode_toko: selector(state, "kode_toko"),
    kode_member: selector(state, "kode_member"),
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadFormLaporanTukarPoint);
