import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../components/helpers/function";
import { convertBulan } from "../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const CetakRekapPembelianPdf = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;


  doc.setFontSize(15);
  doc.text("LAPORAN REKAP PEMBELIAN PERBULAN", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0,48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48,90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN REKAP PEMBELIAN PERBULAN",
  });
  //row 1

  tableColumn = [
    [
      {
        content: `BULAN`,
      },
    
      {
        content: `QTY`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `HARGA NOTA`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `HARGA BELI`,
        styles: {
          halign: 'right',
        },
      },
     
    ],
  ];
 
  data.forEach((item, index) => {
   
    let rows = [
      convertBulan(moment(new Date(item.tanggal)).format("M")),
      {
        content: parseInt(item.qty),
        styles: {
          halign: 'right',
        },
      },
      {
        content: item.berat.toFixed(3),
        styles: {
          halign: 'right',
        },
      },
     
      {
        content: parseInt(item.harga_nota).toLocaleString("kr-KO"),
        styles: {
          halign : "right",
        }
      },
      {
        content: parseInt(item.harga_beli).toLocaleString("kr-KO"),
        styles: {
          halign : "right",
        }
      },
     
    ];
    tableRows.push(rows)
  })
  
  let footer = [
    {
      content: `Total`,
      styles: {
        halign: 'center',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((a,b)=>a +  parseInt(b.qty),0),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: data.reduce((a,b)=>a +  parseFloat(b.berat),0).toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: data.reduce((a,b)=>a +  parseInt(b.harga_nota),0).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: data.reduce((a,b)=>a +  parseInt(b.harga_beli),0).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
   
  ]
  tableRows.push(footer)

  let printed = [
    {
      content: `Printed By ${JSON.parse(localStoragedecryp("userdata")).user_id} / ${getToday()}`,
      colSpan: 7,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ]
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: tbl_barang,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // footRows = [];
  // tbl_barang = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     JSON.parse(localStoragedecryp("userdata")).user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN REKAP PEMBELIAN PERBULAN.pdf`);

};

export default CetakRekapPembelianPdf;
