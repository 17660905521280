



import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getUserdata, localStoragedecryp } from "../../../../components/helpers/function";

class CetakLaporanHistoryCetakBarcodeExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-success btn-block"
                    table="table-to-xls"
                    filename="Laporan History Cetak Barcode"
                    sheet="Laporan History Cetak Barcode"
                    buttonText="Export Exel"
                />
                <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
                    <thead>
                        <tr>
                            <th colSpan="12" style={{ textAlign: "center" }}> LAPORAN HISTORY CETAK BARCODE </th>
                        </tr>
                        <tr>
                            <th colSpan="12"> Periode </th>
                        </tr>
                        <tr>
                            <th colSpan="12">
                                {" "}
                                {localStoragedecryp("tgl_laporan").length === 0
                                    ? null
                                    : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                                    " s/d " +
                                    JSON.parse(localStoragedecryp("tgl_laporan"))
                                        .tgl_akhir}{" "}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan="12"> TOKO :  {getUserdata().nama_toko} </th>
                        </tr>

                        <tr>
                            <th colSpan="12"> ALAMAT :  {getUserdata().alamat_toko} </th>
                        </tr>

                        <tr></tr>

                        <tr >
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NO</th>
                            {/* <th style={{ backgroundColor: "#E8E5E5", color: "#000"}}>KODE BARANG</th> */}
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>TANGGAL</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>USER ID</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BARCODE</th>

                        </tr>
                    </thead>

                    <tbody>
                        {this.props.data.map((item, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}> {index + 1}</td>
                                <td> {item.tanggal}</td>
                                <td> {item.user_id}</td>
                                <td> {item.kode_barcode}</td>

                            </tr>
                        ))}
                    </tbody>


                </table>
            </>
        );
    }
}
export default CetakLaporanHistoryCetakBarcodeExcel;

