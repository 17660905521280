import React, { Component } from "react";
// import { Type } from "react-bootstrap-table2-editor";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  formatGram,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";


class LaporanHasilSoExcel extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total_barang = 0;
    let total_berat = 0;
    return (
      console.log(this.props.data),
      console.log(this.props.type),

      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Hasil Stock Opname"
          sheet="Laporan Hasil Stock Opname"
          buttonText="Export Exel"
        />
        <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="12" style={{ textAlign: "center" }}> LAPORAN HASIL STOCK OPNAME </th>
            </tr>
            <tr>
              <th colSpan="12" style={{ textAlign: "center" }}> {this.props.type}</th>
            </tr>
            <tr>
              <th colSpan="12"> TOKO :  {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="12"> ALAMAT :  {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="12">
                {"PERIODE :  "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal
                }
              </th>
            </tr>
            <tr></tr>

            <tr >
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>KODE BARCODE</th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NAMA BARANG</th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>DATA ATRIBUT</th>
              <th style={{ backgroundColor: "#E8E5E5", textAlign: "right", color: "#000" }}>BERAT</th>

            </tr>
          </thead>

          <tbody>
            {this.props.data.map((item, index) => {
              total_barang = total_barang + item.detail_barang.length;
              return (
                <tr>
                  <td colSpan="12">
                    {item.kode_baki}
                  </td>
                  {item.detail_barang.map((item, index) => {
                    // total_berat = total_berat + item.berat
                    total_berat = total_berat + parseFloat(item.berat);
                    return (
                      <tr>
                        <td>{item.kode_barcode}</td>
                        <td>{item.nama_barang}</td>
                        <td>{item.nama_atribut}</td>
                        <td style={{ textAlign: "right" }}>&nbsp;{formatGram(item.berat, 3)}</td>
                      </tr>
                    )


                  })}

                  <tr>
                    <td
                      colSpan="4"
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",

                        textAlign: "center",
                      }}
                    >
                      Sub Total : &nbsp;{item.detail_barang.length} Barang
                    </td>
                  </tr>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="3"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",

                  textAlign: "center",
                }}
              >
                Grand Total :  &nbsp;{total_barang} Barang
              </td>

              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;{formatGram(total_berat, 3)}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanHasilSoExcel;
