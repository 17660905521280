import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { formatGram, getItem, getUserdata, localStoragedecryp } from "../../../../../components/helpers/function";
class LaporanStockPembelianExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total_berat = 0;
    let total_berat_nota = 0;
    let total_harga_nota = 0;
    let total_harga = 0;
    let total_harga_rata = 0;
    let total_barang = 0;

    this.props.data.forEach((barang, index) => {
      barang.detail.forEach((item, index) => {
        total_berat = total_berat + parseFloat(item.berat);
        total_berat_nota = total_berat_nota + parseFloat(item.berat_nota);
        total_harga_nota = total_harga_nota + parseFloat(item.harga_nota);
        total_harga = total_harga + parseFloat(item.harga);
        total_harga_rata = total_harga_rata + parseFloat(item.harga_rata);
      });
      total_barang = total_barang + barang.detail.length;
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Stock Pembelian"
          sheet="Laporan Stock Pembelian"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="8"> Laporan Stock Pembelian </th>
            </tr>
            <tr>
              <th colSpan="8"> Periode </th>
            </tr>
            <tr>
              <th colSpan="8">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="8"> TOKO :  {getUserdata().nama_toko} </th>
            </tr>
           
            <tr>
              <th colSpan="8"> ALAMAT :  {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="8"></th>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                NO BELI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                KODE SALES
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                KODE BARCODE
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                NAMA BARANG
              </td>
             
                <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                KONDISI
              </td>
             
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                BERAT
              </td>
             
            
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                HARGA
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                HARGA RATA
              </td>
            
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((item, index) => (
              <>
                    <tr>
                      <td colSpan="12">
                        {`${getItem('tgl_laporan').group_by === "KODE_GROUP" ? "KATEGORI" : "KONDISI" } :  ${item._id}`}
                      </td>
                    </tr>

                {item.detail.map((row, index) => (
                  <tr>
                    <td>{row.no_faktur_beli}</td>
                    <td>{row.kode_sales}</td>
                    <td>&nbsp;{row.kode_barcode}</td>
                    <td>{row.nama_barang}</td>
                    <td>{row.kondisi}</td>
                    <td style={{textAlign : "right" }}>&nbsp;{formatGram(row.berat,3)}</td>
                    <td style={{textAlign : "right" }}>&nbsp;{row.harga.toLocaleString("kr-KO")}</td>
                    <td style={{textAlign : "right" }}>&nbsp;{Number((parseInt(row.harga) / parseFloat(row.berat)).toFixed(0)).toLocaleString("kr-KO")}</td>
                  </tr>
                ))}
                <tr>
              <td
                colSpan="5"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",

                  textAlign: "center",
                }}
              >
                Sub Total : &nbsp;{item.detail.length}
              </td>
             
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",

                }}
              >
                &nbsp;{formatGram(item.detail.map((list)=>list.berat).reduce((a,b)=>a + parseFloat(b),0),3)}
              </td>
             
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",

                }}
              >
                &nbsp;{item.detail.map((list)=>list.harga).reduce((a,b)=>a + parseFloat(b),0).toLocaleString("kr-KO")}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",

                }}
              >
              </td>
              
            </tr>
              </>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="5"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",

                  textAlign: "center",
                }}
              >
                Grand Total :  &nbsp;{total_barang} Transaksi
              </td>
              
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",

                }}
              >
                 &nbsp;{formatGram(total_berat,3)}
              </td>
              
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",

                }}
              >
                 &nbsp;{total_harga.toLocaleString("kr-KO")}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",

                }}
              >
              </td>
              
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanStockPembelianExel;
