const ValidasiTanggalLaporan = (value, event) => {
    const errors = {};
    // console.log(event.charCode==13);
    if (!value.tgl_awal) {
      errors.tgl_awal = "Tanggal Awal Tidak Boleh Kosong";
    }
    if (!value.tgl_akhir) {
      errors.tgl_akhir = "Tanggal Akhir Tidak Boleh Kosong";
    }
    return errors;
  };
  
  export default ValidasiTanggalLaporan;
  