import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getUserdata, getToday } from "../../../components/helpers/function";
class CetakLaporanRekapExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Transaksi Rekap Sales Daily"
          sheet="Laporan Transaksi Rekap Sales Daily"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="4" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN SERVICE MASUK{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="4"> Tanggal </th>
            </tr>
            <tr>
              <th colSpan="4"> {getToday()} </th>
            </tr>
            <tr>
              <th colSpan="4"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="4"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
                colSpan="2"
              >
                QTY
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
                colSpan="2"
              >
                BERAT
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, index) => (
              <>
                <tr key={index}>
                  <td
                    colSpan="2"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {row.qty}
                  </td>
                  <td
                    colSpan="2"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    &nbsp;{row.berat === null || row.berat === undefined || row.berat === "-" ? "-" : row.berat.toFixed(2)}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
                colSpan="2"
              >
                {this.props.data.reduce((a, b) => a + parseInt(b.qty), 0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
                colSpan="2"
              >
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat === "-" ? 0 : b.berat), 0)
                  .toFixed(3)}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default CetakLaporanRekapExel;
