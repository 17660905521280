import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    formatGram,
    getUserdata,
    localStoragedecryp,
} from "../../../../../components/helpers/function";


class LaporanHasilSoExcelRekap extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            console.log(this.props.data),
            <>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-success btn-block"
                    table="table-to-xls"
                    filename="Laporan Hasil Stock Opname"
                    sheet="Laporan Hasil Stock Opname Rekap"
                    buttonText="Export Exel"
                />
                <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
                    <thead>
                        <tr>
                            <th colSpan="12" style={{ textAlign: "center" }}> LAPORAN HASIL STOCK OPNAME  REKAP</th>
                        </tr>
                        <tr>
                            <th colSpan="12"> TOKO :  {getUserdata().nama_toko} </th>
                        </tr>

                        <tr>
                            <th colSpan="12"> ALAMAT :  {getUserdata().alamat_toko} </th>
                        </tr>
                        <tr>
                            <th colSpan="12">
                                {"PERIODE :  "}
                                {localStoragedecryp("tgl_laporan").length === 0
                                    ? null
                                    : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal
                                }
                            </th>
                        </tr>

                        <tr></tr>
                        {/* <tr>
                  <th> Kode Baki </th>
                  <th colSpan="12" style={{textAlign : "left"}}> {this.props.kode_baki} </th>
                </tr> */}
                        <tr >
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NO</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BAKI</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>QTY BARANG KETEMU</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BERAT BARANG KETEMU</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>QTY BARANG TIDAK KETEMU</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BERAT BARANG TIDAK KETEMU</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>QTY BARANG LEBIH</th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BERAT BARANG LEBIH</th>
                            <th style={{ backgroundColor: "#E8E5E5", textAlign: "right", color: "#000" }}>TOTAL QTY</th>
                            <th style={{ backgroundColor: "#E8E5E5", textAlign: "right", color: "#000" }}>TOTAL BERAT</th>

                        </tr>
                    </thead>

                    <tbody>
                        {this.props.data.map((item, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}> {index + 1}</td>
                                {/* <td> {item.kode_barang}</td> */}
                                <td>&nbsp;{item.kode_toko}</td>
                                <td> {item.qty_ketemu}</td>
                                <td style={{ textAlign: "right" }}> {formatGram(item.berat_ketemu, 3)}</td>
                                <td> {item.qty_hilang}</td>
                                <td style={{ textAlign: "right" }}> {formatGram(item.berat_hilang, 3)}</td>
                                <td> {item.qty_lebih}</td>
                                <td style={{ textAlign: "right" }}> {formatGram(item.berat_lebih, 3)}</td>
                                <td> {item.qty_total}</td>
                                <td style={{ textAlign: "right" }}> {formatGram(item.berat_total, 3)}</td>
                            </tr>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "center" }} colSpan="2">  </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>&nbsp; {this.props.data.map(list => list.qty_ketemu).reduce((a, b) => a + b, 0)}   </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> {formatGram(this.props.data.map(list => list.berat_ketemu).reduce((a, b) => a + b, 0), 3)}   </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> &nbsp; {this.props.data.map(list => list.qty_hilang).reduce((a, b) => a + b, 0)}    </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> {formatGram(this.props.data.map(list => list.berat_hilang).reduce((a, b) => a + b, 0), 3)}   </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> &nbsp; {this.props.data.map(list => list.qty_lebih).reduce((a, b) => a + b, 0)}     </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> {formatGram(this.props.data.map(list => list.berat_lebih).reduce((a, b) => a + b, 0), 3)}   </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> &nbsp; {this.props.data.map(list => list.qty_total).reduce((a, b) => a + b, 0)}     </th>
                            <th style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}> {formatGram(this.props.data.map(list => list.berat_total).reduce((a, b) => a + b, 0), 3)}   </th>

                        </tr>
                    </tfoot>
                </table>
            </>
        );
    }
}
export default LaporanHasilSoExcelRekap;
