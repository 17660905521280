import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import {
  formatGram,
  getTglSystem,
} from "../../../../components/helpers/function";
import Tabel from "../../../../components/helpers/tabel";
import ValidasiLaporanKeuangan from "../../../../Validasi/LaporanKeuangan/ValidasiLaporanKeuangan";
import Loading from "react-fullscreen-loading";
import {
  GetDataGroup,
  getDataJenis,
  getKondisiPembelian,
} from "../../../../actions/datamaster_action";
import LaporanStockPembelian from "./pdf/LaporanStockPembelian";
import LaporanStockPembelianExel from "./Exel/LaporanStockPembelianExel";
class HeadFilterStockPembelian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      type: "SEMUA",
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDataJenis());
    this.props.dispatch(getKondisiPembelian());
    this.props.dispatch(GetDataGroup());
    this.props.change("kondisi", "ALL");
    this.props.change("kode_group", "ALL");
    this.props.change("kode_dept", "ALL");
    this.props.change("type_barang", "ALL");
    this.props.change("grouping", "KODE_GROUP");
  }
  exportPdf() {
    LaporanStockPembelian(this.props.LaporanTransaksiPembelian);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  render() {
    let dataGroup = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.dataGroup.forEach((list) => {
      let data = {
        value: list.kode_group,
        name: list.kode_group,
      };
      dataGroup.push(data);
    });
    const dataalljenis = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.datajenis.forEach((element) => {
      let row = {
        value: element.kode_dept,
        name: element.kode_dept,
      };
      dataalljenis.push(row);
    });
    const datakondisiall = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.datakondisi.forEach((element) => {
      let row = {
        value: element.kondisi_barang,
        name: element.kondisi_barang,
      };
      datakondisiall.push(row);
    });
    let databarang = [];
    let berat = 0;
    let ongkos = 0;
    let harga = 0;
    let harga_nota = 0;
    let harga_gram = 0;
    let berat_nota = 0;
    this.props.LaporanTransaksiPembelian.forEach((element) => {
      element.detail.forEach((detail) => {
        databarang.push(detail);
        berat_nota = berat_nota + detail.berat_nota;
        berat = berat + detail.berat;
        ongkos = ongkos + detail.ongkos;
        harga = harga + detail.harga;
        harga_nota = harga_nota + detail.harga_nota;
        harga_gram = harga_gram + detail.harga_gram;
      });
    });
    const columns = [
      {
        dataField: "kode_sales",
        text: "Kode Sales",
      },
      {
        dataField: "no_faktur_beli",
        text: "No FB",
        footerAttrs: {
          colSpan: "4",
        },
        footer: (cell, row, num, index) => {
          return <div>Total Transaksi : {databarang.length} </div>;
        },
      },

      {
        dataField: "kode_barcode",
        text: "Kode Barcode",
      },
      {
        dataField: "nama_barang",
        text: "Nama Barang",
      },
      {
        dataField: "kondisi",
        text: "Kondisi",
        footer: "",
      },
      {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        formatter: (cell) => {
          return <div className="text-right"> {formatGram(cell, 3)} </div>;
        },
        footer: (columnData) => {
          return (
            <div className="text-right">
              {" "}
              {formatGram(
                columnData.reduce((acc, item) => acc + item, 0),
                3
              )}{" "}
            </div>
          );
        },
      },

      {
        dataField: "harga",
        text: "Harga",
        headerClasses: "text-right",
        footer: (cell, row, num, index) => {
          return (
            <div className="text-right">
              {cell
                .reduce((a, b) => parseInt(a) + b, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          );
        },
        formatter: (cell) => {
          return (
            <div className="text-right">{cell.toLocaleString("kr-KO")}</div>
          );
        },
      },
      {
        dataField: "harga_rata",
        text: "Harga Rata",
        headerClasses: "text-right",
        formatter: (cell, row) => {
          return (
            <div className="text-right">
              {Number(
                (parseInt(row.harga) / parseFloat(row.berat)).toFixed(0)
              ).toLocaleString("kr-KO")}
            </div>
          );
        },
      },
    ];
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <Field
              label="Kode Group"
              name="kode_group"
              options={dataGroup}
              component={ReanderSelect}
              placeholder={"Silahkan Pilih Kode Group"}
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kode_dept"
              component={ReanderSelect}
              options={dataalljenis}
              label="Kode Jenis"
              placeholder="Kode Jenis"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kondisi"
              component={ReanderSelect}
              options={datakondisiall}
              label="Kondisi"
              placeholder="Kondisi"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="grouping"
              component={ReanderSelect}
              options={[
                {
                  value: "KODE_GROUP",
                  name: "KATEGORI",
                },
                {
                  value: "KONDISI",
                  name: "KONDISI",
                },
              ]}
              label="Grop Berdasarkan"
              placeholder="Grop Berdasarkan"
            />
          </div>

          <div className="col-lg-3">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>

          <div className="col-lg-12">
            <Tabel
              keyField="kode_barcode"
              data={this.props.export ? databarang : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Stock Pembelian Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanStockPembelianExel
                  data={this.props.LaporanTransaksiPembelian}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadFilterStockPembelian = reduxForm({
  form: "HeadFilterStockPembelian",
  enableReinitialize: true,
  validate: ValidasiLaporanKeuangan,
})(HeadFilterStockPembelian);
// export default connect(null)(HeadFilterStockPembelian);
export default connect((state) => {
  return {
    datajenis: state.datamaster.getDataJenis,
    datakondisi: state.datamaster.getKondisiPembelian,
    dataGroup: state.datamaster.GetDataGroup,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadFilterStockPembelian);
