import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { getTglSystem } from "../../../components/helpers/function";
import {
  getData,
  hideModal,
  imgfound,
  ReanderField,
  ReanderSelect,
  ToastNotification,
} from "../../../components/helpers/library";
import Loading from "react-fullscreen-loading";

class ModalTambahTransaksi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      point: "POINT",
      kondisi_barang: "kosong",
      isLoading: false,
      isLoadingBatal: false,
      readOnly: false,
      gambar: imgfound,
      kategori: "SEMUA",
    };
  }

  getKodeMember(e) {
    if (e.length >= 10) {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      this.delayTimer = setTimeout(() => {
        this.setState({
          isLoading: true,
        });
        if (this.props.kode_member === undefined) {
          return false;
        }

        getData("member/get/by-kode-member/" + e)
          .then((res) => {
            this.props.change("nama_member", res.data[0].nama_customer);
            this.setState({
              datatransaksimember: [],
              isLoading: false,
            });
            // console.log(res.data);
          })
          .catch((err) => {
            ToastNotification("info", "Data member tidak ada");
            this.setState({
              datatransaksimember: [],
              isLoading: false,
            });
          });
      }, 1500);
    } else {
      return false;
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className={"col-lg-3"}>
            <Field
              name={"kode_member"}
              label={"Kode Member"}
              component={ReanderField}
              onChange={(e) => this.getKodeMember(e.target.value)}
            />
          </div>
          <div className={"col-lg-3"}>
            <Field
              name={"nama_member"}
              label={"Nama member"}
              component={ReanderField}
              readOnly
            />
          </div>
          <div className={"col-lg-3"}>
            <Field
              name={"transaksi"}
              label={"Transaksi"}
              component={ReanderSelect}
              options={[
                {
                  value: "PENJUALAN",
                  name: "PENJUALAN",
                },
                {
                  value: "PEMBELIAN",
                  name: "PEMBELIAN",
                },
                {
                  value: "PINJAMAN",
                  name: "PINJAMAN",
                },
                {
                  value: "KASUS",
                  name: "KASUS",
                },
              ]}
              placeholder="Pilih Transaksi"
            />
          </div>
          <div className={"col-lg-3"}>
            <Field
              name={"jenis"}
              label={"Jenis"}
              component={ReanderSelect}
              options={[
                {
                  value: "KASUS",
                  name: "KASUS",
                },
                {
                  value: "PERMINTAAN",
                  name: "PERMINTAAN",
                },
              ]}
              placeholder="Pilih Jenis"
            />
          </div>
          <div className={"col-lg-3"}>
            <Field
              name={"no_nota"}
              label={"Nomor Nota"}
              component={ReanderField}
            />
          </div>
          <div className={"col-lg-9"}>
            <Field
              name={"keterangan"}
              label={"Keterangan"}
              component={ReanderField}
            />
          </div>
          <div className={"col-lg-12"}>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  className="btn btn-white"
                  onClick={() => this.props.dispatch(hideModal())}
                  type="button"
                  disabled={this.props.isLoading}
                >
                  Batal
                </button>
                &nbsp;
                <button
                  tabIndex="3"
                  className="btn btn-primary"
                  disabled={this.props.submitting}
                  type="submit"
                >
                  {this.props.isLoading ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                      Menyimpan
                    </>
                  ) : (
                    "Simpan Data"
                  )}
                </button>
              </div>
            </div>
            {this.state.isLoading ? (
              <Loading
                loading
                background="rgba(0, 0, 0, 0.35)"
                loaderColor="rgba(94, 147, 117, 1)"
              />
            ) : null}
          </div>
        </div>
      </form>
    );
  }
}

ModalTambahTransaksi = reduxForm({
  form: "ModalTambahTransaksi",
  enableReinitialize: true,
})(ModalTambahTransaksi);

const selctor = formValueSelector("ModalTambahTransaksi");
export default connect((state) => {
  return {
    initialValues: {
      dari_tanggal: getTglSystem(),
      sampai_tanggal: getTglSystem(),
      transaksi: "PENJUALAN",
      jenis: "KASUS",
    },
    kode_member: selctor(state, "kode_member"),
    getMasterToko: state.datamaster.getMasterToko,
    GetDataGroup: state.datamaster.GetDataGroup,
    getDataJenis: state.datamaster.getDataJenis,
    datakondisi: state.datamaster.getDataKondisiBarang,
    setHancurBarang: state.databarang.setHancurBarang,
  };
}, null)(ModalTambahTransaksi);
