import { SHOW_MODAL_BATAL_PEMBELIAN } from "../actions/pembelian_action";

// showModalBatalBeli
const initialState = {
    showModalBatalBeli : [],
}

const pembelian = (state = initialState, actions) => {
    switch (actions.type) {
        case SHOW_MODAL_BATAL_PEMBELIAN:
            return {
              ...state,
              showModalBatalBeli: actions.payload.data,
            }
            default:
              return state;
          }
}

export default pembelian;