import { SET_BARANG_PEMBELIAN, SET_BARANG_PENJUALAN, SET_BARANG_TUKAR, SET_BATAL_PEMBELIAN, SET_BATAL_PENJUALAN, SET_NO_FAKTUR_PENJUALAN, SET_OTORISASI, SHOW_MODAL_PEMBAYARAN, SHOW_MODAL_PENJUALAN_DATA_CUSTOMER, SHOW_MODAL_TAMBAH_BARANG } from "../actions/penjualan_action";

const initialState = {
    ShowModalDataPenjualanCustomer: false,
    ShowModalTambahBarang: false,
    setBarangPenjualan:[],
    setBarangPembelian:[],
    showModalPembayaran:[],
    setBatalPenjualan:[],
    setNoFakturPenjualan:[],
  setBatalPembelian: [],
  setBarangTukar: [],
  setOtorisasi : false
}

const penjualan = (state = initialState, actions) => {
    switch (actions.type) {
        case SHOW_MODAL_PENJUALAN_DATA_CUSTOMER:
            return {
              ...state,
              ShowModalDataPenjualanCustomer: actions.payload.data,
            }
        case SET_OTORISASI:
            return {
              ...state,
              setOtorisasi: actions.payload.data,
            }
        case SHOW_MODAL_TAMBAH_BARANG:
            return {
              ...state,
              ShowModalTambahBarang: actions.payload.data,
            }
        case SET_BARANG_PENJUALAN:
            return {
              ...state,
              setBarangPenjualan: actions.payload.data,
            }
        case SET_BARANG_PEMBELIAN:
            return {
              ...state,
              setBarangPembelian: actions.payload.data,
            }
        case SHOW_MODAL_PEMBAYARAN:
            return {
              ...state,
              showModalPembayaran: actions.payload.data,
            }
        case SET_BATAL_PENJUALAN:
            return {
              ...state,
              setBatalPenjualan: actions.payload.data,
            }
        case SET_BATAL_PEMBELIAN:
            return {
              ...state,
              setBatalPembelian: actions.payload.data,
            }
        case SET_NO_FAKTUR_PENJUALAN:
            return {
              ...state,
              setNoFakturPenjualan: actions.payload.data,
            }
        case SET_BARANG_TUKAR:
          return{
            ...state,
            setBarangTukar :actions.payload.data
          }
            default:
              return state;
          }
}

export default penjualan;