const ValidasiPenjualan = (value) => {
    const errors = {};
    if (!value.kode_sales) {
        errors.kode_sales = "Kode Sales Harus Dipilih";
    }
    if (!value.kondisi) {
        errors.kondisi = "Silahkan Pilih Kondisi";
    }
  

    return errors;
}

export default ValidasiPenjualan;


