import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings";
import background from "../../assets/img/background.jpg";
import { Card, CardBody } from "reactstrap";
import memberIcon from "../../assets/img/member.gif";
import lihatIcon from "../../assets/img/validasi.gif";
import { searchAkses } from "../../components/helpers/function";

const activeBg = background;
const SubMenuMember = () => {
  const type = useContext(PageSettings);
  const history = useHistory();
  //   const [dateSystem, setdateSystem] = useState(true);

  const routeChange = path => {
    history.push(path);
  };
  useEffect(() => {
    type.handleSetPageSidebar(false);
    type.handleSetPageHeader(false);
    // setdateSystem((getUserdata()?.tgl_system || "-") === getToday());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{ backgroundImage: "url(" + activeBg + ")" }}
        />
      </div>
      <div
        className="login"
        style={{
          minWidth: "80%",
          minHeight: "calc(100vh - 40px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="row col-lg-12 justify-content-center">
          <div className="row col-lg-12 justify-content-center">
            <div
              className={
                searchAkses("title", "Data Member")
                  ? "col-lg-3 col-md-3"
                  : "col-lg-3 col-md-3"
              }
              onClick={() => {
                routeChange("/data-member");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={memberIcon}
                    alt="this slowpoke moves"
                    width="150"
                    className="img-fluid"
                  />
                  <h3>
                    <strong>
                      Data
                      <br />
                      Member
                    </strong>
                  </h3>
                </CardBody>
              </Card>
            </div>
            <div
              className={
                searchAkses("title", "Lihat Transaksi Member")
                  ? "col-lg-3 col-md-3"
                  : "col-lg-3 col-md-3 d-none"
              }
              onClick={() => {
                routeChange("/lihat-transaksi-member");
              }}
            >
              <Card
                style={{
                  borderRadius: 30,
                  backgroundColor: "#619680 !important"
                }}
                className="border-0 card-inverse bg-primary text-white text-center m-b-10 shadow"
              >
                <CardBody>
                  <img
                    src={lihatIcon}
                    alt="this slowpoke moves"
                    width="150"
                    className="img-fluid"
                  />
                  <h3>
                    <strong>Lihat Transaksi Member</strong>
                  </h3>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="col-lg-12 justify-content-center row">
            <div className="col-lg-4" />
            <div
              className="col-lg-3 col-md-3"
              onClick={() => routeChange("/home")}
            >
              <Card
                className="border-0 card-inverse bg-danger text-white text-center m-b-10 shadow"
                style={{ borderRadius: 30 }}
              >
                <CardBody>
                  <h2>
                    <strong>BACK</strong>
                  </h2>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubMenuMember;
