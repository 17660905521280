import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import LaporanKonversiBarangPdf from "./pdf/LaporanKonversiBarangPdf";
import LaporanKonversiBarangExel from "./exel/LaporanKonversiBarangExel";
import {
  ToastNotification,
  getDataNoTimeOut2,
  InputDate,
  getTglSystem,
  BgLoading,
  Field,
  reduxForm,
  connect,
  React,
  Component,
  Tabel,
} from "../../../../components/helpers/library";

class HeadLaporanKonversiBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      no_kirim: [],
      tgl_awal: new Date(),
      tgl_akhir: new Date(),
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    LaporanKonversiBarangPdf(this.props.LaporanDataBarang);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
    setTimeout(() => {
      this.getNoKirim("tgl");
    }, 300);
    this.setState({
      no_kirim: [],
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
    setTimeout(() => {
      this.getNoKirim("tgl");
    }, 300);
    this.setState({
      no_kirim: [],
    });
  }

  componentDidMount() {
    this.props.change("tgl_akhir", getTglSystem());
    this.props.change("tgl_awal", getTglSystem());
  }
  reprint(row) {
    getDataNoTimeOut2("krmbrgbos/reprint?no_kirim_bos=" + row.no_kirim)
      .then((res) => {
        this.print(res.data);
      })
      .catch((err) => {
        console.log(err);
        ToastNotification(
          "info",
          "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!"
        );
      });
  }

  render() {
    const columns = [
      {
        dataField: "kode_barcode",
        text: "Barcode",
        footerAttrs: {
          colSpan: "6",
        },
        footer: `Total : ${this.props.LaporanDataBarang.length}`,
      },
      {
        dataField: "nama_barang",
        text: "Nama Barang",
      },

      {
        dataField: "kategori_asal",
        text: "Kategori Asal",
      },
      {
        dataField: "kategori_tujuan",
        text: "Kategori Tujuan",
      },
      {
        dataField: "jenis_asal",
        text: "Jenis Asal",
      },
      {
        dataField: "jenis_tujuan",
        text: "Jenis Tujuan",
      },
    ];

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-12 row mt-2 justify-content-end mb-3">
            <div className="col-lg-3">
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="kode_barcode"
              data={this.props.LaporanDataBarang || []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Konversi Barang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanKonversiBarangExel
                  data={this.props.LaporanDataBarang}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading && <BgLoading />}
      </form>
    );
  }
}

HeadLaporanKonversiBarang = reduxForm({
  form: "HeadLaporanKonversiBarang",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadLaporanKonversiBarang);
export default connect((state) => {
  return {
    dataToko: state.datamaster.getMasterToko,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadLaporanKonversiBarang);
