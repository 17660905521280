import React from "react";
import { Link } from "react-router-dom";
import {
  formatDate,
  localStorageencryp,
} from "../../../../components/helpers/function.jsx";
import { ToastNotification } from "../../../../components/helpers/notification.jsx";
import { Panel, PanelHeader } from "../../../../components/panel/panel.jsx";
import { postDataNoTImeOut } from "../../../../config/axios.jsx";
import HeadFormLaporanTukarPoint from "./HeadFormLaporanTukarPoint.jsx";
import * as moment from "moment-timezone";

class FormLaporanTukarPoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      datamember: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      // kode_member: data.kode_member,
      type: data.type,
      kategori: "TUKAR POIN",
      kode_toko : "ALL"
    };
    // postDataNoTImeOut("trxmember/get/by-tanggal-kategori", data_post) -> endPoint Old
    postDataNoTImeOut("/trxmember/report", data_post)
      .then((res) => {
        console.log(res.data);
        this.setState({
          export: false,
          datamember: [],
          isLoading: false,
        });
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
          
        } else {
          ToastNotification("success", "Laporan Data Member Tersedia");

          localStorageencryp("tgl_laporan", JSON.stringify(data_post));
          console.log(res.data);
          this.setState({
            export: true,
            datamember: res.data,

            isLoading: false,
          });
        }
      })
      .catch((err) => {
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        this.setState({
          export: false,
          datamember: [],
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Tukar Point Member</li>
        </ol>
        <h1 className="page-header">Laporan Tukar Point Member </h1>
        <Panel>
          <PanelHeader>Laporan Tukar Point Member</PanelHeader>
          <br />
          {/* Laporan Member */}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <HeadFormLaporanTukarPoint
                  isLoading={this.state.isLoading}
                  export={this.state.export}
                  datamember={this.state.datamember}
                  onSubmit={(data) => this.handleSubmit(data)}
                />
              </div>
            </div>
          </div>
          <br />
          {/* End Tambah Laporan Member  */}
        </Panel>
      </div>
    );
  }
}

export default FormLaporanTukarPoint;
