import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getDataHadiah } from "../../../actions/datamaster_action";
import {
  HiidenFiled,
  ReanderField,
  ReanderSelect,
} from "../../../components/helpers/field";
import { ToastNotification } from "../../../components/helpers/notification";
import ValidasiNamaHadiah from "../../../Validasi/hadiah/ValidasiHadiah";
import { NumberOnly } from "../../../Validasi/normalize";

const maptostate = (state) => {
  // let data_ambil_hadiah =
  //   localStoragedecryp("data_ambil_hadiah_tmp").length === 0
  //     ? []
  //     : JSON.parse(localStoragedecryp("data_ambil_hadiah_tmp") || []);
  return {
    datahadiah: state.datamaster.getDataHadiah,
  };
};

class FilterHadiah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataHadiah());
  }

  getValidasiStock(params) {
    this.setState({ isLoadingJenis: true });

    let stockHadiah = document.getElementById("stockHadiah").value;
    console.log(params);
    console.log(stockHadiah);
    if (Number(stockHadiah) < Number(params)) {
      this.setState({
        status: true,
      });
      ToastNotification("info", "Stock Hadiah Kurang Dari Yang Di Input");
    } else {
      this.setState({
        status: false,
      });
    }
  }
  findNamaHadiah(e){
    let kode = e.split('~')
    let data = this.props.datahadiah.find((list)=>list.kode_hadiah === kode[0])
    this.props.change("stockHadiah", data?.stock)
  }
  render() {
    return (
      <>
        <form
          onSubmit={this.props.handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-4">
              <Field
                id="namaHadiah"
                name="namaHadiah"
                label="Nama Hadiah"
                placeholder="Caria Hadiah"
                component={ReanderSelect}
                onChange={(e) => this.findNamaHadiah(e)}
                options={this.props.datahadiah.map((list) => {
                  let data = {
                    value: list.kode_hadiah +'~'+list.nama_hadiah,
                    name: list.nama_hadiah,
                  };
                  return data;
                })}
              />
            </div>
            <Field
              id="stockHadiah"
              name="stockHadiah"
              label="Nama Hadiah"
              type="hidden"
              placeholder="Caria Hadiah"
              component={HiidenFiled}
            />
            <div className="col-4">
              <Field
                id="inputAlasanAmbil"
                name="inputAlasanAmbil"
                label="Alasan Ambil"
                placeholder="Alasan Ambil"
                component={ReanderField}
              />
            </div>
            <div className="col-4">
              <Field
                id="qtyAmbil"
                name="qtyAmbil"
                label="Jumlah ambil"
                type="telp"
                normalize={NumberOnly}
                onChange={(e) => this.getValidasiStock(e.target.value)}
                placeholder="Jumlah Ambil"
                component={ReanderField}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <label>&nbsp;</label>
              <button
                className="btn btn-primary btn-block"
                disabled={
                  this.props.submitting ||
                  this.props.isLoading ||
                  this.state.status
                }
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

FilterHadiah = reduxForm({
  form: "FilterHadiah",
  enableReinitialize: true,
  validate: ValidasiNamaHadiah,
})(FilterHadiah);

export default connect(maptostate)(FilterHadiah);
