import {
  GET_HADIAH_KATALOG,
  SET_DATA_HADIAH_KATALOG,
} from "./hadiahKatalogAction";

const initialState = {
  hadiahKatalog: [
    {
      kode_hadiah: "H00001",
      nama_hadiah: "Kompor Gas",
      info: "Berlaku Sampai",
      sub_info: "20 Desember 2022",
      point: "130",
      deskripsi:
        "Cek promo yang sedang berlangsung dan transaksi di Toko Emas Kresno untuk mendapatkan Poin Hadiah.",
      gambar_path:
        "https://firebasestorage.googleapis.com/v0/b/gambar-78b2b.appspot.com/o/KRESNOAPP%2FHADIAH%2FCreVedmKwE401vaq.jpg?alt=media&token=fe556c05-cf97-4521-b261-bcda625a482c",
      gambar_name: "CreVedmKwE401vaq",
    },
  ],
  ShowModal: null,
};

const stateHadiahKatalog = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_HADIAH_KATALOG:
      return {
        ...state,
        hadiahKatalog: actions.payload.data,
      };
    case SET_DATA_HADIAH_KATALOG:
      return {
        ...state,
        ShowModal: actions.payload,
      };
    default:
      return state;
  }
};
export default stateHadiahKatalog;
