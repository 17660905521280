import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getDataHadiah } from "../../../actions/datamaster_action";
import {
  HiidenFiled,
  ReanderField,
  ReanderSelect,
} from "../../../components/helpers/field";
import {currencyMask} from "../../../components/helpers/library";
// import { ToastNotification } from "../../../components/helpers/notification";
import ValidasiNamaBeliHadiah from "../../../Validasi/hadiah/ValidasiBeliHadiah";
import { NumberOnly } from "../../../Validasi/normalize";

const maptostate = (state) => {
  
  return {
    datahadiah: state.datamaster.getDataHadiah,
  };
};

class FilterBeliHadiah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false, 
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataHadiah());
  }

  getValidasiStock(params) {
    this.setState({ isLoadingJenis: true });

    let stockHadiah = document.getElementById("stockHadiah").value;
    console.log(params);
    console.log(stockHadiah);
    this.setState({
      status: false,
    });
   
  }

  findNamaHadiah(e) {
    let kode = e.split("~");
    let data = this.props.datahadiah.find((list) => list.kode_hadiah === kode[0]);
    this.props.change("stockHadiah", data?.stock);
  }

  render() {
    return (
      <>
        <form
          onSubmit={this.props.handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-4">
              <Field
                id="namaHadiahBeli"
                name="namaHadiahBeli"
                label="Nama Hadiah"
                placeholder="Caria Hadiah"
                component={ReanderSelect}
                onChange={(e) => this.findNamaHadiah(e)}
                options={this.props.datahadiah.map((list) => {
                  let data = {
                    value: list.kode_hadiah + "~" + list.nama_hadiah,
                    name: list.nama_hadiah,
                  };
                  return data;
                })}
              />
            </div>
            <Field
              id="stockHadiah"
              name="stockHadiah"
              label="Nama Hadiah"
              type="hidden"
              placeholder="Caria Hadiah"
              component={HiidenFiled}
            />
            <div className="col-4">
              <Field
                id="qtyBeli"
                name="qtyBeli"
                label="Jumlah Beli"
                type="telp"
                normalize={NumberOnly}
                onChange={(e) => this.getValidasiStock(e.target.value)}
                placeholder="Jumlah Beli"
                component={ReanderField}
              />
            </div>
            <div className="col-4">
              <Field
                id="totHargaHadiah"
                name="totHargaHadiah"
                label="Total Harga"
                type="telp"
                normalize={NumberOnly}
                // onChange={(e) => this.getValidasiStock(e.target.value)}
                placeholder="Total Harga"
                {...currencyMask}
                component={ReanderField}
              />
            </div>
            {/* <div className="col-4">
              <label>&nbsp;</label>
              <button
                className="btn btn-primary btn-block"
                disabled={
                  this.props.submitting ||
                  this.props.isLoading ||
                  this.state.status
                }
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div> */}
          </div>
          <div className="row">
            <div className="col-4">
              <label>&nbsp;</label>
              <button
                className="btn btn-primary btn-block"
                disabled={
                  this.props.submitting ||
                  this.props.isLoading ||
                  this.state.status
                }
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

FilterBeliHadiah = reduxForm({
  form: "FilterBeliHadiah",
  enableReinitialize: true,
  validate: ValidasiNamaBeliHadiah,
})(FilterBeliHadiah);

export default connect(maptostate)(FilterBeliHadiah);
