import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatGram, getItem, getToday, getUserdata, localStoragedecryp } from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanStockPembelian = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = []
  let finalY = 30;
 


  doc.setFontSize(15);
  doc.text("Laporan Stock Pembelian", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0,48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48,90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "Laporan Stock Pembelian",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
          " s/d " +
          JSON.parse(localStoragedecryp("tgl_laporan")).tgl_akhir
    }`,
    14,
    25
  );
  let total_berat = 0;
  let total_berat_nota = 0;
  let total_harga_nota = 0;
  let total_harga = 0;
  let total_harga_rata = 0;
  let total_barang = 0;
  tableColumn = [
    // [
    //   {
    //     content: `No Faktur Group : ${item._id.no_faktur_group} \n${header_detail.jenis}  (${parseInt(header_detail.jumlah_rp).toLocaleString("kr-KO")})`,
    //     colSpan: 13,
    //   },
    // ],
    [
      {
        content: `NO BELI`,
      },
      {
        content: `KODE SALES`,
      },
      {
        content: `KODE BARCODE`,
      },
     
      {
        content: `NAMA BARANG`,
      },
      {
        content: `KONDISI`,
      },
     
      {
        content: `BERAT`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `HARGA`,
        styles: {
          halign: 'right',
        },
      },
     
      {
        content: `HARGA RATA`,
        styles: {
          halign: 'right',
        },
      },
    
    ],
    ];
  data.forEach((item, index) => {
    let tot_berat = 0;
    let tot_berat_nota = 0;
    let tot_harga_nota = 0;
    let tot_harga = 0;
    let tot_harga_rata = 0;
  
    let pembayaran = [
      {
        content: `${getItem('tgl_laporan').group_by === "KODE_GROUP" ? "KATEGORI" : "KONDISI" } :  ${item._id}`,
        colSpan: 13,
      },
    ]
    tableRows.push(pembayaran)
      
    item.detail.forEach((item, index) => {
      let rows = [
        item.no_faktur_beli,
        item.kode_sales,
        item.kode_barcode,
        item.nama_barang,
        item.kondisi,
       
        {
          content: formatGram(item.berat, 3),
          styles: {
            halign: 'right',
    
          },
        },
      
        {
          content: parseInt(item.harga).toLocaleString("kr-KO"),
          styles: {
            halign: 'right',
    
          },
        },
        {
          content:Number((parseInt(item.harga) / parseFloat(item.berat)).toFixed(0)).toLocaleString("kr-KO"),
          styles: {
            halign: 'right',
          },
        },
      ];
      tot_berat = tot_berat + parseFloat(item.berat);
      tot_berat_nota = tot_berat_nota + parseFloat(item.berat_nota);
      tot_harga_nota = tot_harga_nota + parseFloat(item.harga_nota);
      tot_harga = tot_harga + parseFloat(item.harga);
      tot_harga_rata = tot_harga_rata + parseFloat(item.harga_rata);
      
      total_berat = total_berat + parseFloat(item.berat);
      total_berat_nota = total_berat_nota + parseFloat(item.berat_nota);
      total_harga_nota = total_harga_nota + parseFloat(item.harga_nota);
      total_harga = total_harga + parseFloat(item.harga);
      total_harga_rata = total_harga_rata + parseFloat(item.harga_rata);
     
      tableRows.push(rows);
    });
    total_barang = total_barang + item.detail.length;
    let total = [
      {
        content: `Subtotal : ${item.detail.length} Transaksi`,
        colSpan: 5,
        styles: {
          halign: 'center',
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
     
      {
        content:`${tot_berat.toFixed(3)}`,
        styles: {
          halign: 'right',
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      
      {
        content:`${parseInt(tot_harga).toLocaleString("kr-KO")}`,
        styles: {
          halign: 'right',
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content:``,
        styles: {
          halign: 'right',
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
     
    ];
    tableRows.push(total);

  });
  let grand = [
    {
      content: `Grand Total : ${total_barang} Transaksi`,
      colSpan: 5,
      styles: {
        halign: 'center',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
   
    {
      content:`${total_berat.toFixed(3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: 'right',

      },
    },
  
    {
      content:`${parseInt(total_harga).toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: 'right',

      },
    },
    {
      content:``,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: 'right',

      },
    },
   
  ];
  tableRows.push(grand);
  let printed = [
    {
      colSpan:9,
      content: `Printed By ${JSON.parse(localStoragedecryp("userdata")).user_id} / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ]
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY:  finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
 
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  footRows = [];

  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(`User                           : ${JSON.parse(localStoragedecryp("userdata")).user_id}`, 14, finalY + 10);
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`Laporan Stock Pembelian.pdf`);
  var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Laporan Stock Pembelian</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanStockPembelian;
