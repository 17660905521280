import {
    React,
    connect,
    Link,
    reduxForm,
    Panel,
    PanelHeader,
    getDataNoTimeOut,
    formatDate,
    moment,
    ToastNotification,
    PanelBody,
    localStorageencryp
  } from "../../../../components/helpers/library";
  import HeadLaporanJatuhTempo from "./HeadLaporanJatuhTempo";
  
  class LaporanHutangJatuhTempo extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        LaporanHutangJatuhTempo: [],
        export: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit(data) {
      let data_post = {
        tgl_awal: formatDate(
          moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
        ),
        tgl_akhir: formatDate(
          moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
        ),
        kode_sales : data.kode_sales
      };
      this.setState({
        isLoading: true,
      });
  
      getDataNoTimeOut(
        "hutang/report-jatuh-tempo?tgl_from=" +
          data_post.tgl_awal +
          "&tgl_to=" +
          data_post.tgl_akhir +
          "&kode_sales="+data_post.kode_sales
      )
        .then((res) => {
          // console.log(res);
          localStorageencryp("tgl_laporan", JSON.stringify(data_post));
          if (res.data.length === 0) {
            ToastNotification("info", "Laporan Stock Hutang Kosong");
            this.setState({
              export: false,
              isLoading: false,
            });
            return false;
          }
          ToastNotification("success", "Laporan Stock Hutang Tersedia");
          this.setState({
            LaporanHutangJatuhTempo: res.data,
            isLoading: false,
            export: true,
          });
        })
        .catch((err) => {
          ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
          this.setState({
            export: false,
            isLoading: false,
          });
        });
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Laporan</Link>
            </li>
            <li className="breadcrumb-item active">Laporan Hutang Jatuh Tempo</li>
          </ol>
          <h1 className="page-header">Laporan Hutang Jatuh Tempo </h1>
          <Panel>
            <PanelHeader>Laporan Hutang Jatuh Tempo</PanelHeader>
            <br />
            {/* Blank Page */}
            <PanelBody>
              <HeadLaporanJatuhTempo
                export={this.state.export}
                isLoading={this.state.isLoading}
                LaporanHutangJatuhTempo={this.state.LaporanHutangJatuhTempo}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            </PanelBody>
            <br />
            {/* End Tambah Blank Page  */}
          </Panel>
        </div>
      );
    }
  }
  
  LaporanHutangJatuhTempo = reduxForm({
    form: "LaporanHutangJatuhTempo",
    enableReinitialize: true,
  })(LaporanHutangJatuhTempo);
  export default connect(null)(LaporanHutangJatuhTempo);
  