const Menu = [
  {
    path: "/dashboard",
    icon: "fa fa-th",
    title: "Dashboard",
  },

  {
    path: "#",
    icon: "fa fa-hdd",
    title: "Data Master",
    children: [
      { path: "/master-group", title: "Master Group" },
      // { path: 'master-kategori', title: 'Master Kategori' },
      { path: "/master-jenis", title: "Master Jenis" },
      { path: "/master-gudang", title: "Master Gudang" },
      { path: "/master-baki", title: "Master Baki" },
      { path: "/master-bandrol", title: "Master Bandrol" },
      { path: "/master-bank", title: "Master Bank" },
      { path: "/master-rekening", title: "Master Rekening" },
      // { path: '#', title: 'Master Pelanggan Non Member' },
      { path: "/master-sales", title: "Master Sales" },
      { path: "/master-marketplace", title: "Master Marketplace" },
      // { path: '/master-tukang', title: 'Master Tukang' },
      { path: "/master-kode-transaksi", title: "Master Kode Transaksi" },
      { path: "/master-kondisi-barang", title: "Master Kondisi Barang" },
      { path: "/master-kondisi-pembelian", title: "Master Kondisi Pembelian" },
      // { path: "/master-kondisi-pinjaman", title: "Master Kondisi Pinjaman" },
      // { path: '/master-kadar', title: 'Master Kadar' },
      { path: "/master-data-hadiah", title: "Master Hadiah" },
      { path: "/master-parameter-point", title: "Parameter Point" },
      { path: "/master-parameter-harga-emas", title: "Parameter Harga Emas" },
      { path: "/master-parameter-rugi-nota", title: "Parameter Rugi Nota" },
      { path: "/master-parameter-kupon", title: "Parameter Kupon" },
    ],
  },

  // {
  //   path: '#', icon: 'fa fa-database', title: 'Manage Data',
  //   children: [
  //     { path: '/import-data-pusat', title: 'Import Data Pusat' },
  //     { path: '/kirim-barang-kecabang', title: 'Kirim Barang Ke Cabang' },
  //   ],
  // },

  {
    path: "#",
    icon: "fa fa-archive",
    title: "Barang",
    children: [
      { path: "/data-barang", title: "Data Barang" },
      { path: "/terima-barang", title: "Terima Barang" },
      { path: "/hancur-data-barang", title: "Hancur Data Barang" },
      { path: "/pindah-barang", title: "Pindah Barang" },
      { path: "/pindah-barang-satu-baki", title: "Pindah Barang Satu Baki" },
      { path: "/kirim-barang-kecabang", title: "Kirim Barang Ke Cabang" },
      { path: "/timbang-baki", title: "Timbang Baki" },
      { path: "/konversi-barang", title: "Konversi Barang" },
    ],
  },

  {
    path: "/backup",
    icon: "fa fa-cut",
    title: "Backup",
  },

  {
    path: "#",
    icon: "fa fa-shopping-cart",
    title: "Penjualan",
    children: [
      { path: "/transaksi-penjualan", title: "Transaksi Penjualan" },
      { path: "/lihat-penjualan", title: "Lihat Penjualan" },
      { path: "/batal-penjualan", title: "Batal Penjualan" },
      { path: "/lihat-titipan", title: "Lihat Titipan" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-desktop",
    title: "Kupon",
    children: [{ path: "/cek-kupon", title: "Cek Kupon" }],
  },
  {
    path: "#",
    icon: "fas fa-gift",
    title: "Hadiah",
    children: [
      { path: "/beli-hadiah", title: "Beli Hadiah" },
      { path: "/ambil-hadiah", title: "Ambil Hadiah" },
      { path: "/laporan-beli-hadiah", title: "Laporan Beli Hadiah" },
      { path: "/laporan-ambil-hadiah", title: "Laporan Ambil Hadiah" },
      { path: "/laporan-mutasi-hadiah", title: "Laporan Mutasi Hadiah" },
    ],
  },
  {
    path: "#",
    icon: "fa fa-boxes",
    title: "Pesanan",
    children: [
      { path: "/transaksi-pesanan", title: "Transaksi Pesanan" },
      { path: "/lihat-pesanan", title: "Lihat Pesanan" },
    ],
  },

  {
    path: "#",
    icon: "fa fa-shopping-bag",
    title: "Pembelian",
    children: [
      { path: "/transaksi-pembelian", title: "Transaksi Pembelian" },
      // { path: '/batal-pembelian', title: 'Batal Pembelian' },
      { path: "/lihat-pembelian", title: "Lihat Pembelian" },
    ],
  },
  {
    path: "#",
    icon: 'ion ion-md-build fa-2x fa-fw pull-left m-r-10 text-black-lighter"',
    title: "Service",
    children: [
      { path: "/transaksi-services", title: "Transaksi Service" },
      { path: "/lihat-services", title: "Lihat Services" },
    ],
  },

  {
    path: "#",
    icon: "fa fa-hand-holding-usd",
    title: "Hutang",
    children: [
      { path: "/transaksi-hutang", title: "Transaksi Hutang" },
      { path: "/lihat-hutang", title: "Lihat Hutang" },
      { path: "/batal-hutang-lunas", title: "Batal Hutang Lunas" },
    ],
  },
  //  {
  //     path: '#', icon: 'fa fa-cubes', title: 'Cucian',
  //     children: [
  //       { path: '#', title: 'Pindah Sortir' },
  //     ]
  //   },
  // {
  //   path: '#', icon: 'fa fa-credit-card', title: 'Pinjaman',
  //   children: [
  //     { path: '#', title: 'Transaksi Pinjaman' },
  //     { path: '#', title: 'Batal Pinjaman' },
  //     { path: '#', title: 'Bayar Bunga' },
  //     { path: '#', title: 'Pelunasan Pinjaman' },
  //   ]
  // },
  // {
  //   path: '#', icon: 'fas fa-fw m-r-10 fa-copy', title: 'Pesanan',
  //   children: [
  //     { path: '#', title: 'Tambah Bahan' },
  //     { path: '#', title: 'Ambil Bahan' },
  //     { path: '#', title: 'Pembagian Pesanan' },
  //     { path: '#', title: 'Pesanan Selesai' },
  //     { path: '#', title: 'Stock Opname Pesanan' },
  //     { path: '#', title: 'Transaksi Pesanan' },
  //     { path: '#', title: 'Tambah Dp' },
  //     { path: '#', title: 'Pesanan Serah' },
  //   ]
  // },
  {
    path: "#",
    icon: "fas fa-money-bill-alt",
    title: "Uang Kas",
    children: [
      // { path: '#', title: 'Pesanan Serah' },
      // { path: "/ambil-uang-bank", title: "Ambil Uang Bank" },
      // { path: "/tambah-uang-bank", title: "Tambah Uang Bank" },
      { path: "/tambah-uang-kas", title: "Tambah Uang Kas" },
      { path: "/ambil-uang-kas", title: "Ambil Uang Kas" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-desktop",
    title: "Validasi",
    children: [
      { path: "/validasi-jual-beli", title: "Validasi" },
      { path: "/validasi-hutang", title: "Validasi Hutang" },
      // { path: '/validasi-titipan', title: 'Validasi Titipan' },
    ],
  },
  // {
  //   path: '#', icon: 'fas fa-window-restore', title: 'Stock Opname',
  //   children: [
  //     { path: '#', title: 'Stock Opname' },
  //     { path: '#', title: 'Laporan Hasil Stock Opname' },
  //     { path: '#', title: 'Laporan Barang Tidak Ketemu' },
  //     { path: '#', title: 'Laporan Barang Selisih' },
  //   ]
  // },
  {
    path: "#",
    icon: "fas fa-folder-open",
    title: "Laporan",
    children: [
      {
        path: "#",
        title: "Laporan Barang",
        children: [
          { path: "/laporan-kirim-barang", title: "Laporan Kirim Barang" },
          { path: "/laporan-barang-detail", title: "Laporan Barang Detail" },
          { path: "/laporan-tambah-barang", title: "Laporan Tambah Barang" },
          { path: "/laporan-timbang-baki", title: "Laporan Timbang Baki" },
          // { path: "/laporan-terima-barang", title: "Laporan Terima Barang" },
          { path: "/laporan-hancur-barang", title: "Laporan Hancur Barang" },
          { path: "/laporan-pindah-barang", title: "Laporan Pindah Barang" },
          { path: "/laporan-barang-sumary", title: "Laporan Barang Summary" },
          {
            path: "/laporan-konversi-barang",
            title: "Laporan Konversi Barang",
          },
          // {
          //   path: '#', title: 'Laporan Terima Barang',
          //   children: [
          //     { path: '/laporan-terima-barang', title: 'Laporan Terima Barang', },
          //     { path: '/laporan-barang-detail', title: 'Laporan Barang Detail', }
          //   ]
          // },
        ],
      },
      {
        path: "#",
        title: "Laporan Pesanan",
        children: [
          {
            path: "/laporan-pesanan",
            title: "Laporan Pesanan",
          },
          {
            path: "/laporan-pesanan-serah",
            title: "Laporan Pesanan Serah",
          },
          {
            path: "/laporan-batal-pesanan",
            title: "Laporan Batal Pesanan",
          },
          // {
          //   path: '#', title: 'Laporan Terima Barang',
          //   children: [
          //     { path: '/laporan-terima-barang', title: 'Laporan Terima Barang', },
          //     { path: '/laporan-barang-detail', title: 'Laporan Barang Detail', }
          //   ]
          // },
        ],
      },
      {
        path: "#",
        title: "Laporan Penjualan",
        children: [
          // { path: '#', title: 'Laporan Stock Penjualan' },
          { path: "/laporan-titip-uang", title: "Laporan Titip Uang" },
          {
            path: "/laporan-stock-jual",
            title: "Laporan Stock Jual",
          },
          {
            path: "/laporan-transaksi-penjualan",
            title: "Laporan Transaksi Penjualan",
          },
          {
            path: "/laporan-penjualan-marketplace",
            title: "Laporan Penjualan Marketplace",
          },
          {
            path: "/laporan-barang-tukar",
            title: "Laporan Barang Tukar",
          },
          {
            path: "/laporan-batal-penjualan",
            title: "Laporan Batal Penjualan",
          },
          // { path: '/laporan-penjualan-bayar', title: 'Laporan Penjualan Bayar' },
          {
            path: "/laporan-penjualan-sales",
            title: "Laporan Penjualan Sales",
          },
          // { path: '#', title: 'Laporan Pindah Barang' },
          // { path: '#', title: 'Laporan Selisih Berat Penjualan' },
          // { path: '#', title: 'Laporan Rekap Penjualan (Bulanan)' },
          // { path: '#', title: 'Laporan Transaksi Penjualan (Truncate)' },
        ],
      },
      {
        path: "#",
        title: "Laporan Pembelian",
        children: [
          {
            path: "/laporan-stock-pembelian",
            title: "Laporan Stock Pembelian",
          },
          {
            path: "/laporan-transaksi-pembelian",
            title: "Laporan Transaksi Pembelian",
          },
          {
            path: "/laporan-transaksi-pembelian-history",
            title: "Laporan History Transaksi Pembelian",
          },
          {
            path: "/laporan-batal-pembelian",
            title: "Laporan Batal Pembelian",
          },
          {
            path: "/laporan-pembelian-sales",
            title: "Laporan Pembelian Sales",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Service",
        children: [
          { path: "/laporan-services", title: "Laporan Transaksi Service" },
          { path: "/laporan-batal-service", title: "Laporan Batal Service" },
          { path: "/laporan-service-masuk", title: "Laporan Service (Masuk)" },
          {
            path: "/laporan-service-selesai",
            title: "Laporan Service (Selesai)",
          },
          { path: "/laporan-service-ambil", title: "Laporan Service (Ambil)" },
        ],
      },

      {
        path: "#",
        title: "Laporan Hutang",
        children: [
          {
            path: "/laporan-transaksi-hutang",
            title: "Laporan Transaksi Hutang",
          },
          {
            path: "/laporan-hutang-jatuh-tempo",
            title: "Laporan Hutang Jatuh Tempo",
          },
          { path: "/laporan-stock-hutang", title: "Laporan Stock Hutang" },
          { path: "/laporan-hutang-lunas", title: "Laporan Hutang Lunas" },
          { path: "/laporan-batal-hutang", title: "Laporan Batal Hutang" },
          {
            path: "/laporan-transaksi-hutang-persales",
            title: "Laporan Hutang Persales",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Keuangan",
        children: [
          {
            path: "/laporan-transaksi-keuangan-cash",
            title: "Laporan Transaksi Keuangan Cash",
          },
          {
            path: "/laporan-keuangan-non-cash",
            title: "Laporan Keuangan Non Cash",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Member",
        children: [
          { path: "/laporan-daftar-member", title: "Laporan Daftar Member" },
          {
            path: "/laporan-tukar-point-member",
            title: "Laporan Tukar Point Member",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Stock Opname",
        children: [
          { path: "/laporan-hasil-so", title: "Laporan Hasil SO" },
          // {
          //   path: "/laporan-Barang-tidak-ketemu",
          //   title: "Laporan Barang Tidak Ketemu",
          // },
          // {
          //   path: "/laporan-barang-lebih",
          //   title: "Laporan Barang Lebih",
          // },
        ],
      },
      // {
      //   path: "#",
      //   title: "Laporan Managerial Analysis",
      //   children: [
      //     { path: "#", title: "Laporan Margin Per faktur" },
      //     { path: "#", title: "Laporan Penjualan Di Bawah Harga Beli" },
      //   ],
      // },
      {
        path: "/laporan-rekap-perbulan",
        title: "Laporan Rekap Perbulan",
      },

      // {
      //   path: '#', title: 'Laporan Sepuhan',
      //   children: [
      //     { path: '#', title: 'Laporan Sortir Pembelian' },
      //     { path: '#', title: 'Laporan Sortir Sepuh' },
      //     { path: '#', title: 'Laporan Kirim Sepuh Tukang' },
      //     { path: '#', title: 'Laporan Kirim Barang Lebeling' },
      //     { path: '#', title: 'Laporan Kirim Barang Dari Bos' },
      //     { path: '#', title: 'Laporan Kirim Sepuh Dari Bos' },
      //     { path: '#', title: 'Laporan Barang Dalam' },
      //   ]
      // },
      // {
      //   path: '#', title: 'Laporan Summary Tutup Toko',
      // },
      {
        path: "/laporan-otorisasi",
        title: "Laporan Otorisasi",
      },
      {
        path: "/laporan-transaksi-rekap-sales-daily",
        title: "Laporan Transaksi Rekap Sales Daily",
      },
      {
        path: "/laporan-eod",
        title: "Laporan EOD",
      },
      {
        path: "/laporan-history-cetak-barcode",
        title: "Laporan History Cetak Barcode",
      },
      // {
      //   path: "/laporan-authorization",
      //   title: "Laporan Otorisasi",
      // },
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Manage Users",
    children: [
      { path: "/data-users", title: "Data Users" },
      { path: "/hak-akses-users", title: "Hak Akses Users" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-cog",
    title: "Utility",
    children: [
      // { path: "/settingtoko", title: "Setting Toko" },
      { path: "/settingsystem", title: "Setting System" },
      { path: "/cetak-barcode", title: "Cetak Barcode" },
      // { path: '#', title: 'Hak Akses Users' },
    ],
  },
  {
    path: "/stock-opname",
    icon: "fas fa-business-time",
    title: "Stock Opname",
  },
  // {
  //   path: '#', icon: 'fas fa-dollar-sign', title: 'Harga Emas',
  //   children: [
  //     { path: '#', title: 'Daftar Harga Emas' },
  //     { path: '#', title: 'Parameter Harga Emas' },
  //   ]
  // },
  {
    path: "#",
    icon: "fas fa-id-card",
    title: "Member",
    children: [
      { path: "/data-member", title: "Data Member" },
      { path: "/transaksi-member", title: "Transaksi Member" },
      { path: "/lihat-transaksi-member", title: "Lihat Transaksi Member" },
      { path: "/transaksi-poin", title: "Transaksi Poin" },
      { path: "/tukar-point", title: "Tukar Poin" },
      { path: "/database-member", title: "Database Member" },
    ],
  },
  {
    path: "#",
    icon: "fa fa-mobile",
    title: "Admin Katalog App",
    children: [
      { path: "/kategori-katalog", title: "Kategori Katalog" },
      { path: "/harga-katalog", title: "Harga Katalog" },
      { path: "/banner-katalog", title: "Banner Katalog" },
      { path: "/info-katalog", title: "Info Katalog" },
      { path: "/item-katalog", title: "Barang Katalog" },
      { path: "/kontak-admin-katalog", title: "Kontak Admin Katalog" },
      { path: "/hadiah-katalog", title: "Hadiah Katalog" },
      { path: "/group-description-katalog", title: "Deskripsi Group Katalog" },
      { path: "/splashscreen-katalog", title: "Splashscreen App" },
      { path: "/laporan-aktivasi-member", title: "Laporan Aktivasi Member" },
    ],
  },
  // {
  //   path: '#', icon: 'fas fa-sticky-note', title: 'Sepuhan',
  //   children: [
  //     { path: '#', title: 'Sortir Pembelian' },
  //     { path: '#', title: 'Sortir Sepuh' },
  //     { path: '#', title: 'Kirim Sepuh Ketukang' },
  //     { path: '#', title: 'Terima Sepuh Ketukang' },
  //     { path: '#', title: 'Kirim Barang Ke Lebeling' },
  //     { path: '#', title: 'Kirim Barang Dari Bos' },
  //     { path: '#', title: 'Kirim Sepuh Dari Bos' },
  //     { path: '#', title: 'Validasi Barang Rusak' },
  //     { path: '#', title: 'Reprint Transaksi Sepuh' },

  //   ]
  // },
  // {
  //   path: '#', icon: 'fa fa-print', title: 'Cetak Barcode',
  //   children: [
  //     { path: '#', title: 'Reprint Label Barcode' },
  //     { path: '#', title: 'Reprint Label Satu Baki' },
  //   ]
  // },
  // { path: '#', icon: 'fa fa-clock', title: 'Tutup Toko'}

  //   children: [
  //     { path: '/ui/general', title: 'General', highlight: true },
  //     { path: '/ui/typography', title: 'Typograhy' },
  //     { path: '/ui/tabs-accordion', title: 'Tabs & Accordion' },
  //     { path: '/ui/modal-notification', title: 'Modal & Notification' },
  //     { path: '/ui/widget-boxes', title: 'Widget Boxes' },
  //     { path: '/ui/media-object', title: 'Media Object' },
  //     { path: '/ui/buttons', title: 'Buttons', highlight: true },
  //     { path: '/ui/icons', title: 'Icons' },
  //     { path: '/ui/simple-line-icons', title: 'Simple Line Icons' },
  //     { path: '/ui/ionicons', title: 'Ionicons' },
  //     { path: '/ui/language-bar-icon', title: 'Language Bar & Icon' },
  //     { path: '/ui/social-buttons', title: 'Social Buttons' }
  //   ]
  // },
  // { path: '/bootstrap-4', img: '/assets/img/logo/logo-bs4.png', title: 'Bootstrap 4', label: 'NEW' },
  // { path: '/form', icon: 'fa fa-list-ol', title: 'Form Stuff', label: 'NEW',
  //   children: [
  //     { path: '/form/elements', title: 'Form Elements', highlight: true },
  //     { path: '/form/plugins', title: 'Form Plugins', highlight: true }
  //   ]
  // },
  // { path: '/table', icon: 'fa fa-table', title: 'Tables',
  //   children: [
  //     { path: '/table/basic', title: 'Basic Tables' },
  //     { path: '#', title: 'Data Tables' }
  //   ]
  // },
  // { path: '/frontend', icon: 'fa fa-star', title: 'FrontEnd',
  //   children: [
  //     { path: '/frontend/one-page-parallax', title: 'One Page Parallax' },
  //     { path: '/frontend/blog', title: 'Blog' },
  //     { path: '/frontend/forum', title: 'Forum' },
  //     { path: '/frontend/e-commerce', title: 'E-Commerce' }
  //   ]
  // },
  // { path: '/email-template', icon: 'fa fa-envelope', title: 'Email Template',
  //   children: [
  //     { path: '/email-template/system', title: 'System Template' },
  //     { path: '/email-template/newsletter', title: 'Newsletter Template' }
  //   ]
  // },
  // { path: '/chart', icon: 'fa fa-chart-pie', title: 'Chart', label: 'NEW',
  //   children: [
  //     { path: '/chart/js', title: 'Chart JS' },
  //     { path: '/chart/d3', title: 'd3 Chart' },
  //     { path: '/chart/apex', title: 'Apex Chart', highlight: true }
  //   ]
  // },
  // { path: '/calendar', icon: 'fa fa-calendar', title: 'Calendar' },
  // { path: '/map', icon: 'fa fa-map', title: 'Map' },
  // { path: '/gallery', icon: 'fa fa-image', title: 'Gallery' },
  // { path: '/page-option', icon: 'fa fa-cogs', title: 'Page Options',
  //   children: [
  //     { path: '/page-option/blank', title: 'Blank Page' },
  //     { path: '/page-option/with-footer', title: 'Page with Footer' },
  //     { path: '/page-option/without-sidebar', title: 'Page without Sidebar' },
  //     { path: '/page-option/with-right-sidebar', title: 'Page with Right Sidebar' },
  //     { path: '/page-option/with-minified-sidebar', title: 'Page with Minified Sidebar' },
  //     { path: '/page-option/with-two-sidebar', title: 'Page with Two Sidebar' },
  //     { path: '/page-option/full-height', title: 'Full Height Content' },
  //     { path: '/page-option/with-wide-sidebar', title: 'Page with Wide Sidebar' },
  //     { path: '/page-option/with-light-sidebar', title: 'Page with Light Sidebar' },
  //     { path: '/page-option/with-mega-menu', title: 'Page with Mega Menu' },
  //     { path: '/page-option/with-top-menu', title: 'Page with Top Menu' },
  //     { path: '/page-option/with-boxed-layout', title: 'Page with Boxed Layout' },
  //     { path: '/page-option/with-mixed-menu', title: 'Page with Mixed Menu' },
  //     { path: '/page-option/boxed-layout-with-mixed-menu', title: 'Boxed Layout with Mixed Menu' },
  //     { path: '/page-option/with-transparent-sidebar', title: 'Page with Transparent Sidebar' },
  //   ]
  // },
  // { path: '/extra', icon: 'fa fa-gift', title: 'Extra',
  //   children: [
  //     { path: '/extra/timeline', title: 'Timeline' },
  //     { path: '/extra/coming-soon', title: 'Coming Soon Page' },
  //     { path: '/extra/search', title: 'Search Results' },
  //     { path: '/extra/invoice', title: 'Invoice' },
  //     { path: '/extra/error', title: '404 Error Page' },
  //     { path: '/extra/profile', title: 'Profile Page' },
  //   ]
  // },
  // { path: '/user', icon: 'fa fa-key', title: 'Login & Register',
  //   children: [
  //     { path: '/user/login-v1', title: 'Login' },
  //     { path: '/user/login-v2', title: 'Login v2' },
  //     { path: '/user/login-v3', title: 'Login v3' },
  //     { path: '/user/register-v3', title: 'Register v3' }
  //   ]
  // },
  // { path: '/version', icon: 'fa fa-cubes', title: 'Version', label: 'NEW',
  //   children: [
  //     { path: '/version/html', title: 'HTML' },
  //     { path: '/version/ajax', title: 'AJAX' },
  //     { path: '/version/angularjs', title: 'ANGULAR JS' },
  //     { path: '/version/angularjs8', title: 'ANGULAR JS 8', highlight: true },
  //     { path: '/version/laravel', title: 'LARAVEL', highlight: true },
  //     { path: '/version/material', title: 'MATERIAL DESIGN' },
  //     { path: '/version/apple', title: 'APPLE DESIGN' },
  //     { path: '/version/transparent', title: 'Transparent Design', highlight: true }
  //   ]
  // },
  // { path: '/helper', icon: 'fa fa-medkit', title: 'Helper',
  //   children: [
  //     { path: '/helper/css', title: 'Predefined CSS Classes' }
  //   ]
  // },
  // { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
  //   children: [
  //     { path: '/menu/menu-1-1', title: 'Menu 1.1',
  //       children: [
  //         { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
  //           children: [
  //             { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
  //             { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
  //           ]
  //         },
  //         { path: '#', title: 'Menu 2.2' },
  //         { path: '#', title: 'Menu 2.3' },
  //       ]
  //     },
  //     { path: '/menu/menu-1-2', title: 'Menu 1.2' },
  //     { path: '/menu/menu-1-3', title: 'Menu 1.3' },
  //   ]
  // },
];

export default Menu;
