import { getImage, postData2 } from "../../../config/axios";
import { change } from "redux-form";
import { setCamera } from "../../../actions/databarang_action";
import {
  localStorageencryp,
  pembulatan,
  setItem,
} from "../../../components/helpers/function";
import { ToastNotification } from "../../../components/helpers/notification";
import { hideModal } from "../../../actions/datamaster_action";

export const SET_LOADING = "SET_LOADING";
export const SET_BARANG_PESANAN = "SET_BARANG_PESANAN";

export const setLoading = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
  };
};

export const stopLoading = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  };
};

export const setBarangPesanan = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_BARANG_PESANAN,
      payload: true,
    });
  };
};

export const setBarangNormal = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_BARANG_PESANAN,
      payload: false,
    });
  };
};

export const SetBarangPesanan = (data) => {
  console.log(data);
  return async (dispatch, getState) => {
    dispatch(setLoading());
    try {
      const nopesanan = data.no_pesanan;
      const contohbarcode = data.kode_barcode_contoh;
      const contohgudang = data.kode_gudang_contoh;
      const contohbaki = data.kode_baki_cotoh;
      let response = await postData2("pesanan/ambil/no", {
        no_pesanan: nopesanan.trim().toUpperCase(),
      });
      let hasil = response.data;
      console.log(hasil);
      try {
        let image = await getImage("foto_produk/" + hasil.kode_barcode);
        document.body.style.cursor = "initial";
        dispatch(setCamera(image));
        dispatch(change("ModalTambahBarang", "foto", image));
      } catch (e) {
        document.body.style.cursor = "initial";
        dispatch(setCamera(""));
        dispatch(change("ModalTambahBarang", "foto", ""));
      }
      if (hasil.pesanan[0].detail_barang[0].mengikat) {
        dispatch(
          change("ModalTambahBarang", "kode_barcode", hasil.kode_barcode)
        );
        dispatch(change("ModalTambahBarang", "ongkos", hasil.ongkos));
        dispatch(change("ModalTambahBarang", "kode_group", hasil.kode_group));
        dispatch(change("ModalTambahBarang", "tipe_barang", hasil.tipe_barang));
        dispatch(change("ModalTambahBarang", "nama_barang", hasil.nama_barang));
        dispatch(change("ModalTambahBarang", "contoh_barcode", contohbarcode));
        dispatch(change("ModalTambahBarang", "contoh_gudang", contohgudang));
        dispatch(change("ModalTambahBarang", "contoh_baki", contohbaki));
        dispatch(
          change("ModalTambahBarang", "nama_atribut", hasil.nama_atribut)
        );
        dispatch(
          change("ModalTambahBarang", "berat_atribut", hasil.berat_atribut)
        );
        dispatch(
          change("ModalTambahBarang", "harga_atribut", hasil.harga_atribut)
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "harga_jual_tmp",
            pembulatan(parseInt(hasil.harga_jual))
          )
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "harga_jual",
            pembulatan(parseInt(hasil.harga_jual))
          )
        );
        dispatch(change("ModalTambahBarang", "harga_max", hasil.harga_max));
        dispatch(change("ModalTambahBarang", "harga_min", hasil.harga_min));
        dispatch(
          change("ModalTambahBarang", "harga_jual_asli", hasil.harga_jual)
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "berat",
            hasil.pesanan[0].detail_barang[0].berat_customer
          )
        );
        dispatch(
          change("ModalTambahBarang", "no_pesanan", hasil.pesanan[0].no_pesanan)
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "harga_pergram",
            pembulatan(parseInt(hasil.harga_gram))
          )
        );
        dispatch(change("ModalTambahBarang", "kadar", hasil.kadar));
        dispatch(change("ModalTambahBarang", "harga_skrg", hasil.harga_skrg));
        dispatch(change("ModalTambahBarang", "berat_tmp", hasil.berat));
        document.getElementById("berat").focus();
        let total =
          parseInt(hasil.harga_jual || 0) +
          parseInt(hasil.ongkos || 0) +
          parseInt(hasil.harga_atribut || 0);
        dispatch(
          change("ModalTambahBarang", "total", pembulatan(parseInt(total)))
        );
        let customer = {
          alamat_customer: hasil.pesanan[0].alamat_customer || "-",
          jenis_pelanggan: hasil.pesanan[0].kode_customer || "NONMEMBER",
          kode_customer: hasil.pesanan[0].kode_customer || "NONMEMBER",
          kode_sales: hasil.pesanan[0].kode_sales,
          nama_customer: hasil.pesanan[0].nama_customer || "REGULER",
          no_hp: hasil.pesanan[0].no_hp || "-",
        };
        localStorageencryp("data_customer_tmp", JSON.stringify(customer));
        let total_dp = 0;
        hasil.pesanan[0].pembayaran.forEach((element) => {
          total_dp = total_dp + element.jumlah_rp;
        });

        setItem("total_dp", total_dp);
        dispatch(stopLoading());
      } else {
        dispatch(
          change("ModalTambahBarang", "kode_barcode", hasil.kode_barcode)
        );
        dispatch(change("ModalTambahBarang", "ongkos", hasil.ongkos));
        dispatch(change("ModalTambahBarang", "kode_group", hasil.kode_group));
        dispatch(change("ModalTambahBarang", "tipe_barang", hasil.tipe_barang));
        dispatch(change("ModalTambahBarang", "nama_barang", hasil.nama_barang));
        dispatch(change("ModalTambahBarang", "contoh_barcode", contohbarcode));
        dispatch(change("ModalTambahBarang", "contoh_gudang", contohgudang));
        dispatch(change("ModalTambahBarang", "contoh_baki", contohbaki));
        dispatch(
          change("ModalTambahBarang", "nama_atribut", hasil.nama_atribut)
        );
        dispatch(
          change("ModalTambahBarang", "berat_atribut", hasil.berat_atribut)
        );
        dispatch(
          change("ModalTambahBarang", "harga_atribut", hasil.harga_atribut)
        );
        dispatch(
          change("ModalTambahBarang", "harga_jual_tmp", hasil.harga_jual)
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "harga_jual",
            pembulatan(parseInt(hasil.harga_jual))
          )
        );
        dispatch(change("ModalTambahBarang", "harga_max", hasil.harga_max));
        dispatch(change("ModalTambahBarang", "harga_min", hasil.harga_min));
        dispatch(
          change("ModalTambahBarang", "harga_jual_asli", hasil.harga_jual)
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "berat",
            hasil.pesanan[0].detail_barang[0].berat_customer
          )
        );
        dispatch(
          change("ModalTambahBarang", "no_pesanan", hasil.pesanan[0].no_pesanan)
        );
        dispatch(
          change(
            "ModalTambahBarang",
            "harga_pergram",
            pembulatan(parseInt(hasil.group[0].harga))
          )
        );
        dispatch(change("ModalTambahBarang", "kadar", hasil.kadar));
        dispatch(change("ModalTambahBarang", "harga_skrg", hasil.harga_skrg));
        dispatch(change("ModalTambahBarang", "berat_tmp", hasil.berat));
        document.getElementById("berat").focus();
        let total =
          parseInt(hasil.harga_jual || 0) +
          parseInt(hasil.ongkos || 0) +
          parseInt(hasil.harga_atribut || 0);
        dispatch(
          change("ModalTambahBarang", "total", pembulatan(parseInt(total)))
        );
        let customer = {
          alamat_customer: hasil.pesanan[0].alamat_customer || "-",
          jenis_pelanggan: hasil.pesanan[0].kode_customer || "NONMEMBER",
          kode_customer: hasil.pesanan[0].kode_customer || "NONMEMBER",
          kode_sales: hasil.pesanan[0].kode_sales,
          nama_customer: hasil.pesanan[0].nama_customer || "REGULER",
          no_hp: hasil.pesanan[0].no_hp || "-",
        };
        localStorageencryp("data_customer_tmp", JSON.stringify(customer));
        let total_dp = 0;
        hasil.pesanan[0].pembayaran.forEach((element) => {
          total_dp = total_dp + element.jumlah_rp;
        });

        setItem("total_dp", total_dp);
        dispatch(stopLoading());
      }
    } catch (err) {
      dispatch(hideModal());
      localStorageencryp("data_barang_penjualan_tmp", JSON.stringify([]));
      ToastNotification(
        "info",
        err.response === undefined
          ? "Terjadi Kesalahan Saat Mengirim Data "
          : err.response.data.message
      );
      dispatch(stopLoading());
    }
  };
};
