import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  formatGram,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
class LaporanHutangSalesExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Transaksi Hutang Persales"
          sheet="Laporan Transaksi Hutang Persales"
          buttonText="Export Exel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="4"> Laporan Transaksi Hutang Persales </th>
            </tr>
            <tr>
              <th colSpan="4"> Periode </th>
            </tr>
            <tr>
              <th colSpan="4">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_from +
                    "s/d" +
                    JSON.parse(localStoragedecryp("tgl_laporan")).tgl_to}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="4"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="3"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="3"></th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                }}
              >
                Kode Sales
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                JUMLAH HUTANG
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, index) => (
              <tr key={index}>
                <td>&nbsp;{row.kode_sales}</td>
                <td style={{ textAlign: "right" }}>&nbsp;{row.qty}</td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{formatGram(row.berat, 3)}
                </td>

                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.jumlah_hutang.toLocaleString("kr-KO")}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              ></th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;{this.props.data.reduce((a, b) => a + parseInt(b.qty), 0)}{" "}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat), 0)
                  .toFixed(3)}{" "}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + parseInt(b.jumlah_hutang), 0)
                  .toLocaleString("kr-KO")}{" "}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanHutangSalesExel;
