import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderField } from "../../../components/helpers/field";
// import { ToastNotification } from "../../../components/helpers/notification";
import Tabel from "../../../components/helpers/tabel";
// import { getData } from "../../../config/axios";
import ValidasiBaralJual from "../../../Validasi/Penjualan/ValidasiBaralJual";

import Loading from "react-fullscreen-loading";
import {
  // getItem,
  getTglSystem,
  setItem,
} from "../../../components/helpers/function";

class HeadBatalHutang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloadingFaktur: false,
      isLoading: false,
      isLoadingBatal: false,
      date: new Date(),
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  // getNoHutang(value) {
  //   if (this.delay) {
  //     clearTimeout(this.delay);
  //   }
  //   this.delay = setTimeout(() => {
  //     this.setState({ isloadingFaktur: true });
  //     getData(
  //       "hutang/hutang-lunas?no_faktur_hutang=" +
  //         value.substr(0, 13).toUpperCase()
  //     )
  //       .then((res) => {
  //         if (res.data.length === 0) {
  //           ToastNotification(
  //             "info",
  //             "No Faktur Hutang Yang Anda Cari Tidak Ada"
  //           );
  //           this.setState({
  //             isloadingFaktur: false,
  //           });
  //           return false;
  //         } else {
  //           if (getItem("datahutang_lunas").length === 1) {
  //             ToastNotification("info", "Pembatalan Hanya Boleh Satu Faktur");
  //             this.setState({
  //               isloadingFaktur: false,
  //             });
  //             return false;
  //           }
  //           let data = [];
  //           res.data.forEach((el) => {
  //             el.detail_barang.forEach((element) => {
  //               let datas = {
  //                 no_faktur_hutang: el.no_faktur_hutang,
  //                 berat: element.berat,
  //                 kondisi: element.kondisi,
  //                 kategori: element.kategori,
  //                 nama_barang: element.nama_barang,
  //                 qty: element.qty,
  //               };
  //               data.push(datas);
  //             });
  //           });
  //           // console.log(data)
  //           // this.s
  //           setItem("datahutang_lunas", data);
  //         }
  //         this.setState({
  //           isloadingFaktur: false,
  //         });
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           isloadingFaktur: false,
  //         });
  //         ToastNotification("info", "Kode Barcode Yang Anda Cari Tidak Ada");
  //       });
  //   }, 1500);
  // }
  btlPenjualan() {
    this.setState({
      isLoadingBatal: true,
    });
    setTimeout(() => {
      setItem("datahutang_lunas", []);
      this.setState({
        isLoadingBatal: false,
      });
    }, 2000);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-3">
            <Field
              // onChange={(e) => this.getNoHutang(e.target.value)}
              id="no_hutang"
              name="no_hutang"
              component={ReanderField}
              type="text"
              label="No Hutang"
              placeholder="Masukan No Hutang"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label>&nbsp;</label>
              <button
                className="btn btn-primary btn-block"
                disabled={this.props.isloading}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Hutang Lunas"
                )}
              </button>
            </div>
          </div>

          <div className="col-lg-12">
            <Tabel
              keyField="kode_barcode"
              exportCsv={false}
              data={this.props.data}
              columns={this.props.columns}
              empty={true}
              textEmpty="Data Barang Kosong"
            />
          </div>
          <div className="col-lg-12 text-right">
            {/* <label>&nbsp;</label>
            <button
              type="button"
              onClick={() => this.props.simpanBatal()}
              name="type_btn"
              className="btn btn-primary"
              disabled={this.props.isLoading}
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp;Simpan Batal
                  Hutang
                </>
              ) : (
                "Simpan Batal Hutang"
              )}
            </button>
            &nbsp; */}
            <button
              type="button"
              name="type_btn"
              onClick={() => this.btlPenjualan()}
              className="btn btn-warning"
              disabled={this.state.isLoadingBatal}
              style={{ display: "none" }}
            >
              {this.state.isLoadingBatal ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Batal
                </>
              ) : (
                "Batal"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading || this.state.isloadingFaktur ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadBatalHutang = reduxForm({
  form: "HeadBatalHutang",
  enableReinitialize: true,
  validate: ValidasiBaralJual,
})(HeadBatalHutang);
export default connect((state) => {
  return {
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadBatalHutang);
