import React, { Component } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "actions/datamaster_action";
import { ReanderField } from "components/helpers/field";
import { convertBase64 } from "components/helpers/function";

const maptostate = (state) => {
  if (state.stateKategoriKatalog.ShowModal !== null) {
    return {
      gambar_path: state.stateKategoriKatalog.ShowModal.gambar_path,
      isEdit: true,
      isLoading: state.utility.isLoading,
      initialValues: {
        id: state.stateKategoriKatalog.ShowModal.id,
        kode_kategori: state.stateKategoriKatalog.ShowModal.kode_kategori,
        nama_kategori: state.stateKategoriKatalog.ShowModal.nama_kategori,
        warna: state.stateKategoriKatalog.ShowModal.warna,
        gambar_path: state.stateKategoriKatalog.ShowModal.gambar_path,
        gambar_name: state.stateKategoriKatalog.ShowModal.gambar_name,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
    }
  }
  
};
class FormKategoriKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktif: false,
      listgroup: [],
      parameter_bandrol: "2301",
    };
  }

  setFocus() {
    setTimeout(() => {
      document.getElementById("kode_kategori").focus();
    }, 100);
  }

  getValue = async (event) => {
    const file = event.target.files[0];
    document.getElementById("gambar_path").value = file.name;
    const base64 = await convertBase64(file);
    document.getElementById("preview").src = base64;
    this.props.dispatch(change("FormKategoriKatalog", "gambar_path", base64));
    console.log(base64);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className={this.props.isEdit ? "form-group" : "form-group d-none"}>
          <Field
            tabIndex="1"
            id="kode_kategori"
            label="Kode Kategori"
            name="kode_kategori"
            value={this.props.kode_group}
            readOnly={this.props.isEdit}
            placeholder={"Silahkan Tulis Kode Kategori"}
            component={ReanderField}
          />
        </div>
        <Field
          tabIndex="2"
          id="nama_kategori"
          name="nama_kategori"
          component={ReanderField}
          type="text"
          label="Nama Kategori"
          placeholder="Masukan Nama Kategori"
        />
        <Field
          tabIndex="3"
          id="warna"
          name="warna"
          component={ReanderField}
          type="color"
          label="Warna"
          placeholder="Masukan Pilih Warna"
        />
        <div className="form-group">
          <input
            type="file"
            id="gambar"
            name="gambar"
            onChange={(e) => this.getValue(e)}
          />
        </div>

        <Field
          tabIndex="4"
          id="gambar_path"
          name="gambar_path"
          component={ReanderField}
          type="text"
          label="Gambar Icon"
          readOnly={true}
          placeholder="Masukan Gambar Icon"
        />
        <div className="d-none">
          <Field
            id="gambar_name"
            name="gambar_name"
            component={ReanderField}
            type="text"
            label="Gambar Banner"
            readOnly={true}
            placeholder="Masukan Gambar Banner"
          />
        </div>
        <div className="col-lg-12">
          <img
            alt="previewIcon"
            id="preview"
            className="img-thumbnail"
            src={this.props.gambar_path}
          />
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="5"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormKategoriKatalog = reduxForm({
  form: "FormKategoriKatalog",
  enableReinitialize: true,
})(FormKategoriKatalog);
export default connect(maptostate, null)(FormKategoriKatalog);
