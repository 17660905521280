import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm} from "redux-form";
import Loading from "react-fullscreen-loading";
import { ReanderField } from "../../components/helpers/field";
import { Panel } from "../../components/panel/panel";
import { ToastNotification } from "../../components/helpers/notification";
import { getData } from "../../config/axios";

const maptostate = (state) => {
  return {
    // datakondisi: state.datamaster.getDataKondisiBarang,
    setHancurBarang: state.databarang.setHancurBarang,
  };
};

class HeadFormCekKupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      kondisi_barang: "kosong",
      FormModal: "",
      isLoading: false,
      point: "POINT",
    };
  }

  getKodeKupon(e) {
    
    if (e.length < 10) {
      this.props.change("no_kupon", "");      
      return false;
    }
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      if (e === undefined) {
        return false;
      }
      this.setState({
        isLoading: true,
      });

      getData("check-kupon/" + e).then((res) => {
        console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSS");
        console.log(res.data);
        
        if (res.data.length === 0) {
          ToastNotification("info", "Kode Kupon Tersebut Tidak Ada");
          this.props.change("nama_member", "");
          this.props.change("alamat", "");
          this.props.change("no_hp", "");
          this.setState({
            datatKupon: [],
            isLoading: false,
          });
        } else {
          localStorage.setItem("data_member", JSON.stringify(res.data));        
          this.props.change("no_faktur", res.data.no_faktur_jual);
          this.props.change("barcode", res.data.barcode);
          this.props.change("nama_pelanggan", res.data.nama_customer);
          this.props.change("kode_pelanggan", res.data.kode_customer);
         
          console.log(res.data);
        }
      }).catch((err=>{

        ToastNotification("info", "Kode Kupon Tersebut Tidak Ada");
        this.props.change("no_faktur", "");
        this.props.change("nama_pelanggan", "");
        this.props.change("kode_pelanggan", "");
        
        this.setState({
          datatKupon: [],          
          isLoading: false,
        });
      }))
    }, 1500);
  }
  render() {
    return (
      <Panel>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-4">
                <Field
                  onChange={(e) => this.getKodeKupon(e.target.value)}
                  name="no_kupon"
                  component={ReanderField}
                  type="text"
                  label="No Kupon"
                  placeholder="Masukan No Kupon"
                />
              </div>
              <div className="col-lg-4">
                <Field                  
                  name="no_faktur"
                  readOnly={true}
                  component={ReanderField}
                  type="text"
                  label="No Faktur"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  id="transaksi_poin_nama_member"
                  name="barcode"
                  readOnly={true}
                  component={ReanderField}
                  type="text"
                  label="Barcode"
                />
              </div>
            </div>
          </div>

          <div className="col-8">
            <div className="row">
              <div className="col-lg-6">
                <Field
                  id="transaksi_poin_alamat"
                  name="nama_pelanggan"
                  readOnly={true}
                  component={ReanderField}
                  type="text"
                  label="Nama Pelanggan"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  id="transaksi_poin_no_hp"
                  name="kode_pelanggan"
                  readOnly={true}
                  component={ReanderField}
                  type="text"
                  label="Kode Pelanggan"
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoadingAmbilPoin || this.state.isLoadingTambahPoin ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </Panel>
    );
  }
}

HeadFormCekKupon = reduxForm({
  form: "HeadFormCekKupon",
  enableReinitialize: true,
})(HeadFormCekKupon);
export default connect(maptostate, null)(HeadFormCekKupon);
