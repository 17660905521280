import { hideModal, showModal } from "actions/datamaster_action";

import { NotifSucces, ToastNotification } from "components/helpers/library";
import { NotifError } from "components/helpers/notification";
import { deleteData, getData, postData, putData } from "config/axios";
import { setLoading, stopLoading } from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_GROUP_DESCRIPTION_KATALOG = "GET_GROUP_DESCRIPTION_KATALOG";
export const SET_DATA_GROUP_DESCRIPTION_KATALOG = "SET_DATA_GROUP_DESCRIPTION_KATALOG";
export const DELETE_GROUP_DESCRIPTION_KATALOG = "DELETE_GROUP_DESCRIPTION_KATALOG";
export const EDIT_GROUP_DESCRIPTION_KATALOG = "EDIT_GROUP_DESCRIPTION_KATALOG";
export const POST_GROUP_DESCRIPTION_KATALOG = "POST_GROUP_DESCRIPTION_KATALOG";

export const fetchGroupDescriptionKatalog = () => {
  return (dispatch) => {
    dispatch(setLoading())
    getData("app/member/group-description")
      .then((res) => {
        dispatch({
          type: GET_GROUP_DESCRIPTION_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setEditGroupDescriptionKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_GROUP_DESCRIPTION_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteGroupDescriptionKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Group " +
        data.kode_group +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteGroupDescriptionKatalog(data.kode_group));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahGroupDescriptionKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_GROUP_DESCRIPTION_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteGroupDescriptionKatalog = (kode_group) => {
  return (dispatch) => {
    dispatch(setLoading())
    deleteData("app/member/group-description/delete/" +kode_group)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus").then(() =>
          dispatch(fetchGroupDescriptionKatalog())
        );
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Menghapus Harga Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const updateGroupDescriptionKatalog = (kode_group, data) => {
  return (dispatch) => {
    dispatch(setLoading())
    putData("app/member/group-description/edit/" + kode_group, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchGroupDescriptionKatalog());
        dispatch(hideModal());
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Merubah Harga Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const sendGroupDescriptionKatalog = (data) => {
  return (dispatch) => {
    dispatch(setLoading())
    postData("app/member/group-description", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchGroupDescriptionKatalog());
        dispatch(hideModal());
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Menambahkan Harga Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const handleSubmitGroupDescriptionKatalog = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const current = state.stateGroupDescriptionKatalog.ShowModal;
    let payload = state.form.FormGroupDescriptionKatalog.values;
    
    if (current) {
      dispatch(updateGroupDescriptionKatalog( current.kode_group, payload));
      dispatch(stopLoading())
    } else {
      dispatch(sendGroupDescriptionKatalog(payload));
      dispatch(stopLoading())
    }
  };
};
