import { hideModal, showModal } from "actions/datamaster_action";
import { dataURLtoFile } from "components/helpers/function";
import { getData, NotifSucces, ToastNotification } from "components/helpers/library";
import { NotifError } from "components/helpers/notification";
import {
  deleteData,
  deleteImageApp,
  getImageSplashscreenApp,
  postData,
  postSplashImageApp,
  putData,
} from "config/axios";
import { setLoading, stopLoading } from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_SPLASHSCREEN_KATALOG = "GET_SPLASHSCREEN_KATALOG";
export const SET_DATA_SPLASHSCREEN_KATALOG = "SET_DATA_SPLASHSCREEN_KATALOG";
export const DELETE_SPLASHSCREEN_KATALOG = "DELETE_SPLASHSCREEN_KATALOG";
export const EDIT_SPLASHSCREEN_KATALOG = "EDIT_SPLASHSCREEN_KATALOG";
export const POST_SPLASHSCREEN_KATALOG = "POST_SPLASHSCREEN_KATALOG";

export const fetchSplashscreenKatalog = () => {
  return async (dispatch) => {
    dispatch(setLoading())
   var member =  await getImageSplashscreenApp("SPLASHSCREEN/MEMBER.png")
   var guest1 =  await getImageSplashscreenApp("SPLASHSCREEN/GUEST-1.png")
   var guest2 =  await getImageSplashscreenApp("SPLASHSCREEN/GUEST-2.png")
   var guest3 =  await getImageSplashscreenApp("SPLASHSCREEN/GUEST-3.png")
   var payload = await getData("app/splash-screen");
   var templates = [
    {
      kode: "MEMBER",
      name : "MEMBER",
      url: member
    },
    {
      kode: "GUEST-1",
      name : "GUEST SLIDE 1",
      url: guest1
    },
    {
      kode: "GUEST-2",
      name : "GEUST SLIDE 2",
      url: guest2
    },
    {
      kode: "GUEST-3",
      name : "GUEST SLIDE 3",
      url: guest3
    }
  ];
  var result = templates.map((template) => {
    let index = payload.data.findIndex((find) => find.screen_name === template.kode)
    template.text = payload.data[index].text;
    template.position = payload.data[index].position;
    return template;
  })
   dispatch({
    type: GET_SPLASHSCREEN_KATALOG,
    payload:result ,
  });
  dispatch(stopLoading())
  };
};

export const setEditSplashscreenKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_SPLASHSCREEN_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteSplashscreenKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Splashscreen " +
        data.kode_kategori +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteSplashscreenKatalog(data.kode_kategori, data.gambar_name));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahSplashscreenKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_SPLASHSCREEN_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteSplashscreenKatalog = (kode_kategori, gambar_name) => {
  return (dispatch) => {
    dispatch(setLoading())
    deleteData("app/kategori-katalog/delete/" + kode_kategori)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus")
          .then(async () => {
            await deleteImageApp(`SPLASHSCREEN_ICON/${gambar_name}`);
          })
          .then(() => dispatch(fetchSplashscreenKatalog()))
          .then(() => dispatch(stopLoading()));
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError("Gagal Menghapus Splashscreen Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const updateSplashscreenKatalog = (kode_kategori, data) => {
  return (dispatch) => {
    dispatch(setLoading())
    putData("app/kategori-katalog/edit/" + kode_kategori, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchSplashscreenKatalog());
        dispatch(hideModal());
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError("Gagal Merubah Splashscreen Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const sendSplashscreenKatalog = (data) => {
  return (dispatch) => {
    dispatch(setLoading())
    postData("app/kategori-katalog", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchSplashscreenKatalog());
        dispatch(hideModal());
        dispatch(stopLoading())
      })
      .catch((err) => {
        console.log(err);
        dispatch(stopLoading())
        NotifError(
          "Gagal Menambahkan Splashscreen Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const handleSubmitSplashscreenKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(setLoading())
    let state = getState();
    let kode = state.stateSplashscreenKatalog.ShowModal.kode
    console.log(data.gambar_path);
    let imageEdited = !data.gambar_path.includes("https://");
    if(data.gambar_path !== undefined){
      try {
        let imgUrl = data.gambar_path;
        if(imageEdited){
          imgUrl = await postSplashImageApp(
            dataURLtoFile(data.gambar_path),
            `SPLASHSCREEN/${data.kode}`
          );
        }
        if(data.text === ""){
          NotifError("Text Tidak Boleh Kosong");
          return false;
        }
        await putData("app/splash-screen/"+kode, {
          text : data.text,
          position: data.position,
          image_path: imgUrl
        })
        dispatch(stopLoading())
        dispatch(hideModal())
        window.location.reload();
      } catch (error) {
        NotifError("Simpan Data Gagal, Silahkan Ulangi Beberapa Saat Lagi");
        dispatch(stopLoading())
      }
      dispatch(stopLoading())
      dispatch(hideModal())
      return false;
    }
    
  };
};
