const ValidasiHadiahKatalog = (value) => {
  const errors = {};
  if (!value.nama_hadiah) {
    errors.nama_hadiah = "Nama Hadiah Tidak Boleh Kosong";
  }
  if (!value.info) {
    errors.info = "Info Tidak Boleh Kosong";
  }

  if (!value.sub_info) {
    errors.sub_info = "Sub Info Tidak Boleh Kosong ";
  }

  if (!value.point) {
    errors.point = "Point Tidak Boleh Kosong ";
  }

  return errors;
};

export default ValidasiHadiahKatalog;
