
import { deleteData, getData } from "../../../config/axios";
import { NotifMember, ToastNotification } from "../../../components/helpers/notification";
import { Swal } from "../../../components/helpers/library";
// import * as moment from "moment-timezone"


export const GET_ALL_TRANSAKSI_MEMBER = "GET_ALL_TRANSAKSI_MEMBER"
export const CHECK_TRANSAKSI_MEMBER = "CHECK_TRANSAKSI_MEMBER"

export const getAllTransaksiMember = () => {
    return async (dispatch, getState) => {
        getData("/transaksi-member")
            .then((res) => {
                dispatch({
                    type: GET_ALL_TRANSAKSI_MEMBER,
                    payload: res.data
                })
                // console.log(res.data);
            })
            .catch((err) => {
                ToastNotification("info", "Data member tidak ada");
            });
    }
}

export const deleteTransaksiMember = (id) => {
    return async (dispatch, getState) => {
        Swal.fire({
            title: "Anda Yakin !!",
            text: "Ingin Menghapus Data Ini ?",
            icon: "warning",
            position: "top-center",
            cancelButtonText: "Tidak",
            showCancelButton: true,
            confirmButtonText: "OK",
            showConfirmButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteData("/transaksi-member/" + id)
                    .then((res) => {
                        dispatch(getAllTransaksiMember())
                    })
                    .catch((err) => {
                        ToastNotification("info", "Data member tidak ada");
                    });
            }
        }).catch((err) => {
            ToastNotification("error", err.response.data)
        })

    }
}

export const checkTransaksiMember = (type, e) => {
  

    return async (dispatch, getState) => {   
      
        getData("transaksi-member/" + type + "/" + e)
            .then((res) => {
                dispatch({
                    type: CHECK_TRANSAKSI_MEMBER,
                    payload: res.data
                }) 
             
                       
                if (res.data.length > 0) {
                    // for(let i = 0; i < res.data.length; i++){
                    //     dataKasus.push(res.data[i].jenis)                        
                    //     ketKasus.push(res.data[i].transaksi, res.data[i].no_nota, res.data[i].keterangan)                        
                    // } 
                    // NotifMemberNotTransaction()       
                     
                    NotifMember(res.data)
                    // NotifMember(dataKasus, ketKasus)
                    // NotifMember(res.data[0].jenis , res.data[0].keterangan)
                }

               
                // NotifMember(res.data)               
            })
            .catch((err) => {
                ToastNotification("info", "Data member tidak ada");
            });
    }
}