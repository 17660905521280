import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import HeadFormCekKupon from "./HeadFormCekKupon";

class FormCekKupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      point: "POINT",
    };
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Cek Kupon</Link>
          </li>
          <li className="breadcrumb-item active">Cek Kupon</li>
        </ol>
        <h1 className="page-header">Cek Kupon </h1>
        <Panel>
          <PanelHeader>Cek Kupon</PanelHeader>
          <br />
          
          <PanelBody>
            <HeadFormCekKupon
            />
          </PanelBody>
          <br />

        </Panel>
        {this.state.isLoading ? (
        <Loading 
        loading
        background="rgba(0, 0, 0, 0.35)"
         loaderColor="rgba(94, 147, 117, 1)" />
      ) : null}
      </div>
    );
  }
}

export default connect(null)(FormCekKupon);
