import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import Loading from "react-fullscreen-loading";
import { Panel } from "../../../components/panel/panel";
import Tabel from "../../../components/helpers/tabel";
import {
  convertDate,
  getTglSystem,
} from "../../../components/helpers/function";
import { hideModal, showModal } from "../../../actions/datamaster_action";
import ModalGlobal from "../../../components/helpers/ModalGlobal";
import ModalPembayaran from "../../Pesanan/ModalPembayaran";
import { InputDate, ReanderField } from "../../../components/helpers/field";
import { ToastNotification } from "../../../components/helpers/notification";
import FilterBeliHadiah from "./FilterBeliHadiah";
import { postData } from "../../../config/axios";

const maptostate = (state) => {
  return {
    setBeliHadiah: state.databarang.setBeliHadiah,
  };
};

class HeadFormBeliHadiah extends Component {
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }

  componentDidMount() {
    this.props.change("tgl_beli", getTglSystem());
  }

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      FormModal: "",
      isLoading: false,
      databarang: [],
      nama_supplier: "",
      columns: [
        {
          dataField: "namaHadiahBeli",
          text: "Nama Barang",
          headerClasses: "text-center",
          footerAttrs: {
            colSpan: "1",
          },
          footer: "Total",
        },

        {
          dataField: "qtyBeli",
          headerClasses: "text-center",
          text: "Qty",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
          formatter: (cell) => {
            return <div className="text-right">{parseInt(cell)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {" "}
                {columnData.reduce((a, b) => a + parseInt(b), 0)}{" "}
              </div>
            );
          },
        },
        {
          dataField: "totalUang",
          text: "Total Uang",
          headerClasses: "text-center",
          formatter: (cell) => {
            return (
              <div className="text-right">
                {parseInt(cell).toLocaleString("kr-KO")}
              </div>
            );
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {" "}
                {parseInt(
                  columnData.reduce((acc, item) => acc + item, 0)
                ).toLocaleString("kr-KO")}{" "}
              </div>
            );
          },
        },
      ],
    };
  }

  showModalBelilHadiah() {
    this.setState({ FormModal: "BeliHadiah" });
    this.props.dispatch(showModal());
  }

  resetBeliHadiah() {
    this.setState({
      resetAmbilHadiah: true,
    });
    setTimeout(() => {
      localStorage.removeItem("data_beli_hadiah_tmp");
      this.setState({
        databarang: [],
        resetAmbilHadiah: false,
      });
    }, 300);
  }

  handleSimpan() {
    this.setState({
      isLoading: true,
    });
    let cek = JSON.parse(localStorage.getItem("data_beli_hadiah_tmp")) || [];
    if (cek.length === 0) {
      ToastNotification("Information", "Data Hadiah Masih Kosong");
      return false;
    }
    this.props.dispatch(reset("FilterBeliHadiah"));
    // this.props.dispatch(reset("HeadFormBeliHadiah"));
    localStorage.removeItem("data_beli_hadiah_tmp")

    if (this.state.nama_supplier === "") {
      ToastNotification("Information", "Nama Supplier Masih Kosong");
      return false;
    }
    let dataBelilHadiah = [];
    cek.forEach((List) => {
      let row = {
        kode_hadiah: List.kode_hadiah,
        qty: List.qtyBeli,
        total_uang: List.totalUang,
      };
      dataBelilHadiah.push(row);
    });

    const data = {
      tanggal: convertDate(this.state.tgl_awal || getTglSystem()),
      supplier: this.state.nama_supplier,
      detail_hadiah: dataBelilHadiah,
    };

    console.log(data);

    postData("/hadiah/beli", data)
      .then((res) => {
        console.log(res.data);
        ToastNotification("success", "Data Barang Berhasil Di Simpan");
        this.setState({
          resetBeliHadiah: true,
        });
        setTimeout(() => {
          localStorage.removeItem("data_ambil_hadiah_tmp");
          this.setState({
            databarang: [],
            resetAmbilHadiah: false,
          });
        }, 300);
      })
      .catch((error) => {
        console.log(
          error.response === undefined
            ? "Terjadi Kesalahan Saat Mengirim Data"
            : error.response.data
        );
      });
  }

  saveLocalDataBeliHadiah(data) {
    let dataHadiahBeli = data.namaHadiahBeli.split("~");

    let cek = JSON.parse(localStorage.getItem("data_beli_hadiah_tmp")) || [];
    let hasil_cek = cek.findIndex(
      (hasil) => hasil.kode_hadiah === dataHadiahBeli[1]
    );
    console.log(hasil_cek);
    if (hasil_cek === -1) {
      let row = {
        stockHadiah: Number(data.stockHadiah),
        kode_hadiah: dataHadiahBeli[0],
        namaHadiahBeli: dataHadiahBeli[1],
        qtyBeli: Number(data.qtyBeli),
        totalUang: Number(data.totHargaHadiah? data.totHargaHadiah :0 ),
      };
      cek.push(row);
      localStorage.setItem("data_beli_hadiah_tmp", JSON.stringify(cek));
      this.props.dispatch(hideModal());
    } else {
      ToastNotification("info", "Nama Hadiah Tersebut Sudah Ada Ditabel");
      return false;
    }

    this.setState({
      databarang: cek,
    });
  }

  render() {
    return (
      <Panel>
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="tgl_beli"
              component={InputDate}
              label="Tanggal Beli"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Beli"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="namaSupplier"
              component={ReanderField}
              type="text"
              onBlur={(e) => {
                this.setState({
                  nama_supplier: e.target.value,
                });
              }}
              label="Nama Supplier"
              placeholder="Masukan Nama Supplier"
            />
          </div>

          <div className="col-12">
            <br />
            <textarea
              style={{ display: "none" }}
              id="nota_titip"
              rows="100"
              cols="100"
            />
            <Tabel
              keyField="kode_barcode"
              disable_search={true}
              // exportCsv={false}
              columns={this.state.columns}
              btnOptional={true}
              data={
                this.state.databarang.length === 0
                  ? JSON.parse(localStorage.getItem("data_beli_hadiah_tmp")) ||
                    []
                  : this.state.databarang
              }
              btnTextOptional="Data Barang"
              handleClickOptional={() => this.showModalBelilHadiah()}
              empty={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              onClick={() => this.handleSimpan()}
              jenistrx={this.state.FormModal}
              className="btn btn-primary"
              disabled={this.props.isLoading}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data Beli Hadiah"
              )}
            </button>
            &nbsp; &nbsp;
            <button
              onClick={() => this.resetBeliHadiah()}
              className="btn btn-warning"
              disabled={this.state.isLoadingBatal}
              type="button"
            >
              {this.state.isLoadingBatal ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Reset
                </>
              ) : (
                "Reset"
              )}
            </button>
          </div>
        </div>

        <ModalGlobal
          hideBtnX={this.state.FormModal}
          size={this.state.FormModal === "BeliHadiah" ? "xl" : "xl"}
          title={<TitleModal state={this.state.FormModal} />}
          content={
            this.state.FormModal === "BeliHadiah" ? (
              <FilterBeliHadiah
                isLoading={this.state.isLoading}
                onSubmit={(data) => this.saveLocalDataBeliHadiah(data)}
              />
            ) : (
              <ModalPembayaran
                isLoadingPembayranTitipan={this.state.isLoadingPembayranTitipan}
                isLoadingPembayran={this.state.isLoadingPembayran}
                bayarTitipan={(data) => this.bayarTitipan(data)}
                simpanPembayaran={(data) => this.simpanPembayaran(data)}
              />
            )
          }
        />

        {this.state.isLoadingAmbilPoin || this.state.isLoadingTambahPoin ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </Panel>
    );
  }
}

function TitleModal(data, onSubmit) {
  return "Form Beli Hadiah";
}

HeadFormBeliHadiah = reduxForm({
  form: "HeadFormBeliHadiah",
  enableReinitialize: true,
})(HeadFormBeliHadiah);
export default connect(maptostate)(HeadFormBeliHadiah);
