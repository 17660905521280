import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "actions/datamaster_action";
import { ReanderField } from "components/helpers/field";

import { NumberOnly } from "Validasi/normalize";

const maptostate = (state) => {
  if (state.stateKontakAdminKatalog.ShowModal !== null) {
    return {
      isEdit: true,
      isLoading: state.utility.isLoading,
      initialValues: {
        id: state.stateKontakAdminKatalog.ShowModal.id,
        kode_admin: state.stateKontakAdminKatalog.ShowModal.kode_admin,
        nama_admin: state.stateKontakAdminKatalog.ShowModal.nama_admin,
        no_hp: state.stateKontakAdminKatalog.ShowModal.no_hp,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
    }
  }
};
class FormKontakAdminKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktif: false,
      listgroup: [],
      parameter_bandrol: "2301",
    };
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <Field
          tabIndex="1"
          id="nama_admin"
          name="nama_admin"
          component={ReanderField}
          type="text"
          label="Nama Kategori"
          placeholder="Masukan Nama Kategori"
        />
        <Field
          tabIndex="2"
          id="no_hp"
          name="no_hp"
          component={ReanderField}
          type="text"
          label="Nomor Whatsapp"
          normalize={NumberOnly}
          placeholder="Masukan Nomor Whatsapp"
        />
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="5"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormKontakAdminKatalog = reduxForm({
  form: "FormKontakAdminKatalog",
  enableReinitialize: true,
})(FormKontakAdminKatalog);
export default connect(maptostate, null)(FormKontakAdminKatalog);
