import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  formatDate,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";

class LaporanAktivasiMemberExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Aktivasi Member"
          sheet="Laporan Aktivasi Member"
          buttonText="Export Excel"
        />

        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="9"> Laporan Aktivasi Member</th>
            </tr>
            <tr>
              <th colSpan="9"> Periode </th>
            </tr>
            <tr>
              <th colSpan="9">
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).start_date +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan")).end_date}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="9"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="9"></th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kode Member
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NAMA CUSTOMER
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                No Hp
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Alamat Customer
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kecamatan
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kelurahan
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Kota
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Tanggal Lahir
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Tanggal Aktivasi
              </th>
            </tr>
          </thead>

          {this.props.data.map((item, index) => (
            <>
              <tbody>
                {/* <tr>
                  <th
                    colSpan="9"
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    Tanggal Daftar : {item._id.tgl_daftar}{" "}
                  </th>
                </tr> */}

                <>
                  <tr>
                    <td> {item.kode_member}</td>
                    <td> {item.nama_customer}</td>
                    <td> &nbsp;{item.no_hp}</td>
                    <td> {item.alamat_customer}</td>
                    <td> {item.kecamatan}</td>
                    <td> {item.kelurahan}</td>
                    <td> {item.kota}</td>
                    <td> {item.tgl_lahir}</td>
                    <td> {formatDate(item.input_date)}</td>
                  </tr>
                </>
              </tbody>
            </>
          ))}
          <tfoot>
            <tr>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
                colSpan="8"
              >
                Jumlah Daftar
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                {this.props.data.length}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanAktivasiMemberExel;
