import {
  ModalGlobal,
  Panel,
  PanelBody,
  PanelHeader,
  Tabel,
} from "components/helpers/library";
import React from "react";
import { Loading } from "react-fullscreen-loading";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchInfoKatalog,
  handleSubmitInfoKatalog,
  setDeleteInfoKatalog,
  setEditInfoKatalog,
  setTambahInfoKatalog,
} from "./redux/infoKatalogAction";
import FormInfoKatalog from "./widgets/FormInfoKatalog";

class InfoKatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "kode_info",
          text: "Kode Info ",
          sort: true,
        },
        {
          dataField: "title",
          text: "Title",
        },
        {
          dataField: "deskripsi",
          text: "Deskripsi",
        },
        {
          dataField: "gambar_path",
          text: "Gambar",
          formatter: (data) => {
            return (
              <img src={`${data}`} alt="IMAGES" className="img-thumbnail" />
            );
          },
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => {
                      this.props.dispatch(setEditInfoKatalog(row));
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      this.props.dispatch(setDeleteInfoKatalog(row));
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchInfoKatalog());
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Info Katalog</Link>
          </li>
          <li className="breadcrumb-item active">Info Katalog</li>
        </ol>
        <h1 className="page-header">Info Katalog </h1>
        <Panel>
          <PanelHeader>Info Katalog</PanelHeader>
          <br />
          <PanelBody>
            <Tabel
              keyField="kode_info"
              tambahData={true}
              handleClick={() => this.props.dispatch(setTambahInfoKatalog())}
              exportCsv={true}
              data={this.props.InfoKatalog}
              columns={this.state.columns}
              empty={this.props.InfoKatalog}
              textEmpty="Data Info Kosong"
              fileNameCsv={`kategori-katalog`}
            />
          </PanelBody>
          <br />
        </Panel>
        <ModalGlobal
          size="P"
          title={
            this.state.isEdit ? "Edit Info Katalog" : "Tambah Info Katalog"
          }
          content={
            <FormInfoKatalog
              isEdit={this.state.isEdit}
              onSubmit={(data) =>
                this.props.dispatch(handleSubmitInfoKatalog(data))
              }
            />
          }
        />
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    InfoKatalog: state.stateInfoKatalog.infoKatalog,
  };
})(InfoKatalog);
