import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  getData,
  convertDate,
  PanelBody,
  ToastNotification,
  localStorageencryp,
} from "../../../components/helpers/library";
import HeadLaporanMutasiHadiah from "./HeadLaporanMutasiHadiah";

class FormLaporanMutasiHadiah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      LaporanMutasiHadiah: [],
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    let startDate = convertDate(data.start_date);
    let endtDate = convertDate(data.end_date);
    let kategoriLap = data.kategori;
    let typeLaporan = data.type;

    let data_post = {
      tgl_awal:  convertDate(data.start_date),
      tgl_akhir: convertDate(data.end_date),
      
    };

    this.setState({ isLoading: true });

    getData(
      `/report/mutasi-hadiah?start_date=${startDate}&end_date=${endtDate}&kategori=${kategoriLap}&type=${typeLaporan}`
    )
      .then((res) => {
        if (res.data.length === 0) {
          this.setState({
            export: false,
            LaporanMutasiHadiah: [],
            isLoading: false,
          });
          ToastNotification("info", "Laporan Mutasi Hadiah Tidak Tersedia");
        } else {
          ToastNotification("success", "Laporan Mutasi Hadiah Tersedia");
          localStorageencryp("tgl_laporan", JSON.stringify(data_post));
          this.setState({
            export: true,
            isLoading: false,
            LaporanMutasiHadiah: res.data,
          });
        }
      })
      .catch((error) => {
        ToastNotification(
          "info",
          error?.response?.data || "Terjadi Kesalahan saat mengirim data"
        );
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Mutasi Hadiah </li>
        </ol>
        <h1 className="page-header">Laporan Mutasi Hadiah </h1>
        <Panel>
          <PanelHeader>Laporan Mutasi Hadiah</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <HeadLaporanMutasiHadiah
              export={this.state.export}
              isLoading={this.state.isLoading}
              LaporanMutasiHadiah={this.state.LaporanMutasiHadiah}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>

          <br />
          {/* End Tambah Blank Page  */}
        </Panel>
      </div>
    );
  }
}

FormLaporanMutasiHadiah = reduxForm({
  form: "FormLaporanMutasiHadiah",
  enableReinitialize: true,
})(FormLaporanMutasiHadiah);
export default connect(null)(FormLaporanMutasiHadiah);
